// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import fb from "firebase/compat/app";
import "firebase/compat/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDEKP0L2cnHHAdOi-J-HN8pPzO_5Nqmy9k",
  authDomain: "damex-gi.firebaseapp.com",
  projectId: "damex-gi",
  storageBucket: "damex-gi.appspot.com",
  messagingSenderId: "911052645315",
  appId: "1:911052645315:web:11f60651b4777b60b75376",
  measurementId: "G-4NXXKWRJH9"
};

// Initialize Firebase
export const firebase = !fb.apps.length ? fb.initializeApp(firebaseConfig) : fb.app()
//firebase.initializeApp(firebaseConfig);
//firebase.analytics(); 