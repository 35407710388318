import React from "react";
import { Tabs, Typography } from "antd";
import "./depositsWithdrawals.less";
import DepositsWithdrawalsTable from "./depositsWithdrawalsTable";
const { Text } = Typography;
const DepositsWithdrawals = () => {
  const tabsItems = [
    {
      key: "1",
      label: (
        <Text
          style={{
            fontSize: 18,
            color: "#fff",
          }}
        >
          Damex
        </Text>
      ),
      children: <DepositsWithdrawalsTable source={"Damex"} />,
    },
    {
      key: "2",
      label: (
        <Text
          style={{
            fontSize: 18,
            color: "#fff",
          }}
        >
          Damex Direct
        </Text>
      ),
      children: <DepositsWithdrawalsTable source={"Damex Direct"} />,
    },
  ];
  return <Tabs defaultActiveKey="1" items={tabsItems}></Tabs>;
};
export default DepositsWithdrawals;
