import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Card, Divider, Switch, message, } from "antd";
import { connect } from "react-redux";
import { activateTwoFactor, confirmTwoFactor, userTwoFactor } from "../../redux/actions/twoFactorActions";
import { currentToken } from "../../redux/actions/tokenActions";
import servicesTwoFactor from "../../services/twoFactor";
import ModalTwoFactor from "./twoFactorModal";

import "./profile.less";

const { Title } = Typography;

const Security = (props) => {

    const [state, setState] = useState({
        modalActivate: false,
        verificationCode: "",
        loading: false,
      })

      const [check, setCheck] = useState(true);

    
    function openModalActivate() {
        setState({ ...state, modalActivate: true });
      }
    function closeModalActivate() {
        setState({ ...state, modalActivate: false });
    }
    
    async function  verificationCodeR(value) {
        try {
            setState({ ...state, loading: true });
            
            let clientId = props.clientId;
            let accessToken = props.token;
            let body = {
                otp: value,
            };
            const response = await servicesTwoFactor.confirmTwoFactor( clientId, accessToken, body );
            await props.confirmTwoFactor(response);

            props.currentToken(response.headers["x-new-access-token"]);

            if(response.status === 200){       
                if (response.data.success) {
                    setState({ ...state, loading: false });
                    message.success("Change Success");
                    setCheck(!check);
                    props.userTwoFactor(!check);
                    closeModalActivate();
                } else {
                    setState({ ...state, loading: false });
                }
            }
        } catch (error) {
            setState({ ...state, loading: false });
            if (error.response.headers["x-new-access-token"] !== undefined) {
            props.currentToken(error.response.headers["x-new-access-token"]);
            }
            if (error.response.status === 403) {
                message.error("Code Expired");
            } else if (error.response.status === 400) {
                message.error("Data Error");
            } else if (error.response.status === 401) {
                message.error("Incorrect code");
            } else if (error.response.status === 404) {
                message.error("Inactive User");
            } else {
                message.error("Server Error. Please try again");
            }
        }
    }

    async function onSwitchChange(checked) {
        let id = props.clientId;
        let token = props.token;
        let body = {
            enable: checked
        };

        try {
    
            const response = await servicesTwoFactor.activateTwoFactor(id, token, body);
            await props.activateTwoFactor(response);
    
            props.currentToken(response.headers["x-new-access-token"]);
    
            if(response.status === 200){            
                openModalActivate();
            }            
        } catch (error) {
            if (error.response.headers["x-new-access-token"] !== undefined) {
            props.currentToken(error.response.headers["x-new-access-token"]);
            }
            message.error("An error has ocurred, please try again")
        }


    }

    useEffect(() => {
        setCheck(props.changeTwoFactor.userTwoFactor)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  return (
    <div>
        <Row>
            <Col span={24}>              
                <Card className="card" bodyStyle={{ padding: 40 }}>
                    <Title
                        level={4}
                        style={{
                            color: "#fff",
                            textAlign: "left",
                            marginTop: "-9px"
                        }}
                    >
                        2-step verification
                    </Title>                          
                    <Divider 
                        style={{
                            background: "#ccc",
                            margin: "0",
                            marginLeft: "-40px",
                            marginBottom: "20px",
                            width: "105%"
                        }}
                    />                    
                    <Row>
                        <div style={{ width: "35%", textAlign: "left", paddingLeft: "20px" }}>                        
                            <h2 style={{ fontWeight: "bold" }}>Sign-in</h2>
                            <h3 style={{ fontWeight: "bold" }}>Two-Factor Authentication (2FA) </h3>
                            <h5>Protect your account activating an additional layer of security to sign in</h5>                                        
                        </div>
                        <div style={{ padding: "15px" }}>
                            <Switch defaultChecked checked={check} onChange={onSwitchChange} />
                        </div>
                        
                    </Row>
                </Card>
            </Col>
        </Row>
        
        <ModalTwoFactor
        openModal={openModalActivate.bind(this)}
        closeModal={closeModalActivate.bind(this)}
        verificationCodeReceived={verificationCodeR.bind(this)}
        modalActivate={state.modalActivate}
        />
    </div>
  )
}

const mapStateToProps = (state) => {
    return {
      balanceResult: state.balance.balanceResult,
      token: state.tokenAccess.current,
      clientId: state.tokenAccess.clientId,
      authResult: state.authUser.authResult,
      changeTwoFactor: state.twoFactor
    };
  };
  
  const mapDispatchToProps = {
    activateTwoFactor,
    confirmTwoFactor,
    currentToken,
    userTwoFactor
  };

  export default connect(mapStateToProps, mapDispatchToProps)(Security);