import React from "react";
import { Tabs, Typography } from "antd";
import "./transactions.less";
import DepositsTable from "./transactionsTable";
import { connect } from "react-redux";
import { getTransactions } from "../../redux/actions/transactionActions";
import { currentToken } from "../../redux/actions/tokenActions";
const { Text } = Typography;
const Transactions = (props) => {
  const tabItems = [
    {
      key: "1",
      label: (
        <Text
          style={{
            fontSize: 18,
            color: "#fff",
          }}
        >
          Global
        </Text>
      ),
      children: <DepositsTable source={"Global"} />,
    },
    {
      key: "2",
      label: (
        <Text
          style={{
            fontSize: 18,
            color: "#fff",
          }}
        >
          Damex
        </Text>
      ),
      children: <DepositsTable source={"Damex"} />,
    },
    {
      key: "3",
      label: (
        <Text
          style={{
            fontSize: 18,
            color: "#fff",
          }}
        >
          Damex Direct
        </Text>
      ),
      children: <DepositsTable source={"Damex Direct"} />,
    },
  ];
  return (
    <Tabs
      defaultActiveKey="1"
      items={tabItems}
      destroyInactiveTabPane={true}
    ></Tabs>
  );
};

const mapStateToProps = (state) => {
  return {
    transactionResult: state.transaction.transactionResponse,
    token: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
  };
};

const mapDispatchToProps = {
  getTransactions,
  currentToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
