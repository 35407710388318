import React from "react";
import { Table } from "antd";
import { Card, Typography, Select, Row, Col, Button, Tag } from "antd";
import "./fiat.less";
import NumberFormat from "react-number-format";
import { asset } from "../../common/assets";
const { Text } = Typography;
const { Option } = Select;
const status = [
  {
    key: "ACTIVE",
    value: "Active",
  },
  {
    key: "INACTIVE",
    value: "Inactive",
  },
  { key: "ALL", value: "All" },
];
const ExchangeTreasuriesTable = (props) => {
  const dataSource = [];
  dataSource.push(
    {
      key: "abcd12345678",
      balance: 2450,
      currency: "GBP",
      account: "Account Holder: Dam / Account number: 70528379 / Sort Code: 000000",
      status: "ACTIVE",
      partner: "Modulr",
      fee: 0.00015,
      platform: "Damex"
    },
    {
      key: "xyz12345678",
      balance: 5450,
      currency: "EUR",
      account: "Account Holder: Dam / BIC: MOCKGB21 / IBAN: GB51MOCK00000070528380",
      status: "INACTIVE",
      partner: "Modulr",
      fee: 0.00015,
      platform: "Damex Direct"
    }
  );
  const currencies = asset.assets.filter((element) => element.type === "FIAT");
  currencies.push({ value: "ALL", name: "All" });
  const columns = [
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          ID
        </Text>
      ),
      dataIndex: "key",
      key: "key",
    },

    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Currency
        </Text>
      ),
      dataIndex: "currency",
      key: "currency",
    },

    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Balance
        </Text>
      ),
      dataIndex: "balance",
      key: "balance",
      render: (record, originNode) => (
        <div style={{ flexDirection: "column", display: "flex" }}>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            decimalScale={8}
            value={originNode.balance}
          />
          <Text
            style={{
              color: "#ccc",
              fontSize: 16,
              fontWeight: 400
            }}
          >
            {originNode.currency}
          </Text>
        </div>
      ),
    },

    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Partner
        </Text>
      ),
      dataIndex: "partner",
      key: "partner",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Status
        </Text>
      ),
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <>
          {status === "ACTIVE" && (
            <Tag
              style={{
                marginTop: 5,
                color: "#389E0D",
                background: "#F6FFED",
                borderColor: "#B7EB8F",
                fontSize: 12,
              }}
            >
              {status}
            </Tag>
          )}

          {status === "INACTIVE" && (
            <Tag
              style={{
                marginTop: 5,
                color: "#CF1322",
                background: "#FFF1F0",
                borderColor: "#FFA39E",
                fontSize: 12,
              }}
            >
              {status}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Platform
        </Text>
      ),
      className: props.source === "Global" ? "" : "column-hidden",
      dataIndex: "platform",
      key: "platform",
    }
  ];
  return (
    <Card
      className="card-transactions"
      bodyStyle={{ padding: "10px 0px 10px 0px" }}
    >
      <Row
        gutter={[8, { xs: 8, sm: 16, md: 16, lg: 16, xl: 16 }]}
        style={{ padding: "5px 10px" }}
      >
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 7, offset: 0 }}
          xl={{ span: 7, offset: 0 }}
          xxl={{ span: 7, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Select style={{ width: "100%" }} size="large" placeholder="Currency">
            {currencies.map((item, i) => {
              return <Option key={i} value={item.value}>{item.name}</Option>;
            })}
          </Select>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 7, offset: 0 }}
          xl={{ span: 7, offset: 0 }}
          xxl={{ span: 7, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Select style={{ width: "100%" }} size="large" placeholder="Status">
            {status.map((item, i) => {
              return <Option key={i} value={item.key}>{item.value}</Option>;
            })}
          </Select>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 7, offset: 0 }}
          xl={{ span: 7, offset: 0 }}
          xxl={{ span: 7, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Select style={{ width: "100%" }} size="large" placeholder="Partner">
            <Option key="partner" value={"MODULR"}>Modulr</Option>
          </Select>
        </Col>

        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 3, offset: 0 }}
          xl={{ span: 3, offset: 0 }}
          xxl={{ span: 3, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Button type="primary" size="large" style={{ width: "100%" }}>
            Filter
          </Button>
        </Col>
      </Row>

      <div
        id="table-history"
        className="ant-table ant-table-tbody ant-table-thead text-table-cell ant-table-cell"
        style={{ marginTop: 10 }}
      >
        <Table
          rowClassName="row-table-deposit"
          dataSource={dataSource}
          scroll={{ x: 1300 }}
          columns={columns}
          pagination={{ pageSize: 20 }}
          expandable={{
            expandedRowRender: (record) => (
              <p style={{ marginLeft: 44 }}>
                <Text
                  style={{
                    color: "#fff",
                    fontSize: 16,
                  }}
                >
                  {record.account.split("/")[0]}
                </Text>
                <br/>
                <Text
                  style={{
                    color: "#fff",
                    fontSize: 16,
                  }}
                >
                  {record.account.split("/")[1]}
                </Text>
                <br/>
                <Text
                  style={{
                    color: "#fff",
                    fontSize: 16,
                  }}
                >
                  {record.account.split("/")[2]}
                </Text>
              </p>
            ),
          }}
        />
      </div>
    </Card>
  );
};

export default ExchangeTreasuriesTable;
