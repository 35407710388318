export const ACTIVATE_TWO_FACTOR = "ACTIVATE_TWO_FACTOR";
export const CONFIRM_TWO_FACTOR = "CONFIRM_TWO_FACTOR";
export const LOGIN_TWO_FACTOR = "LOGIN_TWO_FACTOR";
export const USER_TWO_FACTOR = "USER_TWO_FACTOR";

export function activateTwoFactor(response) {
  return async (dispatch, getState) => {
    dispatch({
      type: ACTIVATE_TWO_FACTOR,
      payload: response,
    });
  };
}
export function confirmTwoFactor(response) {
  return async (dispatch, getState) => {
    dispatch({
      type: CONFIRM_TWO_FACTOR,
      payload: response,
    });
  };
}
export function loginTwoFactor(response) {
  return async (dispatch, getState) => {
    dispatch({
      type: LOGIN_TWO_FACTOR,
      payload: response,
    });
  };
}

export function userTwoFactor(active) {
  return async (dispatch, getState) => {
    dispatch({
      type: USER_TWO_FACTOR,
      payload: active,
    });
  };
}