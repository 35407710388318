import { COMMISSIONS } from "../actions/commissionsActions";
const initialState = {
  commissionsList: {},
};
export function commissions(state = initialState, action) {
  switch (action.type) {
    case COMMISSIONS:
      return {
        ...state,
        commissionsList: action.payload,
      };
    default:
      return state;
  }
}

export default commissions;
