import { ALL_USERS, COMMISSIONERS_INTRODUCERS } from "../actions/userActions";
const initialState = {
  usersResp: {},
  commissionersOrIntroducers: {},
};
export function users(state = initialState, action) {
  switch (action.type) {
    case ALL_USERS:
      return {
        ...state,
        usersResp: action.payload,
      };
    case COMMISSIONERS_INTRODUCERS:
      return {
        ...state,
        commissionersOrIntroducers: action.payload,
      };
    default:
      return state;
  }
}

export default users;
