import React from "react";
import "./createTransactionsOTC.less";
import { Row, Col, Space, Typography, Button, Divider, Input } from "antd";
import KeyVariant from "@2fd/ant-design-icons/lib/KeyVariant";
import KeyboardBackspace from "@2fd/ant-design-icons/lib/KeyboardBackspace";
import { connect } from "react-redux";
const { Text, Title } = Typography;
const TwoFactorSend = (props) => {
 
  return (
    <Row
      style={{ paddingTop: 20 }}
      justify="center"
      gutter={[48, { xs: 16, sm: 16, md: 16, lg: 24, xl: 24 }]}
    >
      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 24, offset: 0 }}
        md={{ span: 24, offset: 0 }}
        lg={{ span: 24, offset: 0 }}
        xl={{ span: 24, offset: 0 }}
        style={{ textAlign: "center" }}
      >
        <Row
          justify="center"
          gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}
        >
          <Col
            xs={{ span: 3, offset: 0 }}
            sm={{ span: 3, offset: 0 }}
            md={{ span: 3, offset: 0 }}
            lg={{ span: 3, offset: 0 }}
            xl={{ span: 3, offset: 0 }}
          >
            <KeyboardBackspace
              style={{ color: "#fff", fontSize: 30 }}
              onClick={() => props.prev()}
            />
          </Col>
          <Col
            xs={{ span: 18, offset: 0 }}
            sm={{ span: 18, offset: 0 }}
            md={{ span: 18, offset: 0 }}
            lg={{ span: 18, offset: 0 }}
            xl={{ span: 18, offset: 0 }}
            style={{ textAlign: "center" }}
          >
            <Title level={3} style={{ color: "#fff" }}>
              Verification
            </Title>
          </Col>
          <Col
            xs={{ span: 3, offset: 0 }}
            sm={{ span: 3, offset: 0 }}
            md={{ span: 3, offset: 0 }}
            lg={{ span: 3, offset: 0 }}
            xl={{ span: 3, offset: 0 }}
          ></Col>
        </Row>
        <br />
        <Row gutter={[8, { xs: 8, sm: 16, md: 24, lg: 32 }]} justify="center">
          <Space direction="vertical" style={{ justifyContent: "center" }}>
            <Title level={4} style={{ color: "#fff", textAlign: "center" }}>
              2-step verification
            </Title>
            <Text
              style={{
                color: "#fff",
                fontSize: 16,
              }}
            >
              This extra step is to make sure it's really you trying to
              withdrawal funds
            </Text>
          </Space>
        </Row>
        <Divider
          style={{
            background: "#fff",
          }}
        ></Divider>
        <Row gutter={[8, { xs: 8, sm: 16, md: 24, lg: 32 }]} justify="center">
          <Space direction="vertical">
            <Text
              style={{
                color: "#fff",
                fontSize: 16,
                padding: "0px 24px",
              }}
            >
              Enter your 2-step verification code
            </Text>

            <Space direction="horizontal" size="small">
              <KeyVariant
                style={{
                  color: "#53ff84",
                  fontSize: 40,
                  width: "20%",
                  float: "right",
                }}
              />
              <Input
                value={props.code}
                maxLength={6}
                size="large"
                style={{ width: "50%", color: "black" }}
                placeholder="000000"
                onChange={(e) => props.handleCode(e)}
              />
            </Space>

          </Space>
        </Row>
        <Divider
          style={{
            background: "#fff",
          }}
        ></Divider>
        <Row gutter={[32, { xs: 8, sm: 16, md: 24, lg: 32 }]} justify="end">
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
          >
            <Button
              block
              size="large"
              type="primary"
              loading={props.loading}
              disabled={props.code === "" || props.code.toString().length !== 6}
              onClick={() => props.confirmTransaction()}
              style={{
                height: 60,
                fontSize: 18,
                fontWeight: 600,
                borderRadius: "5px",
              }}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorSend);
