import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import pendingSettlements from "../../../services/pendingSettlements";

const usePendingSettlementBalance = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pendingSettlementBalances, setPendingSettlementBalances] =
    useState(null);

  const { token: accessToken, clientId: idToken } = useSelector((state) => ({
    token: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
  }));

  const fetchPendingSettlementBalance = async () => {
    try {
      setLoading(true);
      const response = await pendingSettlements.getPendingSettlementBalance(
        idToken,
        accessToken
      );
      setPendingSettlementBalances(response.data.data);
    } catch (err) {
      setError(err);
      console.error("Error fetching pending settlement balances:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPendingSettlementBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    error,
    pendingSettlementBalances,
    refetch: fetchPendingSettlementBalance,
  };
};

export default usePendingSettlementBalance;
