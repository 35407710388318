import React from "react";
import { Tabs, Typography } from "antd";
import "./profile.less";
import Security from "./security";
const { Text } = Typography;
const Profile = () => {
  const tabItems = [
    {
      key: "1",
      label: (
        <Text
          style={{
            fontSize: 18,
            color: "#fff",
          }}
        >
          Security
        </Text>
      ),
      children: <Security />,
    },
  ];
  return <Tabs defaultActiveKey="1" items={tabItems}></Tabs>;
};
export default Profile;
