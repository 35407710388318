import servicesUser from "../../services/user";
export const AUTH_USER = "AUTH_USER";
export const LOGOUT = "LOGOUT";
export const USERS = "USERS";
export const LOGGED_OUT = "LOGGED_OUT";

export function authUser(response) {
  return async (dispatch, getState) => {
    dispatch({
      type: AUTH_USER,
      payload: response,
    });
  };
}
export function logoutUser(id, accessToken) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesUser.userLogout(id, accessToken);
      dispatch({
        type: LOGOUT,
        payload: response,
      });
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: LOGOUT,
          payload: error.response,
        });
      }
    }
  };
}
export const resetStore = () => {
  return {
    type: LOGGED_OUT,
  };
};
