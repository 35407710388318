import {
    ACTIVATE_TWO_FACTOR,
    CONFIRM_TWO_FACTOR,
    LOGIN_TWO_FACTOR,
    USER_TWO_FACTOR
  } from "../actions/twoFactorActions";
  const initialState = {
    twoFactor: {},
    confirm: {},
    loginTwoFactor: {},
    userTwoFactor: {}
  };
  
  export function twoFactor(state = initialState, action) {
    switch (action.type) {
      case ACTIVATE_TWO_FACTOR:
        return {
            ...state,
            twoFactor: action.payload
        };
      case CONFIRM_TWO_FACTOR:
        return {
            ...state,
            confirm: action.payload
        };
      case LOGIN_TWO_FACTOR:
        return {
            ...state,
            loginTwoFactor: action.payload
        }; 
      case USER_TWO_FACTOR:
        return {
            ...state,
            userTwoFactor: action.payload
        }; 
      default:
        return state;
    }
  }
  