import {
  AUTH_USER,
  LOGOUT,
} from "../actions/authActions";
const initialState = {
  authResult: {},
  logout: {},  
};
export function authUser(state = initialState, action) {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state, 
        authResult: action.payload.data,
        headers: action.payload.headers
       };
    case LOGOUT:
      return {
        logout: action.payload
      };
    default:
      return state;
  }
};

export default authUser;
