import axios from "axios";
import config from "./config";
const BASE_URL = config.URL;

const profile = {
  async getProgile(idToken, accessToken) {
    const resp = await axios.get(BASE_URL + config.getProfile, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
};
export default profile;
