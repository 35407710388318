import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { Col, Card, Space, Typography, Image } from "antd";
import "./dashboard.less";
import NumberFormat from "react-number-format";
import { asset } from "../../common/assets";
const { Text, Title } = Typography;

const FiatBalanceTable = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const [flag, setFlag] = useState(false);
  function getInfoCurrency(currency) {
    let data = asset.assets;
    let findCurrency = data.find((element) => element.value === currency);
    return findCurrency;
  }
  const columns = [
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Currency
        </Text>
      ),
      dataIndex: "currency",
      key: "currency",
      render: (currency) => (
        <>
          <Space align="center">
            <Image
              style={{ alignItems: "center", display: "inline-flex" }}
              preview={false}
              width={25}
              src={ ( currency ) &&  getInfoCurrency(currency)?.icon}
            />
            <Text
              style={{
                color: "#fff",
                fontSize: 16,
              }}
            >
              {
                ( currency ) && getInfoCurrency(currency)?.name
              }
            </Text>
          </Space>
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Balance
        </Text>
      ),
      dataIndex: "client_balance",
      key: "client_balance",
      render: (record, originNode) => (
        <>
          <Text
            style={{
              color: "#fff",
              fontSize: 18,
            }}
          >
            <NumberFormat
              thousandSeparator={true}
              displayType="text"
              prefix={(originNode && originNode?.currency && originNode?.currency?.symbol) && getInfoCurrency(originNode?.currency)?.symbol + " "}
              decimalScale={2}
              value={originNode.client_balance}
            />
          </Text>
        </>
      ),
    },
  ];

  
  useEffect(() => {
    if(!flag){
      let newArray = [];
      for (let i = 0; i < props.data.balances.length; i++){
        if(props.data !== undefined && props.data.balances[i].currency_type === "FIAT"){
          newArray.push(
            {
              key: props.data.balances[i].currency,
              ...props.data.balances[i],
            }
          );
        }
      }      
      setDataSource(newArray);
      setFlag(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Col span="12">
      <Card className="graph-card" >
        <Title
          level={4}
          style={{
            color: "#fff",
            textAlign: "left",
          }}
        >
          User balances by Asset
        </Title>
        <div
          id="table-balances"
          className="ant-table ant-table-tbody ant-table-thead text-table-cell"
        >
          {
            dataSource.length > 5 
            ?
              <Table dataSource={dataSource} columns={columns} pagination={{ pageSize: 5 }} />
            :
              <Table dataSource={dataSource} columns={columns} pagination={false} />
          }
        </div>
      </Card>
    </Col>
  );
};

export default FiatBalanceTable;
