import React, { useEffect } from "react";
import "./layouts.less";
import { Route, Redirect } from "react-router-dom";
import DashboardLayout from "./dashboardLayout";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";

const DashboardLayoutRoute = ({ component: Component, ...rest }) => {
  const { token } = useSelector((state) => ({
    token: state.tokenAccess.current,
  }));
  useEffect(() => {
    const user = window.localStorage.getItem("success");
    var decoded = jwt_decode(token);
    if (user) {
      if (Date.now() >= decoded.exp * 1000) {
        window.localStorage.removeItem("success");
        return <Redirect to="/signin" />;
      }
    }
  }, [token]);

  return (
    <Route
      {...rest}
      render={(props) => (
        <DashboardLayout>
          <Component {...props} />
        </DashboardLayout>
      )}
    />
  );
};

export default DashboardLayoutRoute;
