import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Typography, Card, Input, Divider, Button, DatePicker, Form} from "antd";
import {
    UserOutlined 
} from "@ant-design/icons";
import "./../actions.less";

const { Title, Text } = Typography;

const EditUser = () => {
    return (
        <Row>            
            <Col span={24}>
                <h2 style={{ color: "#fff"}}>Editing User John Reed</h2>
                <Card className="card" bodyStyle={{ padding: 40 }}>
                    <Title
                        level={4}
                        style={{
                            color: "#fff",
                            textAlign: "left",
                            marginTop: "-20px"
                        }}
                    >
                        <UserOutlined 
                            style={{
                                marginRight: "20px"
                            }}
                        />
                        User   
                        <Divider 
                            style={{
                                background: "#ccc",
                                margin: "0",
                                marginLeft: "-60px",
                                marginBottom: "10px",
                                marginTop: "10px",
                                width: "105%"
                            }}
                        />
                    </Title>
                    
                    <h5>ID: 1234</h5>
                    <h5>Registration Date: 11-12-2021 02:00pm</h5>
                    <h5>Customer ID (Modulr): 9809AGTR532</h5>
                    <Col                        
                        xs={{ span: 24, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        md={{ span: 18, offset: 3 }}
                        lg={{ span: 14, offset: 5 }}
                        xl={{ span: 12, offset: 6 }}
                    >
                         <Form
                            name="basic"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValues={{ remember: true }}
                            autoComplete="off"
                            style={{
                                color: "#fff"
                            }}
                        >
                            <Form.Item
                                label="Username"
                                name="username"
                                rules={[{ required: true, message: 'Please input the username' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Email"
                                name="Email"
                                rules={[{ required: true, message: 'Please input the email' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="First Name"
                                name="First Name"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Last Name"
                                name="Last Name"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Birthdate"
                                name="Birthdate"
                            >
                                <DatePicker
                                    size="large"
                                    format="YYYY-MM-DD"
                                    showTime={false}
                                    showToday={false}
                                    style={{
                                        width: "100%"
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Phone"
                                name="Phone"
                            >
                                <Input />
                            </Form.Item>                             
                            <Divider plain
                                orientation="left"
                                style={{                                
                                    borderTopColor: "#fff",
                                    color: "#fff",
                                    margin: "10px 0px 10px 160px",
                                }}
                            >
                                <Text style={{ color: "#fff", fontSize: 16 }}>
                                    Address
                                </Text>
                            </Divider>                            
                            <Form.Item
                                label="Street 1"
                                name="Street 1"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Street 2"
                                name="Street 2"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Region"
                                name="Region"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="City"
                                name="City"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Postal Code"
                                name="Postal Code"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Country"
                                name="Country"
                            >
                                <Input />
                            </Form.Item>
                        </Form>
                    </Col>
                    <Divider 
                        style={{
                            background: "#ccc",
                            margin: "0",
                            marginLeft: "-40px",
                            marginBottom: "5px",
                            marginTop: "25px",
                            width: "104%"
                        }}
                    />
                    <Row
                        style={{
                            marginTop: "30px"
                        }}
                    >
                        <Col
                            span={12}                            
                            style={{ 
                                display: "flex", 
                                justifyContent: "flex-start" 
                            }}
                        >
                            <Link to="/users/actions">
                                <Button size="large">
                                    Cancel
                                </Button>
                            </Link>
                        </Col>
                        <Col
                            span={12}
                            style={{ 
                                display: "flex", 
                                justifyContent: "flex-end" 
                            }}
                        >
                            <Button type="primary" size="large">
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    )
}

export default EditUser;
