import React from "react";
import { Table } from "antd";
import {
  Card,
  Typography,
  Select,
  Row,
  Col,
  Button,
  Tag,
  Tooltip,
  Space,
} from "antd";
import {
  SearchOutlined,
  UnorderedListOutlined,
  PauseCircleOutlined,
  ExclamationCircleOutlined,
  IssuesCloseOutlined,
  CloseCircleOutlined,
  PlaySquareOutlined,
} from "@ant-design/icons";
import "./cards.less";
import { asset } from "../../common/assets";
const { Text } = Typography;
const { Option } = Select;
const status = [
  {
    key: "ACTIVE",
    value: "Active",
  },
  {
    key: "FREEZE",
    value: "Freeze",
  },
  {
    key: "INACTIVE",
    value: "Inactive",
  },
  { key: "ALL", value: "All" },
];
const CardsTable = (props) => {
  const dataSource = [];
  dataSource.push(
    {
      userID: "abcd12345678",
      email: "yosbely@damex.io",
      cardID: "123456789101112",
      status: "ACTIVE",
      key: "abcd12345678",
    },
    {
      userID: "xyz12345678",
      email: "jose@damex.io",
      cardID: "123456789101112",
      status: "INACTIVE",
      key: "xyz12345678",
    },
    {
      userID: "xyz12345679",
      email: "julio@damex.io",
      cardID: "123456789101112",
      status: "FREEZE",
      key: "xyz12345679",
    }
  );
  const currencies = asset.assets.filter((element) => element.type === "FIAT");
  currencies.push({ value: "ALL", name: "All" });
  const columns = [
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          User ID
        </Text>
      ),
      dataIndex: "userID",
      key: "userID",
    },

    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Email
        </Text>
      ),
      dataIndex: "email",
      key: "email",
    },

    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Status
        </Text>
      ),
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <>
          {status === "ACTIVE" && (
            <Tag
              style={{
                marginTop: 5,
                color: "#389E0D",
                background: "#F6FFED",
                borderColor: "#B7EB8F",
                fontSize: 12,
              }}
            >
              {status}
            </Tag>
          )}

          {status === "INACTIVE" && (
            <Tag
              style={{
                marginTop: 5,
                color: "#CF1322",
                background: "#FFF1F0",
                borderColor: "#FFA39E",
                fontSize: 12,
              }}
            >
              {status}
            </Tag>
          )}
          {status === "FREEZE" && (
            <Tag
              style={{
                marginTop: 5,
                color: "blue",
                // background: "#FFF1F0",
                // borderColor: "#FFA39E",
                fontSize: 12,
              }}
            >
              {status}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Action
        </Text>
      ),
      dataIndex: "",
      key: "x",
      render: (record, originNode) => (
        <Space>
          <a href="/cards">
            <Tooltip placement="top" title="View Details">
              <SearchOutlined />
            </Tooltip>
          </a>
          <a href="/cards">
            <Tooltip placement="top" title="View Transactions">
              <UnorderedListOutlined />
            </Tooltip>
          </a>

          {originNode.status === "ACTIVE" && (
            <>
              <a href="/cards">
                <Tooltip placement="top" title="Freeze">
                  <PauseCircleOutlined />
                </Tooltip>
              </a>
            </>
          )}
          {originNode.status === "FREEZE" && (
            <a href="/cards">
              <Tooltip placement="top" title="Unfreeze">
                <PlaySquareOutlined />
              </Tooltip>
            </a>
          )}
          <a href="/cards">
            <Tooltip placement="top" title="Suspend">
              <ExclamationCircleOutlined />
            </Tooltip>
          </a>
          {originNode.status === "SUSPEND" && (
            <a href="/cards">
              <Tooltip placement="top" title="Unsuspend">
                <IssuesCloseOutlined />
              </Tooltip>
            </a>
          )}
          <a href="/cards">
            <Tooltip placement="top" title="Cancel card">
              <CloseCircleOutlined />
            </Tooltip>
          </a>
        </Space>
      ),
    },
  ];
  return (
    <Card
      className="card-transactions"
      bodyStyle={{ padding: "10px 0px 10px 0px" }}
    >
      <Row
        gutter={[8, { xs: 8, sm: 16, md: 16, lg: 16, xl: 16 }]}
        style={{ padding: "5px 10px" }}
      >
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 8, offset: 0 }}
          xl={{ span: 8, offset: 0 }}
          xxl={{ span: 8, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Select style={{ width: "100%" }} size="large" placeholder="Email">
            <Option key="1">julio@damex.io</Option>
          </Select>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 8, offset: 0 }}
          xl={{ span: 8, offset: 0 }}
          xxl={{ span: 8, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Select style={{ width: "100%" }} size="large" placeholder="Status">
            {status.map((item, i) => {
              return (
                <Option key={i} value={item.key}>
                  {item.value}
                </Option>
              );
            })}
          </Select>
        </Col>

        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 4, offset: 0 }}
          xl={{ span: 4, offset: 0 }}
          xxl={{ span: 4, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Button type="primary" size="large" style={{ width: "100%" }}>
            Filter
          </Button>
        </Col>
      </Row>

      <div
        id="table-history"
        className="ant-table ant-table-tbody ant-table-thead text-table-cell ant-table-cell"
        style={{ marginTop: 10 }}
      >
        <Table
          rowClassName="row-table-deposit"
          dataSource={dataSource}
          scroll={{ x: 1300 }}
          columns={columns}
          pagination={{ pageSize: 20 }}
        />
      </div>
    </Card>
  );
};

export default CardsTable;
