import { USERS, ACCOUNTS, CURRENCIES } from "../actions/otcActions";
const initialState = {
  users: {},
  accounts: {},
  currencies: {},
};
export function otc(state = initialState, action) {
  switch (action.type) {
    case USERS:
      return {
        ...state,
        users: action.payload,
      };
    case ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
      };
    case CURRENCIES:
      return {
        ...state,
        currencies: action.payload,
      };
    default:
      return state;
  }
}

export default otc;
