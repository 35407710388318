//import { applyMiddleware } from "redux";
import { configureStore, Tuple } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { thunk } from "redux-thunk";
import reducers from "./reducers/reducers";

const persistConfig = {
  key: "auth",
  storage: storage,
  whitelist: [
    "authUser",
    "balance",
    "tokenAccess",
    "twoFactor",
    "transaction",
    "navigate",
    "otc",
    "users",
    "commissions",
    "amlTransaction",
    "profileReducer",
  ], // which reducer want to store
};

const pReducer = persistReducer(persistConfig, reducers);
export const store = configureStore({
  reducer: pReducer,
  middleware: () => new Tuple(thunk),
  devTools: process.env.NODE_ENV !== "production",
});
export const persistor = persistStore(store);
