import axios from "axios";
import config from "./config";
const BASE_URL = config.URL;

const otc = {
  async createTransactionOtc(idToken, accessToken, body) {
    const resp = await axios.post(BASE_URL + config.createTransaction, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async confirmTransactionOtc(idToken, accessToken, body) {
    const resp = await axios.post(BASE_URL + config.confirmTransaction, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async getCurrencies(clientId, accessToken) {
    const resp = await axios.get(BASE_URL + config.supportedCurrencies, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": clientId,
      },
    });
  return resp;
  },
};
export default otc;
