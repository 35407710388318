import React, {useState} from "react";
import { Row, Col, Typography, Card, Select, Tooltip, Divider, Input } from "antd";
import {
    EditOutlined,
    UserOutlined ,
    CloseOutlined
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import "./actions.less";


const { Title, Text } = Typography;
const { Option } = Select;


const status = [
    {
      key: "ACTIVE",
      value: "Active",
    },
    {
      key: "INACTIVE",
      value: "Inactive",
    }
  ];

const Actions = () => {
    const [isEditingBuy, setIsEditingBuy] = useState(false);
    const [isEditingSell, setIsEditingSell] = useState(false);
    const [percBuy, setPercBuy] = useState(0);
    const [percSell, setPercSell] = useState(0);

    const editingBuy = () => {
        setIsEditingBuy(!isEditingBuy);
    }

    const handleInputChangeB = (e) => {
        setPercBuy(e.target.value);
    }

    const handleInputChangeS = (e) => {
        setPercSell(e.target.value);
    }

    return (
        <Row>
            <Col
                xs={{ span: 24, offset: 1 }}
                sm={{ span: 24, offset: 1 }}
                md={{ span: 24, offset: 1 }}
                lg={{ span: 12 , offset: 0 }}
                xl={{ span: 12, offset: 0 }}
            >
                <Card className="card" bodyStyle={{ width: "100%" }}>
                    <Row>
                        <Col 
                            span={22}  
                        >   
                            <Title
                                level={4}
                                style={{
                                    color: "#fff",
                                    textAlign: "left",
                                }}
                            >       
                                <UserOutlined 
                                    style={{
                                        marginRight: "20px"
                                    }}
                                />
                                John Reed - johnreed@gmail.com                                
                            </Title>                      
                        </Col>
                        <Col 
                            span={2}
                            style={{
                                display: "flex",
                                justifyContent: "flex-end"
                            }}
                        >
                        <Title
                            level={4}
                            style={{
                                color: "#fff",
                                textAlign: "left",
                            }}
                        >        
                            <NavLink to="/users/actions/editUser">
                                <Tooltip placement="top" title="Edit User">                                    
                                    <EditOutlined 
                                        style={{
                                            marginLeft: "20px"
                                        }}
                                    />
                                </Tooltip>      
                            </NavLink> 
                        </Title>       
                        </Col>
                    </Row>                    
                    <Divider 
                        style={{
                            background: "#ccc",
                            margin: "0",
                            marginLeft: "-25px",
                            marginBottom: "10px",
                            width: "105%"
                        }}
                    />
                    <Row>
                        <Col
                            span={10}
                            style={{
                                textAlign: "right",
                                marginLeft: "-5px"
                            }}
                        >
                            <h5>ID:</h5>
                            <h5>Registration Date:</h5>
                            <h5>Customer ID (Modulr): </h5>
                            <h5>Email: </h5>
                            <h5>Username: </h5>
                            <h5>First Name:</h5>
                            <h5>Last Name: </h5>
                            <h5>Birthdate: </h5>
                            <h5>Phone: </h5>
                            <h5>Type: </h5>
                        </Col>
                        <Col
                            span={14}
                        >
                            <h5>1234</h5>
                            <h5>11-12-2021 02:00pm</h5>
                            <h5>9809AGTR532</h5>
                            <h5>john@gmail.com</h5>
                            <h5>johnre</h5>
                            <h5>John</h5>
                            <h5>Reed</h5>
                            <h5>11-12-1990</h5>
                            <h5>+3456987456</h5>
                            <h5> - </h5>
                            <h5> - </h5>                            
                        </Col>
                    </Row>
                    <Row>
                        <Col
                            span={10}
                            style={{
                                textAlign: "right",
                                marginLeft: "-5px"
                            }}
                        >
                            <h5>
                                Status:
                            </h5>
                        </Col>
                        <Col
                            span={14}
                        >                            
                            <div
                                id="select-trade"
                                className="ant-select-selector ant-select"
                                style={{ 
                                    width: "100%", 
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    marginLeft: "20px"
                                }}
                            >
                                <Select style={{ width: "50%" }} size="medium" placeholder="Status">
                                    {status.map((item, i) => {
                                    return <Option key={i} value={item.key}>{item.value}</Option>;
                                    })}
                                </Select>
                            </div>     
                        </Col>
                    </Row>
                </Card>
                <Card className="card sec-card" bodyStyle={{ padding: 40 }}>                    
                    <Title
                        level={4}
                        style={{
                            color: "#fff",
                            textAlign: "left",
                            marginTop: "-9px"
                        }}
                    >
                        Address                  
                    </Title>                      
                    <Divider 
                        style={{
                            background: "#ccc",
                            margin: "0",
                            marginLeft: "-40px",
                            marginBottom: "20px",
                            width: "108.5%"
                        }}
                    />
                    <Row>
                        <Col
                            span={10}
                            style={{
                                textAlign: "right",
                                marginLeft: "-5px"
                            }}
                        >
                            <h5>Street 1:</h5>
                            <h5>Street 2:</h5>
                            <h5>Region: </h5>
                            <h5>City: </h5>
                            <h5>Postal Code: </h5>
                            <h5>Country:</h5>
                        </Col>
                        <Col
                            span={14}
                        >
                            <h5>Street</h5>
                            <h5> - </h5>
                            <h5>East Side </h5>
                            <h5> - </h5>
                            <h5> GX11 1AA</h5>
                            <h5>Gibraltar</h5>                            
                        </Col>
                    </Row>                 
                </Card>
                <Card className="card sec-card" bodyStyle={{ padding: 40 }}>                    
                    <Title
                        level={4}
                        style={{
                            color: "#fff",
                            textAlign: "left",
                            marginTop: "-9px"
                        }}
                    >
                        Damex Card Info               
                    </Title>                      
                    <Divider 
                        style={{
                            background: "#ccc",
                            margin: "0",
                            marginLeft: "-40px",
                            marginBottom: "20px",
                            width: "108.5%"
                        }}
                    />
                    <Row>
                        <Col
                            span={10}
                            style={{
                                textAlign: "right",
                                marginLeft: "-5px"
                            }}
                        >                            
                            <h5>PAN:</h5>
                            <h5>Account Holder Name:</h5>
                            <h5>Expiry Date: </h5>
                        </Col>
                        <Col
                            span={14}
                        >                            
                        </Col>
                    </Row>                 
                </Card>
            </Col>
            <Col
                xs={{ span: 24, offset: 1 }}
                sm={{ span: 24, offset: 1 }}
                md={{ span: 24, offset: 1 }}
                lg={{ span: 12 , offset: 0 }}
                xl={{ span: 12, offset: 0 }}
            >                
                <Card className="card" bodyStyle={{ padding: 40 }}>                    
                    <Title
                        level={4}
                        style={{
                            color: "#fff",
                            textAlign: "left",
                            marginTop: "-9px"
                        }}
                    >
                        2FA Configurations
                    </Title>                          
                    <Divider 
                        style={{
                            background: "#ccc",
                            margin: "0",
                            marginLeft: "-40px",
                            marginBottom: "20px",
                            width: "108.5%"
                        }}
                    />                    
                    <Row>
                        <Col                        
                            xs={{ span: 24, offset: 0 }}
                            sm={{ span: 24, offset: 0 }}
                            md={{ span: 16, offset: 4 }}
                            lg={{ span: 14, offset: 5 }}
                            xl={{ span: 12, offset: 6 }}
                            style={{
                                textAlign: "left"
                            }}
                        >
                            <Divider plain
                                orientation="left"
                                style={{                                
                                    borderTopColor: "#fff",
                                    color: "#fff",
                                    margin: "10px 0px 10px 50px"
                                }}
                            >
                                <Text style={{ color: "#fff", fontSize: 16 }}>
                                    Login
                                </Text>
                            </Divider>
                            <h5 style={{ marginLeft: "50px" }}>Type:  Email</h5>
                            <h5 style={{ marginLeft: "50px" }}>Status: Disabled</h5>
                            <Divider plain
                                orientation="left"
                                className="funding-divider"
                                style={{                                
                                    borderTopColor: "#fff",
                                    color: "#fff",
                                    margin: "10px 0px 10px 50px"
                                }}
                            >
                                <Text style={{ color: "#fff", fontSize: 16 }}>
                                    Funding
                                </Text>
                            </Divider>
                            <h5 style={{ marginLeft: "50px" }}>Type: Google Authenticator</h5>
                            <h5 style={{ marginLeft: "50px" }}>Status: Enabled</h5>
                        </Col>
                    </Row>                                     
                </Card>
                <Card className="card sec-card" bodyStyle={{ padding: 40 }}>                    
                    <Title
                        level={4}
                        style={{
                            color: "#fff",
                            textAlign: "left",
                            marginTop: "-9px"
                        }}
                    >
                        Challenges
                    </Title>                                          
                    <Divider 
                        style={{
                            background: "#ccc",
                            margin: "0",
                            marginLeft: "-40px",
                            marginBottom: "20px",
                            width: "108.5%"
                        }}
                    />    
                    
                    <Row>
                        <Col
                            xs={{ span: 24, offset: 0 }}
                            sm={{ span: 24, offset: 0 }}
                            md={{ span: 10, offset: 7 }}
                            lg={{ span: 8, offset: 8 }}
                            xl={{ span: 6, offset: 9 }}
                            style={{
                                textAlign: "center"
                            }}
                        >
                            <h5>Steps Last Month: -</h5>                    
                            <h5>Steps Current Month: -</h5>
                            <h5>Rewards Achive: -</h5>                    
                        </Col>
                    </Row> 
                </Card>      
                <Card className="card sec-card" bodyStyle={{ padding: 40 }}>                    
                    <Title
                        level={4}
                        style={{
                            color: "#fff",
                            textAlign: "left",
                            marginTop: "-9px"
                        }}
                    >
                        Personalized Fees
                    </Title>                          
                    <Divider 
                        style={{
                            background: "#ccc",
                            margin: "0",
                            marginLeft: "-40px",
                            marginBottom: "20px",
                            width: "108.5%"
                        }}
                    />                    
                    <Row>
                        <Col                        
                            xs={{ span: 24, offset: 0 }}
                            sm={{ span: 24, offset: 0 }}
                            md={{ span: 16, offset: 4 }}
                            lg={{ span: 14, offset: 5 }}
                            xl={{ span: 12, offset: 6 }}
                            style={{
                                textAlign: "left"
                            }}
                        >
                            <Divider plain
                                orientation="left"
                                style={{                                
                                    borderTopColor: "#fff",
                                    color: "#fff",
                                    margin: "10px 0px 10px 50px"
                                }}
                            >
                                <Text style={{ color: "#fff", fontSize: 16 }}>
                                    Buy Markup
                                </Text>
                            </Divider>
                            <h5 style={{ marginLeft: "50px" }}>Standard: 1.5%</h5>
                            <div style={{ display: "flex"}}>
                                {
                                    isEditingBuy ? 
                                    (
                                        <>
                                            <h5 style={{ marginLeft: "50px" }}>
                                                Percentage Discount/Extra Fee: 
                                                <Input
                                                    type="number"
                                                    style={{ width: "20%", marginLeft: "5px", marginRight: "5px" }}
                                                    size="medium"
                                                    value={ percBuy }
                                                    onChange={ handleInputChangeB }
                                                ></Input>% 
                                            </h5>                            
                                            <Typography.Link 
                                                onClick={() => editingBuy()}
                                                style={{
                                                    marginTop: "4px",
                                                    marginLeft: "-35px"
                                                }}
                                            >
                                                <Tooltip placement="top" title="Close">    
                                                    <CloseOutlined 
                                                        style={{
                                                            marginLeft: "20px"
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Typography.Link>                                            
                                        </>
                                    )
                                    :(
                                        <>
                                            <h5 style={{ marginLeft: "50px" }}>Percentage Discount/Extra Fee: { percBuy }%</h5>                            
                                            <Typography.Link 
                                                onClick={() => editingBuy()}
                                            >
                                                <Tooltip placement="top" title="Edit">    
                                                    <EditOutlined 
                                                        style={{
                                                            marginLeft: "20px"
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Typography.Link>
                                        </>                                        
                                    )
                                }                                
                            </div>
                            <h5 style={{ marginLeft: "50px" }}>
                                Total:  
                                {
                                    percBuy === 0 ? "1.5" 
                                    : percBuy < 0 ? ((0.015 * ( percBuy / 100)) * -1) * 100
                                    : (0.015 * ( 1 + (percBuy / 100))) * 100
                                }%
                            </h5>
                            <Divider plain
                                orientation="left"
                                className="funding-divider"
                                style={{                                
                                    borderTopColor: "#fff",
                                    color: "#fff",
                                    margin: "10px 0px 10px 50px"
                                }}
                            >
                                <Text style={{ color: "#fff", fontSize: 16 }}>
                                    Sell Markup
                                </Text>
                            </Divider>
                            <h5 style={{ marginLeft: "50px" }}>Standard: 1.5%</h5>
                            <div style={{ display: "flex"}}>
                                {
                                    isEditingSell ? 
                                    (
                                        <>
                                            <h5 style={{ marginLeft: "50px" }} >
                                                Percentage Discount/Extra Fee: 
                                                <Input
                                                    type="number"
                                                    style={{ width: "20%", marginLeft: "5px", marginRight: "5px" }}
                                                    size="medium"
                                                    value={ percSell }
                                                    onChange={ handleInputChangeS }
                                                ></Input>%
                                            </h5>                            
                                            <Typography.Link 
                                                onClick={() => { setIsEditingSell(!isEditingSell) }}
                                                style={{
                                                    marginTop: "4px",
                                                    marginLeft: "-35px"
                                                }}
                                            >
                                                <Tooltip placement="top" title="Close">    
                                                    <CloseOutlined 
                                                        style={{
                                                            marginLeft: "20px"
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Typography.Link>                                            
                                        </>
                                    )
                                    :(
                                        <>
                                            <h5 style={{ marginLeft: "50px" }}>Percentage Discount/Extra Fee: { percSell }%</h5>                            
                                            <Typography.Link 
                                                onClick={() => { setIsEditingSell(!isEditingSell) }}
                                            >
                                                <Tooltip placement="top" title="Edit">    
                                                    <EditOutlined 
                                                        style={{
                                                            marginLeft: "20px"
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Typography.Link>
                                        </>                                        
                                    )
                                }
                            </div>                            
                            <h5 style={{ marginLeft: "50px" }}>
                                Total:  
                                {
                                    percSell === 0 ? "1.5" 
                                    : percSell < 0 ? ((0.015 * ( percSell / 100)) * -1) * 100
                                    : (0.015 * ( 1 + (percSell / 100))) * 100
                                }%
                            </h5>
                        </Col>
                    </Row>                                     
                </Card>
            </Col>
        </Row>
    )
}

export default Actions;
