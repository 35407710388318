import axios from "axios";
import config from "./config";
const BASE_URL = config.URL;

const balance = {
    
    async balance( idToken, accessToken ) {
        const resp = await axios.get(BASE_URL + config.balance, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "X-CLIENT-ID": idToken,
            },
          });
        return resp;
    }
}

export default balance;