import { TOKEN, CLIENT_ID, REFRESH_TOKEN } from "../actions/tokenActions";
const initialState = {
  current: "",
  clientId: "",
  tokenResponse: {},
};

export function tokenAccess(state = initialState, action) {
  switch (action.type) {
    case TOKEN:
      return {
          ...state,
          current: action.payload
      }
    case CLIENT_ID:
      return {
        ...state,
        clientId: action.payload
      }
    case REFRESH_TOKEN:
      return Object.assign({}, state, { tokenResponse: action.payload });
    default:
      return state;
  }
}

export default tokenAccess
