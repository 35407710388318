const config = {
  ENUM: process.env.REACT_APP_ENUM,
  URL: process.env.REACT_APP_API_ENDPOINT,
  //--- Access
  login: "/auth",
  logout: "/auth/logout",
  //--- Balance
  balance: "/balance",
  //--- Refresh token
  refreshToken: "/auth/refresh",
  //--- Two factor
  activateTwoFactor: "/two-factor",
  confirmTwoFactor: "/two-factor/confirm",
  authTwoFactor: "/auth/two-factor",
  transaction: "/transactions",
  //--- Users
  users: "/damex-users/business-users",
  allUsers: "/damex-users/all-users",
  updateUser: "/damex-users/update-user/",
  commissionersOrIntroducers: "/commissioners-or-introducers",
  usersReviewers: "/profile/admin-and-compliance-users",
  whitelist: "/whitelist",
  //--- OTC
  accounts: "/damex-users/business-user-accounts/",
  createTransaction: "/transactions/create-transaction-otc",
  confirmTransaction: "/transactions/confirm-transaction-otc",
  //--- Currencies
  supportedCurrencies: "/supported-currencies",
  //--- Finance
  commissions: "/commissioners-or-introducers/commissions",
  // AML Transaction monitoring
  getTransactionsAml: "/transaction-monitoring/transactions",
  changeStatusTransactionMonitoring: "/transaction-monitoring/transactions/",
  //--- High Value Transactions
  highValueTransactions: "/high-value-transactions",
  highValueBalance: "/balance/high-value",
  pendingSettlementBalance: "/balance/pending-settlement-balance",
  //--- Profile
  getProfile: "/profile",
  //--- Rules FTM 
  rulesFtm:"/transaction-monitoring"
};

export default config;
