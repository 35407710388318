import React from "react";
import { Tabs, Typography } from "antd";
import "./amlScreening.less";
import AmlScreeningTable from "./amlScreeningTable";
import { connect } from "react-redux";
import { getAmlTransaction } from "../../redux/actions/amlActions";
import { currentToken } from "../../redux/actions/tokenActions";
const { Text } = Typography;
const AmlScreening = (props) => {
  // const [active, setActive] = useState("1");
  const items = [
    {
      key: "1",
      label: (
        <Text
          style={{
            fontSize: 18,
            color: "#fff",
          }}
        >
          Damex Direct
        </Text>
      ),
      children: <AmlScreeningTable source={"Damex Direct"} />,
    },
  ];
  return (
    <Tabs
      defaultActiveKey="1"
      onTabClick={(key) => {
        // setActive(key);
      }}
      items={items}
      destroyInactiveTabPane={true}
    ></Tabs>
  );
};

const mapStateToProps = (state) => {
  return {
    transactionResult: state.amlTransaction.transactionAmlResponse,
    token: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
  };
};

const mapDispatchToProps = {
  getAmlTransaction,
  currentToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(AmlScreening);
