import React from "react";
import {
  Row,
  Col,
  Space,
  Typography,
  Button,
  Divider,
  Modal,
  Input,
  Form
} from "antd";
// import COLOR from "../../const/colors";
import { CloseOutlined } from "@ant-design/icons";
import "./profile.less";

const { Text, Title } = Typography;

const TwoFactorModal = (props) => {
    const [form] = Form.useForm();
    const onInputChange = (value) => {
        props.verificationCodeReceived(value.code)
        form.resetFields();
    }
    
    const closeModal = () => {
      form.resetFields();
      props.closeModal();
    }

    return (
      <div>
        <Modal
          title={
            <Title level={4} 
            style={{ color: "#fff", textAlign: "center" }}
            >
              Confirm settings change
            </Title>
          }
          footer={null}
          className="container-modal-security"
          bodyStyle={{
            background: "#00001E",
            color: "#fff",
            textAlign: "center",
            padding: "24px 0px 24px 0px",
          }}
          maskStyle={{ backgroundColor: "rgba(0,0, 0, 0.90)" }}
          open={props.modalActivate}
          onOk={() => props.openModal()}
          onCancel={() => props.closeModal()}
          centered={true}
          closeIcon={<CloseOutlined 
          style={{ color: "#25253A" }} 
            />}
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            <Row
              gutter={[8, { xs: 8, sm: 16, md: 24, lg: 32 }]}
              justify="center"
            >
                <Space direction="vertical">
                <Title level={4} style={{ color: "#fff", textAlign: "center" }}>
                    2-step verification
                </Title>
                <Text
                  style={{
                    color: "#fff",
                    fontSize: 16,
                    padding: "0px 24px",
                  }}
                >
                  This extra step is to make sure it's really you trying to change settings 
                </Text>
              </Space>
            </Row>
            <Divider
              style={{
                background: "#fff",
              }}
            ></Divider>
            <Row
              gutter={[8, { xs: 8, sm: 16, md: 24, lg: 32 }]}
              justify="center"
            >
              <Space direction="vertical">
                <Text
                  style={{
                    color: "#fff",
                    fontSize: 16,
                    padding: "0px 24px",
                  }}
                >
                  Enter your 2-step verification code
                </Text>

                <Space direction="horizontal" size="small" style={{ width: "98%", paddingLeft: "40px" }}>
                 <Form 
                  onFinish={ onInputChange } 
                  name="modal-two-factor"
                  form={form}
                  style={{ width: "98%" }}
                 >
                    <Form.Item name="code" rules={[
                        ({getFieldValue}) =>({
                            validator(rule, value){
                                if(!value || getFieldValue("code").length === 6){
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error("Please complete the verification code"));
                            }  
                        })
                    ]}>
                        <Input
                            maxLength={ 6 }
                            size="large"
                            style={{ width: "50%",  color:"black" }}
                            placeholder="000000"
                        /> 
                    </Form.Item>
                    <Row gutter={[32, { xs: 8, sm: 16, md: 24, lg: 32 }]} justify="end">
                        <Col
                            xs={{ span: 24, offset: 0 }}
                            sm={{ span: 24, offset: 0 }}
                            md={{ span: 12, offset: 0 }}
                            lg={{ span: 12, offset: 0 }}
                            xl={{ span: 12, offset: 0 }}
                        >
                            <Button
                              block
                              size="large"
                              className="btn-previous-security"
                              onClick={() => closeModal()}
                            >
                            Cancel
                            </Button>
                        </Col>
                        <Col
                            xs={{ span: 24, offset: 0 }}
                            sm={{ span: 24, offset: 0 }}
                            md={{ span: 12, offset: 0 }}
                            lg={{ span: 12, offset: 0 }}
                            xl={{ span: 12, offset: 0 }}
                        >
                            <Button
                            block
                            size="large"
                            type="primary"
                            loading={props.loading}
                            htmlType="submit"
                            style={{
                                height: 60,
                                fontSize: 18,
                                fontWeight: 600,
                                borderRadius: "5px",
                            }}
                            >
                            Confirm
                            </Button>
                        </Col>
                    </Row>
                 </Form>
                </Space>
              </Space>
            </Row>
          </Space>
        </Modal>
      </div>
    );
}

export default TwoFactorModal;
