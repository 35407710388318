const INITIAL_STATE = {
  pageNavigate: { name: "Dashboard", icon: "DashboardOutlined", key: "1" },
};

const navigate = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "PAGE":
      return Object.assign({}, state, { pageNavigate: action.payload });
    default:
      return state;
  }
};

export default navigate;
