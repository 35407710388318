import { combineReducers } from "redux";
import authReducer from "./authReducer";
import balanceReducer from "./balanceReducer";
import tokenReducer from "./tokenReducer";
import transaction from "./transactionReducer";
import { twoFactor } from "./twoFactorReducer";
import otcReducer from "./otcReducer";
import storage from "redux-persist/lib/storage";
import { LOGGED_OUT } from "../actions/authActions";
import navigate from "./navigateReducer";
import users from "./userReducer";
import commissions from "./commissionsReducer";
import amlTransaction from "./amlReducer";
import profileReducer from "./profileReducer";
const reducers = combineReducers({
  authUser: authReducer,
  balance: balanceReducer,
  tokenAccess: tokenReducer,
  twoFactor: twoFactor,
  transaction: transaction,
  navigate: navigate,
  otc: otcReducer,
  users: users,
  commissions: commissions,
  amlTransaction: amlTransaction,
  profileReducer: profileReducer,
});

const rootReducer = (state, action) => {
  if (action.type === LOGGED_OUT) {
    state = undefined;
    storage.removeItem("persist:auth");
    storage.removeItem("client");
    storage.removeItem("success");
    storage.removeItem("profileReducer");
  }
  return reducers(state, action);
};
export default rootReducer;
