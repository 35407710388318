import React from "react";
import { Row, Col, Typography, Card } from "antd";
import {
  TeamOutlined,
  RollbackOutlined,
  SwapOutlined,
  UserAddOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
  PercentageOutlined,
  CreditCardOutlined
} from "@ant-design/icons";
import { CountUp } from "use-count-up";
import "./dashboard.less";

const { Title } = Typography;

const items = [
  {
    icon: <TeamOutlined style={{ fontSize: 54, color: "#64ea91" }} />,
    name: "Active Users",
    total: 1500,
    key: "1",
    step: 3,
  },
  {
    icon: <UserAddOutlined style={{ fontSize: 54, color: "#8fc9fb" }} />,
    name: "New Users",
    total: 1500,
    key: "2",
    step: 3,
  },
  {
    icon: <SwapOutlined style={{ fontSize: 54, color: "#d897eb" }} />,
    name: "Trades",
    total: 1500,
    key: "3",
    step: 3,
  },
  {
    icon: <ArrowDownOutlined style={{ fontSize: 54, color: "#f69899" }} />,
    name: "Deposits",
    total: 1500,
    key: "4",
    step: 3,
  },
  {
    icon: <ArrowUpOutlined  style={{ fontSize: 54, color: "#f69899" }} />,
    name: "Payments",
    total: 1500,
    key: "5",
    step: 3,
  },
  {
    icon: <PercentageOutlined style={{ fontSize: 54, color: "#d897eb" }} />,
    name: "Fees Charged",
    total: 1500,
    key: "6",
    step: 3,
  },
  {
    icon: <CreditCardOutlined  style={{ fontSize: 54, color: "#8fc9fb" }} />,
    name: "Card Transactions",
    total: 1500,
    key: "7",
    step: 1,
  },
  {
    icon: <RollbackOutlined  style={{ fontSize: 54, color: "#64ea91" }} />,
    name: "Card Reversals",
    total: 1500,
    key: "8",
    step: 1,
  },
];

const FirstRowCards = ({ activeTab = "1" }) => {
  return (
    <>
      <Row gutter={[16, { xs: 8, sm: 16, md: 8, lg: 8, xl: 8 }]}>
        {activeTab === "1" || activeTab === "2"
          ? items.map((item) => (
              <Col
                key={item.key}
                xs={{ span: 10, offset: 1 }}
                sm={{ span: 10, offset: 1 }}
                md={{ span: 10, offset: 1 }}
                lg={{ span: 6, offset: 0 }}
                xl={{ span: 6, offset: 0 }}
              >
                <Card className="card-dashboard" bodyStyle={{ padding: 40 }}>
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      float: "left",
                      marginRight: 15,
                    }}
                  >
                    {item.icon}
                  </div>
                  <div style={{ flexDirection: "column", display: "flex" }}>
                    <Title level={5} style={{ color: "#fff", margin: 0 }}>
                      {item.name}
                    </Title>
                    <Title level={3} style={{ color: "#fff", margin: 0 }}>
                      <CountUp
                        isCounting
                        thousandsSeparator=","
                        decimal="."
                        end={item.total}
                        duration={2.5}
                      />
                    </Title>
                  </div>
                </Card>
              </Col>
            ))
          : items.map(
              (item) =>
                item.step === 3 && (
                  <Col
                    key={item.key}
                    xs={{ span: 10, offset: 1 }}
                    sm={{ span: 10, offset: 1 }}
                    md={{ span: 10, offset: 1 }}
                    lg={{ span: 8, offset: 0 }}
                    xl={{ span: 8, offset: 0 }}
                  >
                    <Card
                      className="card-dashboard"
                      bodyStyle={{ padding: 40 }}
                    >
                      <div
                        style={{
                          flexDirection: "column",
                          display: "flex",
                          float: "left",
                          marginRight: 15,
                        }}
                      >
                        {item.icon}
                      </div>
                      <div style={{ flexDirection: "column", display: "flex" }}>
                        <Title level={5} style={{ color: "#fff", margin: 0 }}>
                          {item.name}
                        </Title>
                        <Title level={3} style={{ color: "#fff", margin: 0 }}>
                          <CountUp
                            isCounting
                            thousandsSeparator=","
                            decimal="."
                            end={item.total}
                            duration={2.5}
                          />
                        </Title>
                      </div>
                    </Card>
                  </Col>
                )
            )}
      </Row>
    </>
  );
};

export default FirstRowCards;
