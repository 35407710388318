import axios from "axios";
import config from "./config";
const BASE_URL = config.URL;
const twoFactor = {
  async activateTwoFactor(idToken, accessToken, body) {
    const resp = await axios.post(BASE_URL + config.activateTwoFactor, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async confirmTwoFactor(idToken, accessToken, body) {
    const resp = await axios.post(BASE_URL + config.confirmTwoFactor, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async loginTwoFactor(idToken, body) {
    const resp = await axios.post(BASE_URL + config.authTwoFactor, body, {
      headers: {
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
};
export default twoFactor;
