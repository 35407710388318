export const TOKEN = "TOKEN";
export const CLIENT_ID = "CLIENT_ID";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

export function currentToken(token) {
  return (dispatch, getState) => {
      dispatch({
        type: TOKEN,
        payload: token,
      });
  };
}

export function currentClientId(id) {
  return (dispatch, getState) => {
      dispatch({
        type: CLIENT_ID,
        payload: id,
      });
  };
}

export function refreshToken(response) {
  return async (dispatch, getState) => {
    dispatch({
      type: REFRESH_TOKEN,
      payload: response,
    });
  };
}