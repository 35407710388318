import React, { useState } from "react";
import "./team.less";
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  Table,
  Space,
  Tag,
  Modal,
  Select,
  Form,
  Input,
  Popconfirm,
  message,
} from "antd";
import { RightOutlined } from "@ant-design/icons";
import KeyboardBackspace from "@2fd/ant-design-icons/lib/KeyboardBackspace";
const { Text } = Typography;
const { Option } = Select;
const Team = (props) => {
  const [modalCreate, setModalCreate] = useState(false);
  const [modalManageUser, setModalManageUser] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [status, setStatus] = useState("");
  const [platform, setPlatform] = useState("");
  const usersList = [
    {
      name: "Ricardo Abunassar",
      email: "ricardo@damex.io",
      role: "Admin",
      platform: ["DAMEX", "DAMEX_BUSINESS"],
      status: "Active",
    },
    {
      name: "Julio Paredes",
      email: "julio@damex.io",
      role: "Finance",
      platform: ["DAMEX_BUSINESS"],
      status: "Active",
    },
    {
      name: "Yosbely Ramos",
      email: "yosbely@damex.io",
      role: "Support",
      platform: ["DAMEX"],
      status: "Active",
    },
  ];
  const roleList = [
    { key: "ADMIN", value: "Admin" },
    { key: "FINANCE", value: "Finance" },
    { key: "SUPPORT", value: "Support" },
    { key: "OPERATIONS", value: "Operations" },
  ];
  const statusList = [
    { key: "ACTIVE", value: "Active" },
    { key: "INACTIVE", value: "Inactive" },
  ];
  const platformList = [
    { key: "DAMEX", value: "Damex" },
    { key: "DAMEX_BUSINESS", value: "Damex Direct" },
  ];
  function editUser() {
    setModalCreate(false);
    message.success("User was successfully updated");
  }
  function createNewUser() {
    setModalCreate(false);
    message.success("User created successfully");
  }

  function removeUser() {
    setModalManageUser(false);
    message.success("User deleted successfully");
  }
  function closeManageUser() {
    setModalManageUser(false);
  }
  function selectedUser(record) {
    setFirstName(record.name.split(" ")[0]);
    setLastName(record.name.split(" ")[1]);
    setEmail(record.email);
    setRole(record.role);
    setStatus(record.status);
    let findElement = "";
    let platformsSelected = [];
    for (let app of record.platform) {
      findElement = platformList.find((element) => element.key === app);
      platformsSelected.push(findElement.value);
    }
    setPlatform(platformsSelected);
    setModalManageUser(true);
  }
  function formatPlatform(apps) {
    let platforms = "";
    for (let value of apps) {
      let findElement = platformList.find((element) => element.key === value);
      if (platforms !== "") {
        platforms = findElement.value + ", " + platforms;
      } else {
        platforms = findElement.value;
      }
    }
    return platforms;
  }

  const columnsUsers = [
    {
      title: (
        <Text
          style={{
            color: "#ccc",
            fontSize: 18,
          }}
        >
          Name
        </Text>
      ),
      dataIndex: "name",
      key: "name",
      width: 20,
      render: (name) => (
        <>
          <Text
            style={{
              color: "#fff",
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            {name}
          </Text>
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#ccc",
            fontSize: 18,
          }}
        >
          Email
        </Text>
      ),
      dataIndex: "email",
      key: "email",
      width: 30,
      render: (email) => (
        <>
          <Text
            style={{
              color: "#fff",
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            {email}
          </Text>
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#ccc",
            fontSize: 18,
          }}
        >
          Role
        </Text>
      ),
      dataIndex: "role",
      key: "role",
      width: 50,
      render: (role) => (
        <>
          <Text
            style={{
              color: "#fff",
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            {role}
          </Text>
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#ccc",
            fontSize: 18,
          }}
        >
          Platforms
        </Text>
      ),
      dataIndex: "platform",
      key: "platform",
      width: 50,
      render: (platform) => (
        <>
          <Text
            style={{
              color: "#fff",
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            {formatPlatform(platform)}
          </Text>
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#ccc",
            fontSize: 18,
          }}
        >
          User status
        </Text>
      ),
      dataIndex: "status",
      key: "status",
      width: 30,
      render: (status) => (
        <>
          <Tag color={status === "Active" ? "#87d068" : "#cd201f"}>
            <Text
              style={{
                color: "#fff",
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              {status}
            </Text>
          </Tag>
        </>
      ),
    },
  ];

  return (
    <>
      <Row
        gutter={[16, { xs: 8, sm: 16, md: 8, lg: 8, xl: 24 }]}
        style={{ marginBottom: 10 }}
      >
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
        >
          <Card
            bordered={false}
            size="default"
            style={{
              width: "100%",
              borderRadius: "5px",
              background: "#00001e",
              color: "#ccc",
              // border: "1px solid #000",
            }}
            bodyStyle={{ padding: "10px 0px 10px 0px" }}
          >
            <Button
              type="primary"
              size="large"
              style={{ margin: "10px 20px", float: "right" }}
              onClick={() => setModalCreate(true)}
            >
              Create new user
            </Button>

            {/* <div
                    id="table-users"
                    className="ant-table ant-table-tbody ant-table-thead text-table-cell ant-table-cell"
                    style={{ marginTop: 10 }}
                  > */}
            <Table
              locale={{
                emptyText: (
                  <Space direction="vertical">
                    <br />
                    <Text
                      style={{
                        color: "#fff",
                        fontSize: 16,
                        fontWeight: "bold",
                      }}
                    >
                      No users created yet
                    </Text>

                    <br />
                  </Space>
                ),
              }}
              //loading={state.loading}
              columns={columnsUsers}
              dataSource={usersList}
              rowClassName={"row-table-team"}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    selectedUser(record);
                  }, // click row
                };
              }}
              //scroll={{ x: 1300 }}
            />
            {/* </div> */}
          </Card>
        </Col>
      </Row>
      <Modal
        open={modalCreate}
        bodyStyle={{ background: "#00001E" }}
        maskStyle={{ backgroundColor: "rgba(0,0, 0, 0.90)" }}
        onOk={() => setModalCreate(true)}
        onCancel={() => setModalCreate(false)}
        footer={null}
        title={
          <Text
            style={{
              color: "#fff",
              fontSize: 20,
              fontWeight: 400,
              textAlign: "left",
              alignItems: "center",
              display: "inline-flex",
            }}
          >
            <KeyboardBackspace
              style={{
                color: "#fff",
                fontSize: 30,
                marginRight: 20,
              }}
              onClick={() => setModalCreate(false)}
            />{" "}
            Create new user
          </Text>
        }
        //closeIcon={<CloseOutlined style={{ color: "#fff" }} />}
      >
        <Space direction="vertical" style={{ width: "100%" }} size="middle">
          <Row justify="center">
            <Form
              labelCol={{
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: 24 },
                lg: { span: 24 },
                xl: { span: 24 },
                xxl: { span: 24 },
              }}
              wrapperCol={{
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: 24 },
                lg: { span: 24 },
                xl: { span: 24 },
                xxl: { span: 24 },
              }}
              layout="vertical"
              style={{ width: "100%" }}
            >
              <Form.Item
                label={<Text className="label-dark">* First name</Text>}
                name="firstName"
              >
                <Input
                  size="large"
                  placeholder={"First name"}
                  onChange={(e) => setFirstName(e.target.value)}
                  style={{
                    color: "#fff",
                    background: "#00001E",
                  }}
                />
              </Form.Item>
              <Form.Item
                label={<Text className="label-dark">* Last name</Text>}
                name="lastName"
              >
                <Input
                  size="large"
                  placeholder={"Last name"}
                  onChange={(e) => setLastName(e.target.value)}
                  style={{
                    color: "#fff",
                    background: "#00001E",
                  }}
                />
              </Form.Item>
              <Form.Item
                label={<Text className="label-dark">* Email address</Text>}
                name="email"
              >
                <Input
                  size="large"
                  placeholder={"Email address"}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    color: "#fff",
                    background: "#00001E",
                  }}
                />
              </Form.Item>
              <Form.Item
                label={<Text className="label-dark">* Role</Text>}
                name="role"
              >
                <div
                  id="select-dark-user"
                  className="ant-select-selector ant-select"
                >
                  <Select
                    suffixIcon={
                      <RightOutlined
                        style={{
                          color: "#fff",
                        }}
                      />
                    }
                    style={{
                      color: "#ccc",
                    }}
                    size="large"
                    placeholder="Select role"
                    onChange={(value, option) => {
                      setRole(option.key);
                    }}
                  >
                    {roleList.map((item, i) => {
                      return (
                        <Option key={item.key} value={item.value}>
                          {item.value}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </Form.Item>
              <Form.Item
                label={<Text className="label-dark">* Platforms</Text>}
                name="role"
              >
                <div
                  id="select-dark-user"
                  className="ant-select-selector ant-select"
                >
                  <Select
                    suffixIcon={
                      <RightOutlined
                        style={{
                          color: "#fff",
                        }}
                      />
                    }
                    style={{
                      color: "#ccc",
                    }}
                    size="large"
                    mode="multiple"
                    placeholder="Select platform"
                    onChange={(value, option) => {
                      setPlatform(option.key);
                    }}
                  >
                    {platformList.map((item, i) => {
                      return (
                        <Option key={item.key} value={item.value}>
                          {item.value}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </Form.Item>
              <Form.Item style={{ textAlign: "center" }}>
                <Button
                  block
                  type="primary"
                  style={{
                    height: "60px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    width: "50%",
                  }}
                  disabled={
                    firstName === "" ||
                    lastName === "" ||
                    email === "" ||
                    role === ""
                  }
                  onClick={() => createNewUser}
                >
                  Create this user
                </Button>
              </Form.Item>
            </Form>
          </Row>
        </Space>
      </Modal>
      <Modal
        open={modalManageUser}
        bodyStyle={{ background: "#00001E" }}
        maskStyle={{ backgroundColor: "rgba(0,0, 0, 0.90)" }}
        onOk={() => setModalManageUser(true)}
        onCancel={() => closeManageUser()}
        footer={null}
        title={
          <Text
            style={{
              color: "#fff",
              fontSize: 20,
              fontWeight: 400,
              textAlign: "left",
              alignItems: "center",
              display: "inline-flex",
            }}
          >
            <KeyboardBackspace
              style={{
                color: "#fff",
                fontSize: 30,
                marginRight: 20,
              }}
              onClick={() => closeManageUser()}
            />{" "}
            Edit/Remove user
          </Text>
        }
      >
        <Space direction="vertical" style={{ width: "100%" }} size="middle">
          <Row justify="center">
            <Form
              labelCol={{
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: 24 },
                lg: { span: 24 },
                xl: { span: 24 },
                xxl: { span: 24 },
              }}
              wrapperCol={{
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: 24 },
                lg: { span: 24 },
                xl: { span: 24 },
                xxl: { span: 24 },
              }}
              layout="vertical"
              style={{ width: "100%" }}
            >
              <Form.Item
                label={<Text className="label-dark">* First name</Text>}
              >
                <Input
                  size="large"
                  placeholder={"First name"}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  style={{
                    color: "#fff",
                    background: "#00001E",
                  }}
                />
              </Form.Item>
              <Form.Item
                label={<Text className="label-dark">* Last name</Text>}
              >
                <Input
                  size="large"
                  placeholder={"Last name"}
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                  style={{
                    color: "#fff",
                    background: "#00001E",
                  }}
                />
              </Form.Item>
              <Form.Item
                label={<Text className="label-dark">* Email address</Text>}
              >
                <Input
                  size="large"
                  placeholder={"Email address"}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  style={{
                    color: "#fff",
                    background: "#00001E",
                  }}
                />
              </Form.Item>
              <Form.Item label={<Text className="label-dark">* Role</Text>}>
                <div
                  id="select-dark-user"
                  className="ant-select-selector ant-select"
                >
                  <Select
                    suffixIcon={
                      <RightOutlined
                        style={{
                          color: "#fff",
                        }}
                      />
                    }
                    style={{
                      color: "#ccc",
                    }}
                    size="large"
                    placeholder="Select role"
                    disabled={role === "Admin"}
                    value={role}
                    onChange={(value, option) => {
                      setRole(option.value);
                    }}
                  >
                    {roleList.map((item, i) => {
                      return (
                        <Option key={item.key} value={item.value}>
                          {item.value}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </Form.Item>
              <Form.Item label={<Text className="label-dark">* Status</Text>}>
                <div
                  id="select-dark-user"
                  className="ant-select-selector ant-select"
                >
                  <Select
                    suffixIcon={
                      <RightOutlined
                        style={{
                          color: "#fff",
                        }}
                      />
                    }
                    style={{
                      color: "#ccc",
                    }}
                    size="large"
                    value={status}
                    disabled={role === "Admin"}
                    onChange={(value) => setStatus(value)}
                  >
                    {statusList.map((item, i) => {
                      return (
                        <Option key={item.key} value={item.value}>
                          {item.value}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </Form.Item>
              <Form.Item
                label={<Text className="label-dark">* Platforms</Text>}
                name="platform"
              >
                <div
                  id="select-dark-user"
                  className="ant-select-selector ant-select"
                >
                  <Select
                    suffixIcon={
                      <RightOutlined
                        style={{
                          color: "#fff",
                        }}
                      />
                    }
                    style={{
                      color: "#ccc",
                    }}
                    size="large"
                    mode="multiple"
                    placeholder="Select platform"
                    value={platform}
                    onChange={(value, option) => {
                      setPlatform(value);
                    }}
                  >
                    {platformList.map((item, i) => {
                      return (
                        <Option key={item.key} value={item.value}>
                          {item.value}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </Form.Item>
              <Form.Item style={{ textAlign: "center" }}>
                <Button
                  block
                  type="primary"
                  style={{
                    height: "60px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    width: "50%",
                  }}
                  disabled={
                    firstName === "" ||
                    lastName === "" ||
                    email === "" ||
                    role === ""
                  }
                  onClick={() => editUser}
                >
                  Save changes
                </Button>
              </Form.Item>
              {role !== "Admin" && (
                <Form.Item style={{ textAlign: "center" }}>
                  <Popconfirm
                    title="Are you sure you want to remove this user?"
                    onConfirm={() => removeUser()}
                    onCancel={() => setModalManageUser(false)}
                    okText="Yes, remove"
                    cancelText="No, cancel"
                    icon={null}
                    cancelButtonProps={{ className: "cancel-button" }}
                    okButtonProps={{ className: "ok-button" }}
                  >
                    <Text
                      style={{
                        height: "60px",
                        fontSize: "16px",
                        fontWeight: "bold",
                        width: "50%",
                        color: "#cf202f",
                        cursor: "pointer",
                      }}
                    >
                      Remove this user
                    </Text>
                  </Popconfirm>
                </Form.Item>
              )}
            </Form>
          </Row>
        </Space>
      </Modal>
    </>
  );
};

export default Team;
