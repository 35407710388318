import {
  GET_AML_TRANSACTION,
  SET_LOAD_AML,
  SET_CURRENT_LAST_ID,
} from "../actions/amlActions";
const initialState = {
  transactionAmlResponse: {},
  loadAml: false,
  currentLastId: "",
};

export function amlTransaction(state = initialState, action) {
  switch (action.type) {
    case GET_AML_TRANSACTION:
      return {
        ...state,
        transactionAmlResponse: action.payload,
      };
    case SET_LOAD_AML:
      return {
        ...state,
        loadAml: action.payload,
      };
    case SET_CURRENT_LAST_ID:
      return {
        ...state,
        currentLastId: action.payload,
      };
    default:
      return state;
  }
}

export default amlTransaction;
