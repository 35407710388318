// import servicesBalance from "../../services/balance";
export const BALANCE = "BALANCE";


export function getBalance(response) {
    return async (dispatch, getState) => {
      // try {
      //   const response = await servicesBalance.balance(id, accessToken);
        dispatch({
          type: BALANCE,
          payload: response,
        });
      // } catch (error) {
      //   if (error.response !== undefined) {
      //     dispatch({
      //       type: BALANCE,
      //       payload: error.response,
      //     });
      //   }
      // }
    };
  }
