// Dependencies
import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import NumberFormat from "react-number-format";

// Ant Design Components
import {
  Table,
  Card,
  Typography,
  Button,
  Space,
  Popconfirm,
  Skeleton,
} from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

// Components
import FilterSection from "./FilterSection";
import StatusTag from "../../common/StatusTag";
import EmptyTableMessage from "../../common/EmptyTableMessage";

// Constants
import { ENTITIES } from "../../../constants/entities";
import { PENDING_SETTLEMENT_TYPES as TRANSACTION_TYPES } from "../../../constants/transactionTypes";
import { COLUMNS, EXPANDED_FIELDS } from "../constants/tableConstants";

// Hooks
import { useTableData } from "../hooks/useTableData";
import { useResizeObserverErrorHandler } from "../../../hooks/useResizeObserverErrorHandler";
import { useFiltersState } from "../hooks/useFiltersState";
import {
  useExpandableConfig,
  useExpandableRowConfig,
} from "../../common/ExpandableConfig";
import { useApproveTransaction } from "../hooks/useApproveTransaction";

const { Text } = Typography;

const PendingSettlementTable = ({ refreshBalances }) => {
  // -----------------------------------------------------------------------------
  // @ Redux Selectors
  // -----------------------------------------------------------------------------

  // Redux state selectors for authentication and currency data
  const { token, clientId, supportedCurrencies } = useSelector((state) => ({
    token: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
    supportedCurrencies: state.otc.currencies,
  }));

  // -----------------------------------------------------------------------------
  // @ State
  // -----------------------------------------------------------------------------

  // State for tracking expanded row keys
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  // -----------------------------------------------------------------------------
  // @ Hooks
  // -----------------------------------------------------------------------------

  // Handles resize observer errors in the table
  useResizeObserverErrorHandler();

  // Custom hook for managing filter states and operations
  const {
    filter,
    isFiltering,
    setIsFiltering,
    startDate,
    endDate,
    inputValues,
    selectValues,
    handleFilterChange,
    handleInputChange,
    handleDateChange,
    handleReset,
  } = useFiltersState();

  // Custom hook for managing table data, pagination and loading states
  const {
    data,
    loading,
    pagination,
    handleSearch,
    handleTableChange,
    refreshData,
  } = useTableData();

  // Custom hook for handling transaction approvals
  const { handleApprove, isApproving } = useApproveTransaction(
    clientId,
    token,
    refreshData,
    refreshBalances
  );

  // Custom hooks for expandable table functionality
  const expandableConfig = useExpandableConfig({
    expandedRowKeys,
    setExpandedRowKeys,
    fields: EXPANDED_FIELDS,
  });

  const expandableRowConfig = useExpandableRowConfig(
    expandedRowKeys,
    setExpandedRowKeys
  );

  // -----------------------------------------------------------------------------
  // @ Handlers
  // -----------------------------------------------------------------------------

  // Handler for search button click
  const onSearch = async () => {
    setIsFiltering(true);
    try {
      await handleSearch({ ...filter });
    } finally {
      setIsFiltering(false);
    }
  };

  // Handler for reset button click
  const onReset = () => {
    handleReset(handleSearch);
  };

  // -----------------------------------------------------------------------------
  // @ Table Configuration
  // -----------------------------------------------------------------------------

  // Table columns configuration with custom renderers
  const columns = [
    ...COLUMNS.map((column) => ({
      ...column,
      title: (
        <Text style={{ color: "#fff", fontSize: 16 }}>{column.title}</Text>
      ),
      render: (text, record) => {
        switch (column.key) {
          case "amount":
            return (
              <NumberFormat
                value={text}
                displayType="text"
                thousandSeparator={true}
                decimalScale={8}
                style={{ color: "#fff", fontSize: 16 }}
              />
            );
          case "transaction_date":
            return (
              <Text style={{ color: "#fff", fontSize: 16 }}>
                {moment(text).format("YYYY-MM-DD HH:mm:ss")}
              </Text>
            );
          case "type":
            const transactionType = TRANSACTION_TYPES.find(
              (type) => type.key === text
            );
            return (
              <Text style={{ color: "#fff", fontSize: 16 }}>
                {transactionType ? transactionType.value : text}
              </Text>
            );
          default:
            return <Text style={{ color: "#fff", fontSize: 16 }}>{text}</Text>;
        }
      },
    })),
    // Status column with custom status tag component
    {
      title: <Text style={{ color: "#fff", fontSize: 16 }}>Status</Text>,
      dataIndex: "status",
      width: 100,
      key: "status",
      render: (status) => <StatusTag status={status} />,
    },
    // Actions column with approval button
    {
      title: <Text style={{ color: "#fff", fontSize: 16 }}>Actions</Text>,
      key: "actions",
      width: 100,
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            title="Are you sure you want to approve this transaction?"
            onConfirm={() => handleApprove(record._id)}
            okText="Confirm"
            cancelText="Cancel"
            okButtonProps={{ loading: isApproving }}
            disabled={isApproving}
          >
            <Button
              type="link"
              icon={
                <CheckCircleOutlined
                  style={{ fontSize: 24, color: "#00ff6a" }}
                />
              }
              disabled={isApproving}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // -----------------------------------------------------------------------------
  // @ Render
  // -----------------------------------------------------------------------------
  return (
    <Card className="card-pending-settlements">
      {/* Filter Section */}
      <FilterSection
        inputValues={inputValues}
        selectValues={selectValues}
        startDate={startDate}
        endDate={endDate}
        handleInputChange={handleInputChange}
        handleFilterChange={handleFilterChange}
        handleDateChange={handleDateChange}
        onSearch={onSearch}
        handleReset={onReset}
        isFiltering={isFiltering}
        entities={ENTITIES}
        transactionTypes={TRANSACTION_TYPES}
        supportedCurrencies={supportedCurrencies}
        disabledStartDate={(current) => {
          if (!current) return false;
          return endDate ? current > moment(endDate) : false;
        }}
        disabledEndDate={(current) => {
          if (!current) return false;
          return startDate ? current < moment(startDate) : false;
        }}
      />

      {/* Table Section */}
      {loading || isFiltering ? (
        <Skeleton active />
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          rowKey="_id"
          expandable={expandableConfig}
          onRow={expandableRowConfig}
          scroll={{ x: data?.length === 0 ? 1300 : 1800 }}
          className="row-table-pending"
          onChange={handleTableChange}
          pagination={pagination}
          locale={{
            emptyText: <EmptyTableMessage />,
          }}
        />
      )}
    </Card>
  );
};

export default PendingSettlementTable;
