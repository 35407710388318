import React, { useState } from "react";
import { Button, Drawer, Form, Input, Select, Space, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import servicesUsers from "../../services/user";

const { Option } = Select;

const UsersAddBeneficiaryDrawer = ({ idToken, accessToken, userData, onSuccess }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const showDrawer = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const handleCurrencyChange = (value) => {
    form.resetFields(["account_data"]);
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const payload = {
        user_id: userData._id,
        currency: values.currency,
        account_data: values.account_data,
      };

      await servicesUsers.addExternalAccount(payload, idToken, accessToken);

      notification.success({
        message: "Success",
        description: "Beneficiary has been added successfully",
      });

      onClose();
      
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />} size="small">
        Add beneficiary
      </Button>

      <Drawer
        title="Add Beneficiary"
        width={520}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={() => form.submit()} type="primary" loading={loading}>
              Save
            </Button>
          </Space>
        }
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="currency"
            label="Currency"
            rules={[{ required: true, message: "Please select a currency" }]}
          >
            <Select placeholder="Select currency" onChange={handleCurrencyChange}>
              <Option value="EUR">EUR</Option>
              <Option value="GBP">GBP</Option>
            </Select>
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues?.currency !== currentValues?.currency
            }
          >
            {({ getFieldValue }) => {
              const currency = getFieldValue("currency");

              if (currency === "EUR") {
                return (
                  <>
                    <Form.Item
                      name={["account_data", "iban"]}
                      label="IBAN"
                      rules={[{ required: true, message: "Please enter IBAN" }]}
                    >
                      <Input placeholder="Enter IBAN" />
                    </Form.Item>
                    <Form.Item
                      name={["account_data", "bic"]}
                      label="BIC"
                      rules={[{ required: true, message: "Please enter BIC" }]}
                    >
                      <Input placeholder="Enter BIC" />
                    </Form.Item>
                  </>
                );
              }

              if (currency === "GBP") {
                return (
                  <>
                    <Form.Item
                      name={["account_data", "account_number"]}
                      label="Account Number"
                      rules={[{ required: true, message: "Please enter account number" }]}
                    >
                      <Input placeholder="Enter account number" />
                    </Form.Item>
                    <Form.Item
                      name={["account_data", "sort_code"]}
                      label="Sort Code"
                      rules={[{ required: true, message: "Please enter sort code" }]}
                    >
                      <Input placeholder="Enter sort code" />
                    </Form.Item>
                  </>
                );
              }

              return null;
            }}
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default UsersAddBeneficiaryDrawer;
