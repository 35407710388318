import { TRANSACTION, DATA } from "../actions/transactionActions";
const initialState = {
  transactionResponse: {},
  dataTx: {},
};

export function transaction(state = initialState, action) {
  switch (action.type) {
    case TRANSACTION:
      return {
        ...state,
        transactionResponse: action.payload,
      };
    case DATA:
      return {
        ...state,
        dataTx: action.payload,
      };
    default:
      return state;
  }
}

export default transaction;
