import React, { useState, useEffect, useMemo } from "react";
import { Table, Space } from "antd";
import debounce from "lodash/debounce";
import {
  Card,
  Typography,
  Select,
  Row,
  Col,
  Button,
  Input,
  Skeleton,
  message,
  Divider,
} from "antd";
import compactFormat from "cldr-compact-number";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import "./users.less";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  getAllUsers,
  clearUsers,
  getCommissionersIntroducers,
  clearCommissionersIntroducers,
} from "../../redux/actions/userActions";
import { currentToken } from "../../redux/actions/tokenActions";
import servicesUsers from "../../services/user";
import { useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";
import { asset } from "../../common/assets";
import StatusUserTag from "../common/StatusUserTag";
import EditUserDrawer from "./EditUserDrawer";
import UsersAddBeneficiaryDrawer from "./UsersAddBeneficiaryDrawer";

const { Text } = Typography;
const { Option } = Select;
let lastIdUserToSelect = null;
const UsersTable = (props) => {
  const { token, usersList, commissionersList } = useSelector((state) => ({
    token: state.tokenAccess,
    usersList: state.users.usersResp,
    commissionersList: state.users.commissionersOrIntroducers,
  }));
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [rowId, setRowId] = useState("");
  const [filter, setFilter] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [isFiltering, setIsFiltering] = useState(false);
  const [lastIdUser, setlastIdUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const [commissionersArray, setCommissionersArray] = useState([]);
  const [introducersArray, setIntroducersArray] = useState([]);
  const [loadingCom, setLoadingCom] = useState(false);
  const [currentIntroducer, setCurrentIntroducer] = useState(null);
  const [currentCommisioner, setCurrentCommissioner] = useState(null);
  const [dataSourceSelectUser, setDataToSelectUser] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [originalDataSource, setOriginalDataSource] = useState([]);
  let lastId = null;
  let isMoreData = true;
  let debounceTimeout = 400;
  const status = [
    { key: "WAITING_FOR_KYC", value: "Waiting for KYC" },
    { key: "PENDING_REVIEW", value: "Pending review" },
    { key: "COUNTRY_NOT_SUPPORTED", value: "Country not supported" },
    { key: "REJECTED", value: "Rejected" },
    { key: "RETRY_KYC", value: "Retry KYC" },
    { key: "APPROVED", value: "Approved" },
    { key: "ACTIVE", value: "Active" },
    { key: "KYC_RENEWAL", value: "KYC Renewal" },
    { key: "INACTIVE", value: "Inactive" },
    { key: "ALL", value: "All" },
  ];
  const entityList = [
    { key: "GIB", value: "GIB" },
    { key: "UAB", value: "UAB" },
    { key: "ALL", value: "All" },
  ];
  const transactionType = [
    { key: "DEPOSIT", value: "Deposit" },
    { key: "TRANSFER_IN", value: "Transfer in" },
    { key: "EXCHANGE_IN", value: "Exchange in" },
    { key: "WITHDRAWAL", value: "Withdrawal" },
    { key: "TRANSFER_OUT", value: "Transfer out" },
    { key: "EXCHANGE_OUT", value: "Exchange out" },
  ];
  let typeRetail = [
    { key: "STANDARD", value: "Standard" },
    { key: "PREMIUM", value: "Premium" },
    { key: "VIP", value: "VIP" },
    { key: "ALL", value: "All" },
  ];
  let typeDirect = [
    { key: "FIAT_ONLY", value: "Fiat only" },
    { key: "REGULAR", value: "Regular" },
    { key: "LITE", value: "Lite" },
    { key: "ALL", value: "All" },
  ];
  let typeList = [...typeRetail, ...typeDirect];

  const levels = [
    { key: "1", value: "1" },
    { key: "2", value: "2" },
    { key: "3", value: "3" },
    { key: "4", value: "4" },
    { key: "ALL", value: "All" },
  ];
  useEffect(() => {
    setFetching(true);
    fetchUserToSelect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setDataSource([]);
    // setDataToSelectUser([]);
    setRowId("");
    getUsersList();

    if (props.source === "Damex Direct") {
      getCommissionersIntroducersInfo();
    }
    const entries = performance.getEntriesByType("navigation");
    entries.forEach((entry) => {
      if (entry.type === "reload") {
        setlastIdUser(null);
        setFilter({});
        dispatch(clearUsers());
      }
    });
    return () => {
      setLoading(false);
      dispatch(clearUsers());
      dispatch(clearCommissionersIntroducers());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let keys = Object.keys(usersList);
    if (keys.length > 0 && rowId === null) {
      formatDataUser();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersList]);
  useEffect(() => {
    if (commissionersList !== undefined) {
      let keys = Object.keys(commissionersList);
      if (keys.length > 0) {
        formatDataCommissioners();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commissionersList]);

  async function formatDataCommissioners() {
    const response = commissionersList;
    if (response.status === 200) {
      let data = response.data.data.commissioners_or_introducers;
      if (data.length > 0) {
        if (response.status === 200) {
          let commissioners = data.filter(
            (element) => element.type === "COMMISSIONER"
          );
          let introducers = data.filter(
            (element) => element.type === "INTRODUCER"
          );

          let orderedCommissioners = commissioners.sort(function (a, b) {
            let fullNameA = "";
            let fullNameB = "";

            fullNameA = a.name;

            fullNameB = b.name;

            return fullNameA.localeCompare(fullNameB);
          });
          let orderedIntroducers = introducers.sort(function (a, b) {
            let fullNameA = "";
            let fullNameB = "";

            fullNameA = a.name;

            fullNameB = b.name;

            return fullNameA.localeCompare(fullNameB);
          });
          setCommissionersArray(orderedCommissioners);
          setIntroducersArray(orderedIntroducers);
          setLoadingCom(false);
        }
      }
    }
  }
  async function getCommissionersIntroducersInfo() {
    try {
      setLoadingCom(true);

      const response = await servicesUsers.commissionersOrIntroducersList(
        token.clientId,
        token.current
      );
      if (response.headers["x-new-access-token"] !== undefined) {
        props.currentToken(response.headers["x-new-access-token"]);
      }
      await props.getCommissionersIntroducers(response);
    } catch (error) {
      setLoadingCom(false);
      if (error.response.status === 400) {
        if (error.response.data.error_details.payload_error !== undefined) {
          let messageError = error.response.data.error_details.payload_error;
          validationError(messageError, error.response.data.message);
        } else {
          message.error(error.response.data.message);
        }
        message.error("Invalid token");
      } else if (error.response.status === 422) {
        message.error("Invalid token");
      } else if (error.response.status === 401) {
        message.error("Expired token");
      } else if (error.response.status === 403 && error.response.status === 500)
        message.error(error.response.data.message);
    }
  }
  function validationError(messageError, otherMessage) {
    if (messageError === "INVALID_EMAIL_FORMAT") {
      message.error("'Email' is invalid", 10);
    } else if (messageError === "INVALID_LAST_USER") {
      message.error("Last_id is invalid", 10);
    } else {
      message.error(otherMessage, 10);
    }
  }
  const fetchUserToSelect = async () => {
    setOriginalDataSource([]);
    setDataToSelectUser([]);
    lastIdUserToSelect = null;
    isMoreData = true;
    while (isMoreData) {
      let filterAux = {};
      if (props?.source === "Damex") {
        filterAux = {
          platform: "RETAIL",
          last_id: lastIdUserToSelect,
        };
      } else {
        filterAux = {
          platform: "BUSINESS",
          last_id: lastIdUserToSelect,
        };
      }
      try {
        const response = await servicesUsers.listAllUsers(
          token.clientId,
          token.current,
          filterAux
        );
        if (response.headers["x-new-access-token"] !== undefined) {
          props.currentToken(response.headers["x-new-access-token"]);
        }

        let usersResp = response.data.data;
        lastIdUserToSelect = usersResp.current_last_id;

        if (usersResp.current_page_size < usersResp.max_elements_per_page) {
          isMoreData = false;
        } else {
          isMoreData = true;
        }
        let aux = dataSourceSelectUser;
        if (usersResp?.users?.length > 0) {
          let data = addID(usersResp.users);
          data = data.map((user) => ({
            label: `${user.customer_name}`,
            value: user._id,
          }));
          for (let user of data) {
            if (
              !dataSourceSelectUser.find((item) => {
                return user.value === item.value;
              })
            ) {
              aux.push(user);
            }
          }
          setDataToSelectUser(aux);
          setOriginalDataSource(aux);
        }
      } catch (error) {
        isMoreData = false;
        props.currentToken(error.response.headers["x-new-access-token"]);
        setDataSource([]);
        if (error.response.status === 401 || error.response.status === 422) {
          message.error("An error has ocurred. Please sign in again");
          window.localStorage.setItem("success", false);
          history.push("/signin");
        }
      }
    }
    setFetching(false);
  };
  // eslint-disable-next-line
  const searchUserToSelect = React.useCallback((inputValue) => {
    let value = inputValue.toLowerCase();
    let resultFilter = [];
    if (inputValue !== "") {
      // eslint-disable-next-line
      resultFilter = originalDataSource.filter((item) => {
        const { label } = item;
        if (String(label).toLowerCase().includes(value)) {
          return item;
        }
      });
      return resultFilter;
    } else {
      return originalDataSource;
    }
  });

  async function getUsersList(isFilter) {
    setLoading(true);
    setRowId(null);
    if (isFilter) {
      setIsFiltering(true);
      lastId = null;
      setDataSource([]);
      setPagination({ current: 1, pageSize: 20, total: 0 });
    } else {
      lastId = lastIdUser;
    }
    let filterAux = {};
    if (props?.source === "Damex") {
      filterAux = {
        ...filter,
        platform: "RETAIL",
        last_id: lastId,
      };
    } else {
      filterAux = {
        ...filter,
        platform: "BUSINESS",
        last_id: lastId,
      };
    }
    try {
      const response = await servicesUsers.listAllUsers(
        token.clientId,
        token.current,
        filterAux
      );
      if (response.headers["x-new-access-token"] !== undefined) {
        props.currentToken(response.headers["x-new-access-token"]);
      }
      await props.getAllUsers(response);
    } catch (error) {
      props.currentToken(error.response.headers["x-new-access-token"]);
      setDataSource([]);
      if (error.response.status === 401 || error.response.status === 422) {
        message.error("An error has ocurred. Please sign in again");
        window.localStorage.setItem("success", false);
        history.push("/signin");
      }
    }
  }
  function addID(aux) {
    let data = [];
    aux.map((item, i) => {
      return data.push({ ...item, key: item._id });
    });
    return data;
  }
  function formatDataUser() {
    let aux = [];
    let response = usersList;
    if (response.status === 200) {
      let usersResp = response.data.data;
      if (usersResp?.users?.length > 0) {
        setFilter({ ...filter, last_id: usersResp.current_last_id });
        setlastIdUser(usersResp.current_last_id);
        let data = addID(usersResp.users);
        aux = [...dataSource, ...data];

        let newPagination = {
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: aux.length + 1,
        };
        setPagination(newPagination);
        setDataSource(aux);
      } else {
        let newPagination = {
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.length,
        };
        setPagination(newPagination);
      }
      setLoading(false);
      setIsFiltering(false);
      return aux;
    }
  }
  function findType(type) {
    let name = "";
    if (type !== undefined) {
      let data = typeList.find((element) => element.key === type);
      name = data.value;
    }
    return name;
  }
  // function findStatus(val) {
  //   let name = "";
  //   if (val !== undefined) {
  //     let data = status.find((element) => element.key === val);
  //     name = data.value;
  //   }
  //   return name;
  // }

  const handleInput = (event) => {
    let val = event.target.value.toLowerCase();
    if (event.target.name === "country") {
      val = val.toUpperCase();
    }
    setFilter({
      ...filter,
      [event.target.name]: val === "" ? null : val,
    });
  };
  async function handleTableChange(pag) {
    setPagination(pag);
    let currentSize = pagination.current * pagination.pageSize + 1;
    if (pagination.current < pag.current && currentSize === pag.total) {
      await getUsersList(false);
    }
  }
  async function getUserDetail(row, record) {
    setLoadingUser(true);
    setUserDetail({});
    let infoUser = record;
    // delete infoUser.created_date;
    // delete infoUser.customer_name;
    // delete infoUser.email;
    // delete infoUser.country;
    // delete infoUser.platform;
    // record.username !== undefined && delete infoUser.username;
    // record.kyc_level !== undefined && delete infoUser.kyc_level;
    // record.customer_success_manager !== undefined &&
    //   delete infoUser.customer_success_manager;
    let arrayFees = [];
    if (record.fees !== undefined) {
      for (let value of record.accounts) {
        let objFee = {};
        objFee.currency = value.currency;
        let feesTypes = record.fees.filter(
          (element) => element.currency === value.currency
        );
        objFee.fees = feesTypes;
        arrayFees.push(objFee);
      }
    }
    if (record.markups !== undefined) {
      let findMarkups = record.markups.find(
        (element) => element.range !== undefined
      );
      if (findMarkups === undefined) {
        findMarkups = record.markups.find(
          (element) => element.risk_levels !== undefined
        );
      }
      if (findMarkups === undefined) {
        findMarkups = record.markups.find(
          (element) => element.spread !== undefined && element.spread !== null
        );
      }
      if (findMarkups === undefined) {
        findMarkups = record.markups.find(
          (element) => element.fx_fee !== undefined && element.fx_fee !== null
        );
      }
      infoUser.tradingFees = findMarkups;
    }
    infoUser.orderedFees = arrayFees;
    setUserDetail(infoUser);
    setLoadingUser(false);
  }
  function labelName(name) {
    let label = "";
    if (name === "challenge_achieved_current_month") {
      label = "Challenge achieved current month";
    } else if (name === "challenge_achieved_last_month") {
      label = "Challenge achieved last month";
    } else if (name === "current_month_steps") {
      label = "Steps current month";
    } else if (name === "previous_month_steps") {
      label = "Steps last month";
    } else if (name === "two_factor") {
      label = "Two factor authentication ";
    } else if (name === "phone_number") {
      label = "Phone Number";
    } else if (name === "date_of_birth") {
      label = "Birthdate";
    } else if (name === "customer_id") {
      label = "Customer ID (FIAT)";
    } else if (name === "customer_success_manager") {
      label = "Customer success manager";
    } else if (name === "address_status") {
      label = "Address status";
    } else if (name === "country") {
      label = "Country";
    } else if (name === "city") {
      label = "City";
    } else if (name === "region") {
      label = "Region";
    } else if (name === "postal_code") {
      label = "Postal code";
    } else if (name === "postal_code") {
      label = "Postal code";
    } else if (name === "physical_address") {
      label = "Address ";
    } else if (name === "trading_fee_type") {
      label = "Trading fee type";
    } else if (name === "vault_account_name") {
      label = "Vault Name";
    } else if (name === "vault_account_id") {
      label = "Vault ID";
    } else if (name === "customer_name") {
      label = "User name";
    } else if (name === "line_1") {
      label = "Line 1";
    } else if (name === "line_2") {
      label = "Line 2";
    } else if (name === "funding") {
      label = "Funding";
    } else if (name === "sign_in") {
      label = "Sign in";
    } else if (name === "entity") {
      label = "Entity";
    } else if (name === "risk_level") {
      label = "Risk level";
    } else {
      label = name;
    }
    return label;
  }
  function valueFormat(name) {
    let value = {};
    if (name.includes("percentage")) {
      value.symbol = "%";
      value.type = "number";
    } else if (name.includes("eur")) {
      value.symbol = "EUR";
      value.type = "number";
    } else if (name.includes("gbp")) {
      value.symbol = "GBP";
      value.type = "number";
    } else if (name === "fee") {
      value.symbol = "currency";
      value.type = "number";
    } else if (name === "amount") {
      value.symbol = "currency";
      value.type = "number";
    } else if (name.includes("rate") || name.includes("price")) {
      value.symbol = "currency";
      value.type = "number";
    } else {
      value.symbol = "";
      value.type = "text";
    }
    return value;
  }
  function findTypeTransaction(type) {
    let data = transactionType.find((element) => element.key === type);
    return data;
  }
  function findMethod(value) {
    let data = "";
    if (value === "EMAIL") {
      data = "Email";
    } else if (data === "GAUTH") {
      data = "Authenticator";
    }
    return data;
  }
  function handleIntroducer(value) {
    if (value) {
      setCurrentIntroducer(value.split("_")[1]);
      let val = value.split("_")[1];
      let name;
      val !== "" ? (name = val) : (name = "");
      setFilter({
        ...filter,
        introducer_name: name,
      });
    } else {
      setCurrentIntroducer(null);
      setFilter({
        ...filter,
        introducer_name: "",
      });
    }
  }
  function defineTypeCurrency(currency) {
    let findType = asset.assets.find((item) => {
      return currency === item.value;
    });
    if (findType) {
      return findType.type;
    }
    return null;
  }
  function getFxLevel(markups) {
    let findPercent = "";
    if (markups) {
      if (Array(markups).length > 0) {
        for (let markup of markups) {
          if (markup.fx_fee) {
            const { percentage } = markup.fx_fee;
            if (percentage) {
              findPercent = percentage + "%";
            }
          }
        }
        if (findPercent === "") {
          for (let markup of markups) {
            if (markup.base_currency && markup.quote_currency) {
              if (
                defineTypeCurrency(markup.base_currency) === "FIAT" &&
                defineTypeCurrency(markup.quote_currency) === "FIAT"
              ) {
                if (markup.spread) {
                  findPercent = markup.spread + "% (Spread)";
                }
              }
            }
          }
        }
      }
    }
    return findPercent;
  }
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      //   setFetching(true);
      setDataToSelectUser(searchUserToSelect(value));
      // setFetching(false);
    };
    return debounce(loadOptions, debounceTimeout);
  }, [searchUserToSelect, debounceTimeout]);
  function handleCommissioner(value) {
    if (value) {
      setCurrentCommissioner(value.split("_")[1]);
      let val = value.split("_")[1];
      let name;
      val !== "" ? (name = val) : (name = "");
      setFilter({
        ...filter,
        commissioner_name: name,
      });
    } else {
      setCurrentCommissioner(null);
      setFilter({
        ...filter,
        commissioner_name: "",
      });
    }
  }
  const handleUserUpdated = (updatedUser) => {
    const updatedDataSource = dataSource.map((user) =>
      user._id === updatedUser._id ? updatedUser : user
    );
    setDataSource(updatedDataSource);
  };
  const columns = [
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          ID
        </Text>
      ),
      dataIndex: "key",
      key: "_id",
      className: "id-column",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Date
        </Text>
      ),
      width: 140,
      dataIndex: "created_date",
      key: "created_date",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Customer name
        </Text>
      ),
      width: 180,
      dataIndex: "customer_name",
      key: "customer_name",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Username
        </Text>
      ),
      width: 150,
      className: props.source === "Damex Direct" ? "column-hidden" : "",
      dataIndex: "username",
      key: "username",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Email
        </Text>
      ),
      width: 210,
      dataIndex: "email",
      key: "email",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Country
        </Text>
      ),
      width: 90,
      dataIndex: "country",
      key: "country",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Entity
        </Text>
      ),
      width: 80,
      dataIndex: "entity",
      key: "entity",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          KYC Level
        </Text>
      ),
      width: 90,
      className: props.source === "Damex Direct" ? "column-hidden" : "",
      dataIndex: "kyc_level",
      key: "kyc_level",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Customer Sucess Manager
        </Text>
      ),
      className: props.source === "Damex" ? "column-hidden" : "",
      dataIndex: "customer_success_manager",
      key: "customer_success_manager",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {props.source === "Damex Direct" ? "Type" : "Type Damex plan"}
        </Text>
      ),
      dataIndex: "type",
      key: "type",
      render: (type) => (
        <>{findType(type) !== undefined ? findType(type) : type}</>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Status
        </Text>
      ),
      dataIndex: "status",
      key: "status",
      render: (status) => <StatusUserTag status={status} />,
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Actions
        </Text>
      ),
      key: "actions",
      render: (_, record) => (
        <Space>
          {record.type === "LITE" && (
            <UsersAddBeneficiaryDrawer idToken={token.clientId} accessToken={token.current} userData={record} onSuccess={handleUserUpdated} />
          )}
          <EditUserDrawer userData={record} onUserUpdated={handleUserUpdated} />
        </Space>
      ),
    },
  ];
  function orderData(data) {
    const order = data.sort(function (a, b) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    return order;
  }
  return (
    <Card
      className="card-users"
      bodyStyle={{ padding: "10px 0px 10px 0px" }}
      title={
        <Text
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color: "#fff",
            textAlign: "left",
          }}
        >
          {props.source + " users"}
        </Text>
      }
    >
      <Row
        gutter={[8, { xs: 8, sm: 16, md: 16, lg: 16, xl: 16 }]}
        style={{ padding: "5px 10px" }}
      >
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: props.source === "Damex Direct" ? 4 : 6, offset: 0 }}
          xl={{ span: props.source === "Damex Direct" ? 4 : 6, offset: 0 }}
          xxl={{ span: props.source === "Damex Direct" ? 4 : 6, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Input
            allowClear
            style={{ width: "100%" }}
            size="large"
            placeholder="ID"
            name="user_id"
            onChange={handleInput}
          ></Input>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: props.source === "Damex Direct" ? 4 : 6, offset: 0 }}
          xl={{ span: props.source === "Damex Direct" ? 4 : 6, offset: 0 }}
          xxl={{ span: props.source === "Damex Direct" ? 4 : 6, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Select
            showSearch
            labelInValue
            filterOption={false}
            loading={fetching}
            style={{ width: "100%" }}
            size="large"
            placeholder="Client Name"
            onSearch={debounceFetcher}
            onSelect={(val) => {
              const { label } = val;
              setFilter({
                ...filter,
                customer_name: label,
              });
            }}
            options={orderData(dataSourceSelectUser)}
          />
        </Col>

        {props.source === "Damex" && (
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 6, offset: 0 }}
            xl={{ span: 6, offset: 0 }}
            xxl={{ span: 6, offset: 0 }}
            style={{
              textAlign: "left",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Input
              allowClear
              style={{ width: "100%" }}
              size="large"
              placeholder="Username"
              name="username"
              onChange={handleInput}
            ></Input>
          </Col>
        )}
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: props.source === "Damex Direct" ? 4 : 6, offset: 0 }}
          xl={{ span: props.source === "Damex Direct" ? 4 : 6, offset: 0 }}
          xxl={{ span: props.source === "Damex Direct" ? 4 : 6, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Input
            allowClear
            style={{ width: "100%" }}
            size="large"
            placeholder="Email"
            name="user_email"
            onChange={handleInput}
          ></Input>
        </Col>
        {props.source === "Damex Direct" && (
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 4, offset: 0 }}
            xl={{ span: 4, offset: 0 }}
            xxl={{ span: 4, offset: 0 }}
            style={{
              textAlign: "left",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Input
              allowClear
              style={{ width: "100%" }}
              size="large"
              placeholder="Customer Sucess Manager"
              name="customer_success_manager"
              onChange={handleInput}
            ></Input>
          </Col>
        )}
        {props.source === "Damex Direct" && (
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 4, offset: 0 }}
            xl={{ span: 4, offset: 0 }}
            xxl={{ span: 4, offset: 0 }}
            style={{
              textAlign: "left",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Input
              allowClear
              style={{ width: "100%" }}
              size="large"
              placeholder="Country"
              name="country"
              maxLength={2}
              onChange={handleInput}
            ></Input>
          </Col>
        )}
        {props.source === "Damex Direct" && (
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 4, offset: 0 }}
            xl={{ span: 4, offset: 0 }}
            xxl={{ span: 4, offset: 0 }}
            style={{
              textAlign: "left",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Select
              allowClear
              showSearch
              style={{ width: "100%" }}
              size="large"
              placeholder="Search by entity"
              name="entity"
              onChange={(value) => {
                let entity;
                value !== "ALL" ? (entity = value) : (entity = "");
                setFilter({
                  ...filter,
                  entity: entity,
                });
              }}
            >
              {entityList.map((item, i) => {
                return (
                  <Option key={i} value={item.key}>
                    {item.value}
                  </Option>
                );
              })}
            </Select>
          </Col>
        )}
      </Row>
      <Row
        gutter={[8, { xs: 8, sm: 16, md: 16, lg: 16, xl: 16 }]}
        style={{ padding: "5px 10px" }}
      >
        {props.source === "Damex" && (
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: props.source === "Damex" ? 5 : 4, offset: 0 }}
            xl={{ span: props.source === "Damex" ? 5 : 4, offset: 0 }}
            xxl={{ span: props.source === "Damex" ? 5 : 4, offset: 0 }}
            style={{
              textAlign: "left",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Input
              allowClear
              style={{ width: "100%" }}
              size="large"
              placeholder="Country"
              name="country"
              onChange={handleInput}
            ></Input>
          </Col>
        )}
        {props.source === "Damex" && (
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: props.source === "Damex" ? 5 : 4, offset: 0 }}
            xl={{ span: props.source === "Damex" ? 5 : 4, offset: 0 }}
            xxl={{ span: props.source === "Damex" ? 5 : 4, offset: 0 }}
            style={{
              textAlign: "left",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Select
              allowClear
              showSearch
              style={{ width: "100%" }}
              size="large"
              placeholder="Search by KYC Level"
              onChange={(value) => {
                let level;
                value !== "ALL" ? (level = value) : (level = null);
                setFilter({
                  ...filter,
                  kyc_level: level,
                });
              }}
            >
              {levels.map((item, i) => {
                return (
                  <Option key={i} value={item.key}>
                    {item.value}
                  </Option>
                );
              })}
            </Select>
          </Col>
        )}
        {props.source === "Damex Direct" && (
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 5, offset: 0 }}
            xl={{ span: 5, offset: 0 }}
            xxl={{ span: 5, offset: 0 }}
            style={{
              textAlign: "left",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Select
              allowClear
              showSearch
              defaultValue={currentCommisioner}
              filterOption={(input, option) => {
                setCurrentCommissioner(input);
                if (option.value) {
                  let value = option.value.split("_")[1];
                  return value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
              }}
              style={{ width: "100%" }}
              size="large"
              placeholder="Commissioner name"
              loading={loadingCom}
              disabled={loadingCom}
              name="commissioner_name"
              onChange={(value) => handleCommissioner(value)}
              value={currentCommisioner}
            >
              {commissionersArray.map((item, i) => {
                return (
                  <Option value={item._id + "_" + item.name} key={item._id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
        )}
        {props.source === "Damex Direct" && (
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 5, offset: 0 }}
            xl={{ span: 5, offset: 0 }}
            xxl={{ span: 5, offset: 0 }}
            style={{
              textAlign: "left",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Select
              allowClear
              showSearch
              defaultValue={currentIntroducer}
              filterOption={(input, option) => {
                setCurrentIntroducer(input);
                if (option.value) {
                  let value = option.value.split("_")[1];
                  return value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
              }}
              style={{ width: "100%" }}
              size="large"
              placeholder="Introducer name"
              loading={loadingCom}
              disabled={loadingCom}
              name="introducer_name"
              onChange={(value) => handleIntroducer(value)}
              value={currentIntroducer}
            >
              {introducersArray.map((item, i) => {
                return (
                  <Option value={item._id + "_" + item.name} key={item._id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
        )}
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{
            span: 5,
            offset: 0,
          }}
          xl={{ span: 5, offset: 0 }}
          xxl={{ span: 5, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Select
            allowClear
            showSearch
            style={{ width: "100%" }}
            size="large"
            placeholder="Type"
            onChange={(value) => {
              let item;
              value !== "ALL" ? (item = value) : (item = null);
              setFilter({
                ...filter,
                type: item,
              });
            }}
          >
            {props.source === "Damex"
              ? typeRetail.map((item, i) => {
                  return (
                    <Option key={i} value={item.key}>
                      {item.value}
                    </Option>
                  );
                })
              : typeDirect.map((item, i) => {
                  return (
                    <Option key={i} value={item.key}>
                      {item.value}
                    </Option>
                  );
                })}
          </Select>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 5, offset: 0 }}
          xl={{ span: 5, offset: 0 }}
          xxl={{ span: 5, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Select
            allowClear
            showSearch
            style={{ width: "100%" }}
            size="large"
            placeholder="Status"
            onChange={(value) => {
              let stat;
              value !== "ALL" ? (stat = value) : (stat = null);
              setFilter({
                ...filter,
                status: stat,
              });
            }}
          >
            {status.map((item, i) => {
              return (
                <Option key={i} value={item.key}>
                  {item.value}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 4, offset: 0 }}
          xl={{ span: 4, offset: 0 }}
          xxl={{ span: 4, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Button
            type="primary"
            size="large"
            style={{ width: "100%" }}
            onClick={() => getUsersList(true)}
          >
            Filter
          </Button>
        </Col>
      </Row>
      <div
        id="table-history"
        className="ant-table ant-table-tbody ant-table-thead text-table-cell ant-table-cell"
        style={{ marginTop: 10 }}
      >
        {!loading && !isFiltering && (
          <Table
            rowClassName="row-table"
            dataSource={dataSource}
            scroll={{
              x:
                dataSource.length === 0
                  ? null
                  : props.source === "Damex"
                    ? 1800
                    : 1800,
            }}
            columns={columns}
            pagination={pagination}
            onChange={handleTableChange}
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: async (event) => {
            //       if (record._id === rowId) {
            //         setRowId("");
            //       } else {
            //         setRowId(record._id);
            //       }
            //       await getUserDetail(rowIndex, record);
            //     },
            //   };
            // }}
            expandable={{
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <DownOutlined onClick={async (e) => {
                    e.stopPropagation();
                    if (!userDetail._id || userDetail._id !== record._id) {
                      await getUserDetail(null, record);
                    }
                    onExpand(record, e);
                  }} />
                ) : (
                  <RightOutlined onClick={async (e) => {
                    e.stopPropagation();
                    if (!userDetail._id || userDetail._id !== record._id) {
                      await getUserDetail(null, record);
                    }
                    onExpand(record, e);
                  }} />
                ),
              expandedRowRender: (record, index) => (
                <>
                  <Row gutter={4} style={{ marginLeft: 60 }}>
                    {Object.entries(userDetail).map(([key, value], i) => {
                      return (
                        <>
                          {value != null &&
                            key !== "accounts" &&
                            key !== "physical_address" &&
                            key !== "two_factor" &&
                            key !== "commissioners_list" &&
                            key !== "fees" &&
                            key !== "orderedFees" &&
                            key !== "introducers_list" &&
                            key !== "markups" &&
                            key !== "tradingFees" &&
                            key !== "key" &&
                            key !== "_id" &&
                            key !== "type" &&
                            key !== "status" && (
                              <>
                                <Col className="gutter-row" span={3}>
                                  <Text
                                    style={{
                                      color: "#fff",
                                      fontSize: 16,
                                    }}
                                  >
                                    {labelName(key)}:
                                  </Text>
                                </Col>
                                <Col className="gutter-row" span={5}>
                                  {valueFormat(key).type === "number" && (
                                    <NumberFormat
                                      thousandSeparator={true}
                                      displayType="text"
                                      style={{
                                        fontSize: 16,
                                        fontWeight: 400,
                                        color: "#ccc",
                                      }}
                                      decimalScale={8}
                                      value={value}
                                    />
                                  )}
                                  {valueFormat(key).type === "text" && (
                                    <Text
                                      style={{
                                        color: "#ccc",
                                        fontSize: 16,
                                      }}
                                    >
                                      {value}
                                    </Text>
                                  )}
                                </Col>
                              </>
                            )}
                        </>
                      );
                    })}
                  </Row>
                  <Row gutter={4} style={{ marginLeft: 60 }}>
                    {userDetail.accounts !== undefined && (
                      <>
                        {userDetail.accounts.length > 0 && (
                          <>
                            <Col className="gutter-row" span={9}>
                              <>
                                <Divider
                                  style={{ borderTopColor: "#fff" }}
                                  orientation="left"
                                >
                                  <Text
                                    style={{
                                      color: "#fff",
                                      fontSize: 16,
                                    }}
                                  >
                                    {"Accounts"}
                                  </Text>
                                </Divider>
                                <Row gutter={4}>
                                  {userDetail.accounts.map((item, i) => {
                                    return (
                                      <>
                                        <Col className="gutter-row" span={4}>
                                          <Text
                                            style={{
                                              color: "#fff",
                                              fontSize: 16,
                                            }}
                                          >
                                            {item.currency + ":"}
                                          </Text>
                                        </Col>
                                        <Col className="gutter-row" span={8}>
                                          <NumberFormat
                                            thousandSeparator={true}
                                            displayType="text"
                                            style={{
                                              fontSize: 16,
                                              fontWeight: 400,
                                              color: "#ccc",
                                            }}
                                            suffix={" " + item.currency}
                                            value={item.available_balance}
                                          />
                                        </Col>
                                      </>
                                    );
                                  })}
                                </Row>
                              </>
                            </Col>

                            <Col className="gutter-row" span={1}>
                              <Divider
                                style={{
                                  borderLeftColor: "#fff",
                                  marginLeft: 30,
                                  height: "9em",
                                  top: "2em",
                                }}
                                type="vertical"
                              />
                            </Col>
                          </>
                        )}
                      </>
                    )}
                    {userDetail.physical_address !== undefined && (
                      <>
                        {Object.keys(userDetail.physical_address).length !==
                          0 && (
                          <>
                            <Col className="gutter-row" span={9}>
                              <Divider
                                style={{ borderTopColor: "#fff" }}
                                orientation="left"
                              >
                                <Text
                                  style={{
                                    color: "#fff",
                                    fontSize: 16,
                                  }}
                                >
                                  {"Address"}
                                </Text>
                              </Divider>
                              <Row gutter={4}>
                                {Object.entries(
                                  userDetail.physical_address
                                ).map(([key, value], i) => {
                                  return (
                                    <>
                                      {value !== null &&
                                        key !== "address_status" && (
                                          <>
                                            <Col
                                              className="gutter-row"
                                              span={6}
                                            >
                                              <Text
                                                style={{
                                                  color: "#fff",
                                                  fontSize: 16,
                                                }}
                                              >
                                                {labelName(key)}:
                                              </Text>
                                            </Col>
                                            <Col
                                              className="gutter-row"
                                              span={6}
                                            >
                                              {valueFormat(key).type ===
                                                "text" && (
                                                <Text
                                                  style={{
                                                    color: "#ccc",
                                                    fontSize: 16,
                                                  }}
                                                >
                                                  {value}
                                                </Text>
                                              )}
                                            </Col>
                                          </>
                                        )}
                                    </>
                                  );
                                })}
                              </Row>
                            </Col>
                          </>
                        )}
                      </>
                    )}
                    {userDetail.two_factor !== undefined && (
                      <>
                        <Col className="gutter-row" span={1}>
                          <Divider
                            style={{
                              borderLeftColor: "#fff",
                              marginLeft: 30,
                              height: "9em",
                              top: "2em",
                            }}
                            type="vertical"
                          />
                        </Col>
                        <Col className="gutter-row" span={4}>
                          <Divider
                            style={{ borderTopColor: "#fff" }}
                            orientation="left"
                          >
                            <Text
                              style={{
                                color: "#fff",
                                fontSize: 16,
                              }}
                            >
                              {labelName("two_factor")}
                            </Text>
                          </Divider>
                          <Row gutter={4}>
                            {Object.entries(userDetail.two_factor).map(
                              ([key, value], i) => {
                                return (
                                  <>
                                    {value != null && (
                                      <>
                                        <Col className="gutter-row" span={8}>
                                          <Text
                                            style={{
                                              color: "#fff",
                                              fontSize: 16,
                                            }}
                                          >
                                            {labelName(key)}:
                                          </Text>
                                        </Col>
                                        <Col className="gutter-row" span={16}>
                                          <Text
                                            style={{
                                              color: "#ccc",
                                              fontSize: 16,
                                            }}
                                          >
                                            {value.method !== "NONE"
                                              ? value.status +
                                                " (" +
                                                findMethod(value.method) +
                                                ")"
                                              : "Not Configured"}
                                          </Text>
                                        </Col>
                                      </>
                                    )}
                                  </>
                                );
                              }
                            )}
                          </Row>
                        </Col>{" "}
                      </>
                    )}
                  </Row>
                  <Row gutter={4} style={{ marginLeft: 60 }}>
                    {userDetail.orderedFees?.length > 0 && (
                      <>
                        <Col className="gutter-row" span={9}>
                          <Divider
                            style={{ borderTopColor: "#fff" }}
                            orientation="left"
                          >
                            <Text
                              style={{
                                color: "#fff",
                                fontSize: 16,
                              }}
                            >
                              {"Fees charged"}
                            </Text>
                          </Divider>
                          {userDetail.orderedFees.map((item, i) => {
                            return (
                              <>
                                <Row gutter={4}>
                                  <Col className="gutter-row" span={24}>
                                    <Text
                                      style={{
                                        color: "#fff",
                                        fontSize: 16,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item.currency + ":"}
                                    </Text>
                                  </Col>
                                </Row>
                                <Row gutter={4}>
                                  {item.fees.map((value, i) => {
                                    return (
                                      <>
                                        <Col
                                          className="gutter-row"
                                          xs={{ span: 24, offset: 0 }}
                                          sm={{ span: 24, offset: 0 }}
                                          md={{ span: 12, offset: 0 }}
                                          lg={{ span: 12, offset: 0 }}
                                          xl={{ span: 12, offset: 0 }}
                                          xxl={{ span: 6, offset: 0 }}
                                        >
                                          <Text
                                            style={{
                                              color: "#fff",
                                              fontSize: 16,
                                            }}
                                          >
                                            {findTypeTransaction(
                                              value.transaction_type
                                            ).value + ":"}
                                          </Text>
                                        </Col>

                                        <Col
                                          className="gutter-row"
                                          xs={{ span: 24, offset: 0 }}
                                          sm={{ span: 24, offset: 0 }}
                                          md={{ span: 12, offset: 0 }}
                                          lg={{ span: 12, offset: 0 }}
                                          xl={{ span: 12, offset: 0 }}
                                          xxl={{ span: 6, offset: 0 }}
                                        >
                                          {value.value_type === "FIXED" && (
                                            <Text
                                              style={{
                                                color: "#fff",
                                                fontSize: 16,
                                              }}
                                            >
                                              {value.amount +
                                                " " +
                                                value.currency}
                                              {value.network !== "BASE_ASSET" &&
                                              value.network !== undefined
                                                ? "(" + value.network + ")"
                                                : ""}
                                            </Text>
                                          )}
                                          {value.value_type ===
                                            "PERCENTAGE" && (
                                            <Text
                                              style={{
                                                color: "#fff",
                                                fontSize: 16,
                                              }}
                                            >
                                              {value.percentage_amount +
                                                "%" +
                                                " (min " +
                                                value.currency +
                                                " " +
                                                value.min_amount +
                                                ")"}
                                            </Text>
                                          )}
                                        </Col>
                                      </>
                                    );
                                  })}
                                </Row>
                                <br />
                              </>
                            );
                          })}
                        </Col>

                        <Col className="gutter-row" span={1}>
                          <Divider
                            style={{
                              borderLeftColor: "#fff",
                              marginLeft: 30,
                              height:
                                userDetail.orderedFees?.length === 6
                                  ? "45em"
                                  : userDetail.orderedFees?.length === 4
                                    ? "31em"
                                    : "15em",
                              top: "2.5em",
                            }}
                            type="vertical"
                          />
                        </Col>
                      </>
                    )}
                    <Col className="gutter-row" span={9}>
                      {userDetail.tradingFees !== undefined && (
                        <>
                          {Object.keys(userDetail.tradingFees).length !== 0 && (
                            <>
                              <Divider
                                style={{ borderTopColor: "#fff" }}
                                orientation="left"
                              >
                                <Text
                                  style={{
                                    color: "#fff",
                                    fontSize: 16,
                                  }}
                                >
                                  {"Trading fees"}
                                </Text>
                              </Divider>
                              <Row gutter={4}>
                                {userDetail.tradingFees.risk_levels && (
                                  <>
                                    {userDetail.tradingFees.risk_levels.map(
                                      (item, i) => {
                                        return (
                                          <>
                                            <Col
                                              className="gutter-row"
                                              span={24}
                                            >
                                              <Text
                                                style={{
                                                  color: "#fff",
                                                  fontSize: 16,
                                                }}
                                              >
                                                {item.level}
                                                <Text
                                                  style={{
                                                    color: "#fff",
                                                    fontSize: 16,
                                                    marginLeft: 20,
                                                  }}
                                                >
                                                  {item.percentage + " %"}
                                                </Text>
                                              </Text>
                                            </Col>
                                          </>
                                        );
                                      }
                                    )}
                                  </>
                                )}
                                {userDetail.tradingFees.range && (
                                  <>
                                    {userDetail.tradingFees.range.map(
                                      (item, i) => {
                                        return (
                                          <>
                                            <Col
                                              className="gutter-row"
                                              span={24}
                                            >
                                              <Text
                                                style={{
                                                  color: "#fff",
                                                  fontSize: 16,
                                                }}
                                              >
                                                {compactFormat(
                                                  item.min,
                                                  "en",
                                                  "en"
                                                )}
                                                {item.max !== null
                                                  ? " - " +
                                                    compactFormat(
                                                      item.max,
                                                      "en",
                                                      "en"
                                                    )
                                                  : "+"}
                                                <Text
                                                  style={{
                                                    color: "#fff",
                                                    fontSize: 16,
                                                    marginLeft: 20,
                                                  }}
                                                >
                                                  {item.percentage + " %"}
                                                </Text>
                                              </Text>
                                            </Col>
                                          </>
                                        );
                                      }
                                    )}
                                  </>
                                )}
                                {userDetail.tradingFees.spread && (
                                  <>
                                    <Col className="gutter-row" span={8}>
                                      <Text
                                        style={{
                                          color: "#fff",
                                          fontSize: 16,
                                        }}
                                      >
                                        Trading fees
                                      </Text>
                                    </Col>
                                    <Col className="gutter-row" span={16}>
                                      <Text
                                        style={{
                                          color: "#fff",
                                          fontSize: 16,
                                        }}
                                      >
                                        {userDetail.tradingFees.spread + " %"}
                                      </Text>
                                    </Col>
                                  </>
                                )}
                                <>
                                  <Col className="gutter-row" span={8}>
                                    <Text
                                      style={{
                                        color: "#fff",
                                        fontSize: 16,
                                      }}
                                    >
                                      {"FX:"}
                                    </Text>
                                  </Col>
                                  <Col className="gutter-row" span={16}>
                                    <Text
                                      style={{
                                        color: "#fff",
                                        fontSize: 16,
                                      }}
                                    >
                                      {getFxLevel(userDetail.markups)}
                                    </Text>
                                  </Col>
                                </>
                              </Row>
                            </>
                          )}
                        </>
                      )}
                      {userDetail.commissioners_list !== undefined &&
                        userDetail.commissioners_list.length > 0 && (
                          <>
                            <Divider
                              style={{ borderTopColor: "#fff" }}
                              orientation="left"
                            >
                              <Text
                                style={{
                                  color: "#fff",
                                  fontSize: 16,
                                }}
                              >
                                {"Commissioners"}
                              </Text>
                            </Divider>
                            {userDetail.commissioners_list.map((item, i) => {
                              return (
                                <Row gutter={4}>
                                  <Col className="gutter-row" span={12}>
                                    <Text
                                      style={{
                                        color: "#fff",
                                        fontSize: 16,
                                      }}
                                    >
                                      {item.name}
                                    </Text>
                                  </Col>
                                  <Col className="gutter-row" span={12}>
                                    <Text
                                      style={{
                                        color: "#fff",
                                        fontSize: 16,
                                      }}
                                    >
                                      {item.percentage + " %"}
                                    </Text>
                                  </Col>
                                </Row>
                              );
                            })}
                          </>
                        )}

                      {userDetail.introducers_list !== undefined &&
                        userDetail.introducers_list.length > 0 && (
                          <>
                            <Divider
                              style={{ borderTopColor: "#fff" }}
                              orientation="left"
                            >
                              <Text
                                style={{
                                  color: "#fff",
                                  fontSize: 16,
                                }}
                              >
                                {"Introducers"}
                              </Text>
                            </Divider>
                            {userDetail.introducers_list.map((item, i) => {
                              return (
                                <Row gutter={4}>
                                  <Col className="gutter-row" span={12}>
                                    <Text
                                      style={{
                                        color: "#fff",
                                        fontSize: 16,
                                      }}
                                    >
                                      {item.name}
                                    </Text>
                                  </Col>
                                  <Col className="gutter-row" span={12}>
                                    <Text
                                      style={{
                                        color: "#fff",
                                        fontSize: 16,
                                      }}
                                    >
                                      {item.percentage + " %"}
                                    </Text>
                                  </Col>
                                </Row>
                              );
                            })}
                          </>
                        )}
                    </Col>
                  </Row>
                  {loadingUser && <Skeleton active />}
                </>
              ),
              expandedRowKeys: [rowId],
              onExpand: (expanded, record) => {
                if (expanded) {
                  setRowId(record._id);
                } else {
                  setRowId("");
                }
              }
            }}
          />
        )}
      </div>
      {(loading || isFiltering) && <Skeleton active />}
    </Card>
  );
};
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  getAllUsers,
  currentToken,
  clearUsers,
  getCommissionersIntroducers,
  clearCommissionersIntroducers,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
