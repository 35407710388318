import React, { useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
  Typography,
  Divider,
} from "antd";
import { useSelector } from "react-redux";
import servicesUsers from "../../services/user";
import NumberFormat from "react-number-format";
import compactFormat from "cldr-compact-number";

const { Option } = Select;
const { Text } = Typography;

const EditUserDrawer = ({ userData, onUserUpdated }) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { token } = useSelector((state) => ({
    token: state.tokenAccess,
  }));

  const showDrawer = (e) => {
    e.stopPropagation()
    setOpen(true);
    const formValues = {
      customer_name: userData.customer_name,
      email: userData.email,
      country: userData.country,
      type: userData.type,
      entity: userData.entity,
      status: userData.status,
      risk_level: userData.risk_level,
      trading_fee_type: userData.trading_fee_type,
    };

    // Filtramos valores undefined o null
    const cleanFormValues = Object.fromEntries(
      Object.entries(formValues).filter(
        ([_, value]) => value !== undefined && value !== null
      )
    );

    form.setFieldsValue(cleanFormValues);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // const response = await servicesUsers.updateUser(
      //   token.clientId,
      //   token.current,
      //   userData._id,
      //   values
      // );

      // if (response.status === 200) {
      //   message.success("User updated successfully");
      //   const updatedUser = {
      //     ...userData,
      //     ...response.data
      //   };
      //   onUserUpdated(updatedUser);
      //   onClose();
      // }
    } catch (error) {
      message.error(error.response?.data?.message || "Error updating user");
    } finally {
      setLoading(false);
    }
  };

  const renderUserInfo = () => {
    return (
      <>
        <Row gutter={16}>
          <Col span={12}>
            <Text strong>ID:</Text>
            <Text style={{ marginLeft: 8 }}>{userData._id}</Text>
          </Col>
          <Col span={12}>
            <Text strong>Created Date:</Text>
            <Text style={{ marginLeft: 8 }}>{userData.created_date}</Text>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 16 }}>
          <Col span={12}>
            <Text strong>Customer ID (FIAT):</Text>
            <Text style={{ marginLeft: 8 }}>
              {userData.customer_id || " - "}
            </Text>
          </Col>
          <Col span={12}>
            <Text strong>Customer Success Manager:</Text>
            <Text style={{ marginLeft: 8 }}>{userData.customer_success_manager || " - "}</Text>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 16 }}>
          <Col span={12}>
            <Text strong>Vault Account ID:</Text>
            <Text style={{ marginLeft: 8 }}>
              {userData.vault_account_id || " - "}
            </Text>
          </Col>
          <Col span={12}>
            <Text strong></Text>
            <Text style={{ marginLeft: 8 }}></Text>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 16 }}>
          <Col span={24}>
            <Text strong>Vault Account Name:</Text>
            <Text style={{ marginLeft: 8 }}>{userData.vault_account_name || " - "}</Text>
          </Col>
        </Row>
      </>
    );
  };

  const renderAccounts = () => {
    if (!userData.accounts?.length) return null;

    return (
      <>
        <Divider
          orientation="left"
          style={{
            borderColor: "#fff",
            color: "#fff",
          }}
        >
          Accounts
        </Divider>
        {userData.accounts.map((account, index) => (
          <Row gutter={16} key={index} style={{ marginTop: 8 }}>
            <Col span={8}>
              <Text strong>{account.currency}:</Text>
            </Col>
            <Col span={16}>
              <NumberFormat
                value={account.available_balance}
                thousandSeparator={true}
                displayType="text"
                suffix={` ${account.currency}`}
              />
            </Col>
          </Row>
        ))}
      </>
    );
  };

  const renderAddress = () => {
    if (!userData.physical_address) return null;

    return (
      <>
        <Divider
          orientation="left"
          style={{
            borderColor: "#fff",
            color: "#fff",
          }}
        >
          Address
        </Divider>
        <Row gutter={16}>
          <Col span={12}>
            <Text strong>City:</Text>
            <Text style={{ marginLeft: 8 }}>
              {userData.physical_address.city}
            </Text>
          </Col>
          <Col span={12}>
            <Text strong>Country:</Text>
            <Text style={{ marginLeft: 8 }}>
              {userData.physical_address.country}
            </Text>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 8 }}>
          <Col span={12}>
            <Text strong>Line 1:</Text>
            <Text style={{ marginLeft: 8 }}>
              {userData.physical_address.line_1}
            </Text>
          </Col>
          <Col span={12}>
            <Text strong>Line 2:</Text>
            <Text style={{ marginLeft: 8 }}>
              {userData.physical_address.line_2 || "N/A"}
            </Text>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 8 }}>
          <Col span={12}>
            <Text strong>Region:</Text>
            <Text style={{ marginLeft: 8 }}>
              {userData.physical_address.region}
            </Text>
          </Col>
          <Col span={12}>
            <Text strong>Postal Code:</Text>
            <Text style={{ marginLeft: 8 }}>
              {userData.physical_address.postal_code}
            </Text>
          </Col>
        </Row>
      </>
    );
  };

  const renderFeesCharged = () => {
    if (!userData.orderedFees?.length) return null;

    return (
      <>
        <Divider
          orientation="left"
          style={{
            borderColor: "#fff",
            color: "#fff",
          }}
        >
          Fees Charged
        </Divider>
        {userData.orderedFees.map((item, index) => (
          <div key={index}>
            <Text strong style={{ fontSize: 16 }}>
              {item.currency}:
            </Text>
            {item.fees.map((fee, feeIndex) => (
              <Row
                gutter={16}
                key={feeIndex}
                style={{ marginTop: 8, marginLeft: 16 }}
              >
                <Col span={12}>
                  <Text>{fee.transaction_type}:</Text>
                </Col>
                <Col span={12}>
                  {fee.value_type === "FIXED" ? (
                    <Text>
                      {fee.amount} {fee.currency}
                      {fee.network && fee.network !== "BASE_ASSET"
                        ? ` (${fee.network})`
                        : ""}
                    </Text>
                  ) : (
                    <Text>
                      {fee.percentage_amount}% (min {fee.currency}{" "}
                      {fee.min_amount})
                    </Text>
                  )}
                </Col>
              </Row>
            ))}
            <Divider style={{ margin: "12px 0" }} />
          </div>
        ))}
      </>
    );
  };

  const renderTradingFees = () => {
    if (!userData.tradingFees) return null;

    return (
      <>
        <Divider
          orientation="left"
          style={{
            borderColor: "#fff",
            color: "#fff",
          }}
        >
          Trading Fees
        </Divider>
        {userData.tradingFees.risk_levels && (
          <>
            {userData.tradingFees.risk_levels.map((level, index) => (
              <Row gutter={16} key={index} style={{ marginTop: 8 }}>
                <Col span={12}>
                  <Text>{level.level}:</Text>
                </Col>
                <Col span={12}>
                  <Text>{level.percentage}%</Text>
                </Col>
              </Row>
            ))}
          </>
        )}
        {userData.tradingFees.range && (
          <>
            {userData.tradingFees.range.map((range, index) => (
              <Row gutter={16} key={index} style={{ marginTop: 8 }}>
                <Col span={12}>
                  <Text>
                    {compactFormat(range.min, "en", "en")}
                    {range.max !== null
                      ? ` - ${compactFormat(range.max, "en", "en")}`
                      : "+"}
                  </Text>
                </Col>
                <Col span={12}>
                  <Text>{range.percentage}%</Text>
                </Col>
              </Row>
            ))}
          </>
        )}
        {userData.tradingFees.spread && (
          <Row gutter={16} style={{ marginTop: 8 }}>
            <Col span={12}>
              <Text>Trading fees:</Text>
            </Col>
            <Col span={12}>
              <Text>{userData.tradingFees.spread}%</Text>
            </Col>
          </Row>
        )}
        {userData.markups && (
          <Row gutter={16} style={{ marginTop: 8 }}>
            <Col span={12}>
              <Text>FX:</Text>
            </Col>
            <Col span={12}>
              <Text>
                {userData.markups.find((m) => m.fx_fee)?.fx_fee?.percentage ||
                  userData.markups.find((m) => m.spread)?.spread}
                %{userData.markups.find((m) => m.spread) ? " (Spread)" : ""}
              </Text>
            </Col>
          </Row>
        )}
      </>
    );
  };

  const renderCommissioners = () => {
    if (!userData.commissioners_list?.length) return null;

    return (
      <>
        <Divider
          orientation="left"
          style={{
            borderColor: "#fff",
            color: "#fff",
          }}
        >
          Commissioners
        </Divider>
        {userData.commissioners_list.map((commissioner, index) => (
          <Row gutter={16} key={index} style={{ marginTop: 8 }}>
            <Col span={12}>
              <Text>{commissioner.name}</Text>
            </Col>
            <Col span={12}>
              <Text>{commissioner.percentage}%</Text>
            </Col>
          </Row>
        ))}
      </>
    );
  };

  const renderIntroducers = () => {
    if (!userData.introducers_list?.length) return null;

    return (
      <>
        <Divider
          orientation="left"
          style={{
            borderColor: "#fff",
            color: "#fff",
          }}
        >
          Introducers
        </Divider>
        {userData.introducers_list.map((introducer, index) => (
          <Row gutter={16} key={index} style={{ marginTop: 8 }}>
            <Col span={12}>
              <Text>{introducer.name}</Text>
            </Col>
            <Col span={12}>
              <Text>{introducer.percentage}%</Text>
            </Col>
          </Row>
        ))}
      </>
    );
  };

  const renderTwoFactor = () => {
    if (!userData.two_factor) return null;

    return (
      <>
        <Divider orientation="left">Two Factor Authentication</Divider>
        {Object.entries(userData.two_factor).map(
          ([key, value], index) =>
            value && (
              <Row gutter={16} key={index} style={{ marginTop: 8 }}>
                <Col span={8}>
                  <Text>{key}:</Text>
                </Col>
                <Col span={16}>
                  <Text>
                    {value.method !== "NONE"
                      ? `${value.status} (${value.method === "EMAIL" ? "Email" : "Authenticator"})`
                      : "Not Configured"}
                  </Text>
                </Col>
              </Row>
            )
        )}
      </>
    );
  };

  return (
    <>
      <Button
        type="primary"
        onClick={showDrawer}
        icon={<EditOutlined />}
        size="small"
      >
        Edit
      </Button>
      <Drawer
        title="Edit User"
        width={720}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              onClick={() => form.submit()}
              type="primary"
              loading={loading}
            >
              Save
            </Button>
          </Space>
        }
      >
        {renderUserInfo()}

        <Divider
          orientation="left"
          style={{
            borderColor: "#fff",
            color: "#fff",
          }}
        >
          Editable Information
        </Divider>
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          
          onFinish={onFinish}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="customer_name"
                label="Customer Name"
                rules={[
                  { required: true, message: "Please enter customer name" },
                ]}
              >
                <Input disabled placeholder="Enter customer name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Please enter email" },
                  { type: "email", message: "Please enter a valid email" },
                ]}
              >
                <Input disabled placeholder="Enter email" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="country"
                label="Country"
                rules={[
                  { required: true, message: "Please enter country code" },
                ]}
              >
                <Input disabled placeholder="Enter country code" maxLength={2} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="type"
                label="Type"
                rules={[{ required: true, message: "Please select type" }]}
              >
                <Select disabled placeholder="Select type">
                  <Option value="STANDARD">Standard</Option>
                  <Option value="PREMIUM">Premium</Option>
                  <Option value="VIP">VIP</Option>
                  <Option value="FIAT_ONLY">Fiat only</Option>
                  <Option value="REGULAR">Regular</Option>
                  <Option value="LITE">Lite</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="entity"
                label="Entity"
                rules={[{ required: true, message: "Please select entity" }]}
              >
                <Select disabled placeholder="Select entity">
                  <Option value="GIB">GIB</Option>
                  <Option value="UAB">UAB</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="status"
                label="Status"
                rules={[{ required: true, message: "Please select status" }]}
              >
                <Select placeholder="Select status">
                  <Option value="WAITING_FOR_KYC">Waiting for KYC</Option>
                  <Option value="PENDING_REVIEW">Pending review</Option>
                  <Option value="COUNTRY_NOT_SUPPORTED">
                    Country not supported
                  </Option>
                  <Option value="REJECTED">Rejected</Option>
                  <Option value="RETRY_KYC">Retry KYC</Option>
                  <Option value="APPROVED">Approved</Option>
                  <Option value="ACTIVE">Active</Option>
                  <Option value="KYC_RENEWAL">KYC Renewal</Option>
                  <Option value="INACTIVE">Inactive</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="risk_level" label="Risk Level">
                <Select disabled placeholder="Select risk level">
                  <Option value="LOW">Low</Option>
                  <Option value="MEDIUM">Medium</Option>
                  <Option value="HIGH">High</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="trading_fee_type" label="Trading Fee Type">
                <Select disabled placeholder="Select trading fee type">
                  <Option value="TIERS">Tiers</Option>
                  <Option value="FIXED">Fixed</Option>
                  <Option value="PERCENTAGE">Percentage</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {renderAccounts()}
        {renderAddress()}
        {renderFeesCharged()}
        {renderTradingFees()}
        {renderCommissioners()}
        {renderIntroducers()}
        {renderTwoFactor()}
      </Drawer>
    </>
  );
};

export default EditUserDrawer;
