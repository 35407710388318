import { GET_PROFILE_USER } from "../actions/profileActions";
const initialState = {
  profile: {},
  headers: {},
};
export function profileReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROFILE_USER:
      return {
        ...state,
        profile: action.payload.data?.data?.user,
      };

    default:
      return state;
  }
}

export default profileReducer;
