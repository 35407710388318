export const ALL_USERS = "ALL_USERS";
export const COMMISSIONERS_INTRODUCERS = "COMMISSIONERS_INTRODUCERS";
export function getAllUsers(response) {
  return async (dispatch, getState) => {
    dispatch({
      type: ALL_USERS,
      payload: response,
    });
  };
}
export function getCommissionersIntroducers(response) {
  return async (dispatch, getState) => {
    dispatch({
      type: COMMISSIONERS_INTRODUCERS,
      payload: response,
    });
  };
}
export function clearCommissionersIntroducers() {
  return async (dispatch, getState) => {
    dispatch({
      type: COMMISSIONERS_INTRODUCERS,
      payload: {},
    });
  };
}
export function clearUsers() {
  return async (dispatch, getState) => {
    dispatch({
      type: ALL_USERS,
      payload: {},
    });
  };
}
