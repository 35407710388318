import servicesProfile from "../../services/profile";
export const GET_PROFILE_USER = "GET_PROFILE_USER";

export function getProfileUser(id, accessToken) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesProfile.getProgile(id, accessToken);

      dispatch({
        type: GET_PROFILE_USER,
        payload: response,
      });
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: GET_PROFILE_USER,
          payload: error.response,
        });
      }
    }
  };
}
