export const COMMISSIONS = "COMMISSIONS";

export function getCommissions(response) {
  return async (dispatch, getState) => {
    dispatch({
      type: COMMISSIONS,
      payload: response,
    });
  };
}
