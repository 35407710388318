import axios from "axios";
import config from "./config";
const BASE_URL = config.URL;

const user = {
  async userAuth(body, idToken, os, browser, token) {
    const resp = await axios.post(BASE_URL + config.login, body, {
      headers: {
        "X-CLIENT-ID": idToken,
        "X-CLIENT-OS": os,
        "X-CLIENT-NAME": browser,
        "X-CLIENT-TOKEN": token,
      },
    });
    return resp;
  },
  async userLogout(idToken, accessToken) {
    const resp = await axios.delete(BASE_URL + config.logout, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async usersList(idToken, accessToken, lastID) {
    let url = BASE_URL + config.users;
    if (lastID !== undefined) {
      url = url + "?last_id=" + lastID;
    }
    const resp = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async userAccount(idToken, accessToken, userId) {
    const resp = await axios.get(BASE_URL + config.accounts + userId, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async refreshToken(idToken, accessToken) {
    const resp = await axios.put(
      BASE_URL + config.refreshToken,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-CLIENT-ID": idToken,
        },
      }
    );
    return resp;
  },
  async listAllUsers(idToken, accessToken, filter) {
    const resp = await axios.get(BASE_URL + config.allUsers, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
      params: {
        kyc_level: filter?.kyc_level || null,
        country: filter?.country || null,
        customer_success_manager: filter?.customer_success_manager || null,
        customer_name: filter?.customer_name || null,
        username: filter?.username || null,
        status: filter?.status || null,
        email: filter?.user_email || null,
        type: filter?.type || null,
        last_id: filter?.last_id || null,
        user_id: filter?.user_id || null,
        platform: filter?.platform || null,
        introducer_name: filter?.introducer_name || null,
        commissioner_name: filter?.commissioner_name || null,
      },
    });
    return resp;
  },
  async commissionersOrIntroducersList(idToken, accessToken) {
    let url = BASE_URL + config.commissionersOrIntroducers;

    const resp = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
};
export default user;
