import React, { useEffect, useState } from "react";
import "./createTransactionsOTC.less";
import { connect, useSelector } from "react-redux";
import { Modal, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { currentToken } from "../../redux/actions/tokenActions";
import OtcBridge from "./otcBridge";
import TwoFactor from "./twoFactor";
import servicesOtc from "../../services/otc";

const CreateTransactionOTC = (props) => {
  const [current, setCurrent] = useState(0);
  const [code, setCode] = useState("");
  const [amount, setAmount] = useState("");
  const [network, setNetwork] = useState("");
  const [loading, setLoading] = useState(false);

  const { token } = useSelector((state) => ({
    token: state.tokenAccess,
  }));
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function nextStep() {
    setCurrent(current + 1);
  }
  function prevStep() {
    setCurrent(current - 1);
  }
  function handleCode(e) {
    const reg = /^[0-9]+$/;
    if (reg.test(e.target.value) || e.target.value === "") {
      setCode(e.target.value);
    }
  }
  function handleNetwork(value) {
    setNetwork(value);
  }
  function handleAmount(values) {
    setAmount(values.value);
  }
  function validationError(messageError, otherMessage) {
    if (messageError === "MISSING_ACCOUNT_ID") {
      message.error("Missing account_id", 10);
    } else if (messageError === "MISSING_AMOUNT") {
      message.error("Missing amount", 10);
    } else if (messageError === "INVALID_VALUE_AMOUNT") {
      message.error("'Amount' is invalid", 10);
    } else if (messageError === "INVALID_ACCOUNT_ID") {
      message.error("'Account ID' is invalid", 10);
    } else if (messageError === "ACCOUNT_BLOCKED") {
      message.error("Account is locked", 10);
    } else if (messageError === "INSUFFICIENT_BALANCE") {
      message.error("Business user does not have enough available balance", 10);
    } else if (messageError === "MISSING_GAUTH_TOKEN") {
      message.error(
        "The admin user must first configure their 2FA with GAuth",
        10
      );
    } else if (messageError === "MISSING_OTP") {
      message.error("Missing code", 10);
    } else if (
      messageError === "INVALID_TYPE_OTP" ||
      messageError === "INVALID_VALUE_OTP"
    ) {
      message.error("Invalid code", 10);
    } else if (messageError === "OTP_TOO_SHORT") {
      message.error("The code is less than 6 digits", 10);
    } else if (messageError === "OTP_TOO_LONG") {
      message.error("The code is greater than 6 digits", 10);
    }  else if (messageError === "MISSING_NETWORK" || messageError === "INVALID_VALUE_NETWORK") {
      message.error("Invalid network value", 10);
    }else if (messageError === "INVALID_NETWORK") {
      message.error("The network is not supported for the current account.", 10);
    } else {
      message.error(otherMessage, 10);
    }
  }

  async function createTransactionOtc() {
    try {
      setLoading(true);
      let body = {
        account_id: props.accountData._id,
        amount: amount,
      };
      if (props.accountData.type === "CRYPTO") {
        body.network = network;
      }

      let response = await servicesOtc.createTransactionOtc(
        token.clientId,
        token.current,
        body
      );
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        nextStep();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.response.headers !== undefined) {
        let newAccessToken = error.response.headers["x-new-access-token"];
        props.currentToken(newAccessToken);
      }
      if (error.response.status === 400) {
        if (error.response.data.error_details?.payload_error !== undefined) {
          let messageError = error.response.data.error_details.payload_error;
          validationError(messageError, error.response.data.message);
        } else if (
          error.response.data.error_details?.validation_error !== undefined
        ) {
          let messageError = error.response.data.error_details.validation_error;
          validationError(messageError, error.response.data.message);
        } else {
          message.error(error.response.data.message);
        }
      } else if (error.response.status === 401) {
        message.error("Session has expired");
      } else {
        message.error(error.response.data.message);
      }
    }
  }
  async function confirmTransaction() {
    try {
      setLoading(true);
      let body = {
        otp: code,
      };
      let response = await servicesOtc.confirmTransactionOtc(
        token.clientId,
        token.current,
        body
      );
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        setLoading(false);
        props.closeModalSend();
        props.getAccounts();
        setAmount("");
        setCurrent(0);
        setCode("");
        message.success("The funds have been sent to the OTC bridge", 10);
      }
    } catch (error) {
      setLoading(false);
      if (error.response.headers !== undefined) {
        let newAccessToken = error.response.headers["x-new-access-token"];
        await props.currentToken(newAccessToken);
      }
      if (error.response.status === 400) {
        if (error.response.data.error_details?.payload_error !== undefined) {
          let messageError = error.response.data.error_details.payload_error;
          validationError(messageError, error.response.data.message);
        } else if (
          error.response.data.error_details?.validation_error !== undefined
        ) {
          let messageError = error.response.data.error_details.validation_error;
          validationError(messageError, error.response.data.message);
        } else {
          message.error(error.response.data.message);
        }
      } else if (error.response.status === 401) {
        message.error("Session has expired");
      } else {
        message.error(error.response.data.message);
      }
    }
  }

  const steps = [
    {
      content: (
        <OtcBridge
          userData={props.userData}
          accountData={props.accountData}
          next={nextStep.bind(this)}
          handleAmount={handleAmount.bind(this)}
          amount={amount}
          createTransactionOtc={createTransactionOtc.bind(this)}
          loading={loading}
          network={network}
          handleNetwork={handleNetwork.bind(this)}
        />
      ),
    },
    {
      content: (
        <TwoFactor
          prev={prevStep.bind(this)}
          closeModalSend={props.closeModalSend.bind(this)}
          createTransactionOtc={createTransactionOtc.bind(this)}
          loading={loading}
          handleCode={handleCode.bind(this)}
          code={code}
          confirmTransaction={confirmTransaction.bind(this)}
        />
      ),
    },
  ];
  return (
    <>
      <Modal
        open={props.modalTransactionOTC}
        bodyStyle={{ background: "#00001E" }}
        maskStyle={{ backgroundColor: "rgba(0,0, 0, 0.90)" }}
        onOk={() => props.openModalSend()}
        onCancel={() => props.closeModalSend()}
        footer={null}
        closeIcon={<CloseOutlined style={{ color: "#fff" }} />}
      >
        {steps[current].content}
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    //token: state.tokenAccess,
  };
};

const mapDispatchToProps = {
  currentToken,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateTransactionOTC);
