// Dependencies
import React from "react";

// Ant Design Components
import { Row, Col, Input, Select, DatePicker, Button } from "antd";

const { Option } = Select;

const FilterSection = ({
  inputValues,
  selectValues,
  startDate,
  endDate,
  handleInputChange,
  handleFilterChange,
  handleDateChange,
  onSearch,
  handleReset,
  isFiltering,
  entities,
  transactionTypes,
  supportedCurrencies,
  disabledStartDate,
  disabledEndDate,
}) => {

  // Text input fields configuration
  const inputFields = [
    {
      placeholder: "Transaction ID",
      value: inputValues.transaction_id,
      field: "transaction_id",
      width: 4,
    },
    {
      placeholder: "User Email",
      value: inputValues.user_email,
      field: "user_email",
      width: 5,
    },
    {
      placeholder: "User Full Name",
      value: inputValues.user_fullname,
      field: "user_fullname",
      width: 5,
    },
  ];

  // Select fields configuration
  const selectFields = [
    {
      placeholder: "Entity",
      value: selectValues.entity,
      field: "entity",
      options: entities,
      width: 5,
    },
    {
      placeholder: "Currency",
      value: selectValues.currency_code,
      field: "currency_code",
      options: [
        ...(supportedCurrencies?.map(currency => ({
          key: currency.code,
          value: currency.name
        })) || []),
        { key: "ALL", value: "All" }
      ],
      width: 5,
    },
    {
      placeholder: "Type",
      value: selectValues.type,
      field: "type",
      options: transactionTypes,
      width: 4,
    },
  ];

  // -----------------------------------------------------------------------------
  // @ Render
  // -----------------------------------------------------------------------------
  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
      {/* Text Input Fields */}
      {inputFields.map(({ placeholder, value, field, width }) => (
        <Col xs={24} sm={12} md={6} lg={width} key={field}>
          <Input
            placeholder={placeholder}
            size="large"
            value={value}
            onChange={(e) => handleInputChange(e, field)}
          />
        </Col>
      ))}

      {/* Select Fields */}
      {selectFields.map(({ placeholder, value, field, options, width }) => (
        <Col xs={24} sm={12} md={6} lg={width} key={field}>
          <Select
            style={{ width: "100%" }}
            size="large"
            placeholder={placeholder}
            value={value}
            onChange={(value) => handleFilterChange(value, field)}
          >
            {options.map((item) => (
              <Option key={item.key} value={item.key}>
                {item.value}
              </Option>
            ))}
          </Select>
        </Col>
      ))}

      {/* Date Range Pickers */}
      <Col xs={24} sm={12} md={6} lg={5}>
        <DatePicker
          placeholder="Start date"
          size="large"
          format="YYYY-MM-DD"
          style={{ width: "100%" }}
          showTime={false}
          showToday={false}
          value={startDate}
          onChange={(value) => handleDateChange("start", value)}
          disabledDate={disabledStartDate}
        />
      </Col>
      <Col xs={24} sm={12} md={6} lg={5}>
        <DatePicker
          placeholder="End date"
          size="large"
          format="YYYY-MM-DD"
          style={{ width: "100%" }}
          showTime={false}
          showToday={false}
          value={endDate}
          onChange={(value) => handleDateChange("end", value)}
          disabledDate={disabledEndDate}
        />
      </Col>

      {/* Action Buttons */}
      <Col xs={24} sm={12} md={6} lg={5}>
        <Button
          type="primary"
          size="large"
          onClick={onSearch}
          loading={isFiltering}
          style={{ width: "100%" }}
        >
          Filter
        </Button>
      </Col>
      <Col xs={24} sm={12} md={6} lg={5}>
        <Button
          type="secondary"
          size="large"
          onClick={handleReset}
          loading={isFiltering}
          style={{ width: "100%" }}
        >
          Reset
        </Button>
      </Col>
    </Row>
  );
};

export default FilterSection;
