import { Tabs, Table, Tag, Typography } from 'antd';

const { Text } = Typography;

const TradeTable = () => {
  const columns = [
    {
      title: 'Trade no.',
      dataIndex: 'tradeNo',
      key: 'tradeNo',
      fixed: 'left',
    },
    {
      title: 'Date/Time',
      dataIndex: 'dateTime',
      key: 'dateTime',
    },
    {
      title: 'Client/Status',
      dataIndex: 'clientStatus',
      key: 'clientStatus',
      ellipsis: false,
      render: (text, record) => (
        <div className="client-status-cell">
          <Text className="client-name">{record.client}</Text>
          <Tag className={`status-tag ${record.status.toLowerCase().replace(/\s+/g, '-')}`}>
            {record.status}
          </Tag>
        </div>
      ),
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
    },
    {
      title: 'Pair/Direction',
      dataIndex: 'pairDirection',
      key: 'pairDirection',
      render: (text, record) => (
        <div className="pair-direction">
          <Text>{record.pair}</Text>
          <Tag className={`direction-tag ${record.direction.toLowerCase()}`}>
            {record.direction}
          </Tag>
        </div>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text, record) => (
        <div className="amount-cell">
          <Text>{text}</Text>
          <Text type="secondary">{record.quantityCurrency}</Text>
        </div>
      ),
    },
    {
      title: 'LP Amount',
      dataIndex: 'lpAmount',
      key: 'lpAmount',
      render: (text, record) => (
        <div className="amount-cell">
          <Text>{text}</Text>
          <Text type="secondary">{record.lpCurrency}</Text>
        </div>
      ),
    },
    {
      title: 'Client Amount',
      dataIndex: 'clientAmount',
      key: 'clientAmount',
      render: (text, record) => (
        <div className="amount-cell">
          <Text>{text}</Text>
          <Text type="secondary">{record.clientCurrency}</Text>
        </div>
      ),
    },
    {
      title: 'Revenue Bank Used',
      dataIndex: 'revenue',
      key: 'revenue',
      render: (text, record) => (
        <div className="revenue-bank-cell">
          <div className="revenue-amount">
            <Text>{text}</Text>
            <Text className="currency">{record.revenueCurrency}</Text>
          </div>
          <div className="bank-info">
            <Text className="separator">•</Text>
            <Text className="bank-name">{record.bankUsed}</Text>
          </div>
        </div>
      ),
    },
    {
      title: 'Trade Fee Charged (%)',
      dataIndex: 'tradeFee',
      key: 'tradeFee',
      align: 'right',
    },
  ];

  const mockData = [
    {
      key: '14000',
      tradeNo: '14000',
      dateTime: '08/10/2024 15:32:57',
      client: 'Mayfly Entertainment Limited',
      status: 'Transfer funds to Client',
      platform: 'B2C2',
      pair: 'USDT/GBP',
      direction: 'Sell',
      quantity: '39,598.81',
      quantityCurrency: 'USDT',
      lpAmount: '30,288.14',
      lpCurrency: 'GBP',
      clientAmount: '30,288.14',
      clientCurrency: 'GBP',
      revenue: '0',
      revenueCurrency: 'GBP',
      bankUsed: 'BCB',
      tradeFee: '0.00%',
    },
    {
      key: '13999',
      tradeNo: '13999',
      dateTime: '08/11/2024 14:23:05',
      client: 'Sam Buxton',
      status: 'Completed',
      platform: 'Wincent',
      pair: 'BTC/USDT',
      direction: 'Buy',
      quantity: '0.06267865',
      quantityCurrency: 'BTC',
      lpAmount: '6,280',
      lpCurrency: 'USDT',
      clientAmount: '6,280',
      clientCurrency: 'USDT',
      revenue: '0',
      revenueCurrency: 'USDT',
      bankUsed: 'None',
      tradeFee: '0.00%',
    },
    {
      key: '13998',
      tradeNo: '13998',
      dateTime: '08/11/2024 14:12:19',
      client: 'Cinclader Limited',
      status: 'Completed',
      platform: 'Wincent',
      pair: 'USDT/EUR',
      direction: 'Sell',
      quantity: '30,000.00',
      quantityCurrency: 'USDT',
      lpAmount: '27,892.27',
      lpCurrency: 'EUR',
      clientAmount: '27,752.52',
      clientCurrency: 'EUR',
      revenue: '167.35',
      revenueCurrency: 'EUR',
      bankUsed: 'Turicum',
      tradeFee: '0.60%',
    },
    {
      key: '13997',
      tradeNo: '13997',
      dateTime: '08/11/2024 14:02:39',
      client: 'Every Matrix N.V.',
      status: 'Completed',
      platform: 'Wincent',
      pair: 'USDT/EUR',
      direction: 'Sell',
      quantity: '188,740.00',
      quantityCurrency: 'USDT',
      lpAmount: '175,246.84',
      lpCurrency: 'EUR',
      clientAmount: '174,812.88',
      clientCurrency: 'EUR',
      revenue: '613.97',
      revenueCurrency: 'EUR',
      bankUsed: 'Clear Junction',
      tradeFee: '0.35%',
    },
    {
      key: '13996',
      tradeNo: '13996',
      dateTime: '08/11/2024 13:50:03',
      client: 'Every Matrix N.V.',
      status: 'Completed',
      platform: 'Wincent',
      pair: 'USDT/EUR',
      direction: 'Sell',
      quantity: '27,000.00',
      quantityCurrency: 'USDT',
      lpAmount: '25,087.41',
      lpCurrency: 'EUR',
      clientAmount: '24,999.57',
      clientCurrency: 'EUR',
      revenue: '87.84',
      revenueCurrency: 'EUR',
      bankUsed: 'Clear Junction',
      tradeFee: '0.35%',
    },
  ];

  return (
    <div className="trade-table-container">
      <Tabs
        defaultActiveKey="recent"
        items={[
          {
            key: 'recent',
            label: 'Recent OTC Trades',
            children: (
              <Table 
                columns={columns} 
                dataSource={mockData}
                scroll={{ x: 'max-content' }}
                tableLayout="auto"
              />
            ),
          },
          {
            key: 'b2c2',
            label: 'B2C2 Trade History',
            children: (
              <Table 
                columns={columns} 
                dataSource={mockData}
                scroll={{ x: 'max-content' }}
                tableLayout="auto"
              />
            ),
          },
          {
            key: 'calculation',
            label: 'Calculation Tools',
            children: (
              <Table 
                columns={columns} 
                dataSource={mockData}
                scroll={{ x: 'max-content' }}
                tableLayout="auto"
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default TradeTable; 