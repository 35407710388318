import React, { useState } from "react";
import { Table } from "antd";
import { Card, Typography, Tooltip, Select, Row, Button, Col, Input, Popconfirm, Form,  } from "antd";
import "./exchange.less";
import { EditOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";
const { Text } = Typography;
const { Option } = Select;

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const dataSource = [];
  dataSource.push(
    {
      key: 1,
      symbol: "BTC/ETH",
      type: "Swap",
      buyMarkup: "1.5%",
      sellMarkup: "1%",
      buyMinimum: "0",
      sellMinimum: "0"
    },
    {
      key: 2,
      symbol: "BTC/GBP",
      type: "Buy/Sell",
      buyMarkup: "1.5%",
      sellMarkup: "1%",
      buyMinimum: "0",
      sellMinimum: "0"
    }
  );

const ExchangeTable = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(dataSource);
  const [editingKey, setEditingKey] = useState('');
  const par = [];
  const type = [];

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      buyMarkup: '',
      sellMarkup: '',
      buySpread: '',
      sellSpread: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Symbol Pair
        </Text>
      ),
      dataIndex: "symbol",
      key: "symbol"
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Type
        </Text>
      ),
      dataIndex: "type",
      key: "type"
    },

    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Buy Markup
        </Text>
      ),
      dataIndex: "buyMarkup",
      key: "buyMarkup",
      editable: true,
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Sell Markup
        </Text>
      ),
      dataIndex: "sellMarkup",
      key: "sellMarkup",
      editable: true,
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Buy Minimum
        </Text>
      ),
      dataIndex: "buyMinimum",
      key: "buyMinimum",
      editable: true,
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Sell Minimum
        </Text>
      ),
      dataIndex: "sellMinimum",
      key: "sellMinimum",
      editable: true,
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Edit 
        </Text>
      ),
      key: 'edit',
      render: (_, record) => {
         const editable = isEditing(record);
         return editable ? (
           <span>
                <Typography.Link
                    onClick={() => save(record.key)}
                    style={{
                        marginRight: 8,
                    }}
                >
                    <Tooltip placement="top" title="Save">                        
                        <SaveOutlined />
                    </Tooltip>
                </Typography.Link>
                <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                    <Tooltip placement="top" title="Cancel">                        
                        <CloseOutlined />
                    </Tooltip>
                </Popconfirm>
           </span>
         ) : (
           <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                <Tooltip placement="top" title="Edit">    
                    <EditOutlined />
                </Tooltip>
           </Typography.Link>
         );
      },
    },

  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Card
      className="card-transactions"
      bodyStyle={{ padding: "10px 0px 10px 0px" }}
    >
        
      <Row
        gutter={[8, { xs: 8, sm: 16, md: 16, lg: 16, xl: 16 }]}
        style={{ padding: "5px 10px" }}
      >
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 8, offset: 0 }}
          xl={{ span: 8, offset: 0 }}
          xxl={{ span: 8, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Select style={{ width: "100%" }} size="large" placeholder="Symbol Pair">
            {par.map((item, i) => {
              return <Option key={i} value={item.value}>{item.name}</Option>;
            })}
          </Select>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 8, offset: 0 }}
          xl={{ span: 8, offset: 0 }}
          xxl={{ span: 8, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Select style={{ width: "100%" }} size="large" placeholder="Type">
            {type.map((item, i) => {
              return <Option key={i} value={item.key}>{item.value}</Option>;
            })}
          </Select>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 8, offset: 0 }}
          xl={{ span: 8, offset: 0 }}
          xxl={{ span: 8, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Button type="primary" size="large" style={{ width: "100%" }}>
            Filter
          </Button>
        </Col>
      </Row>
       <div
        id="table-history"
        className="ant-table ant-table-tbody ant-table-thead text-table-cell ant-table-cell"
        style={{ marginTop: 10 }}
      >
        <Form form={form} component={false}>
            <Table
                components={{
                body: {
                    cell: EditableCell,
                },
                }}
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row row-table-deposit"
                pagination={{
                onChange: cancel,
                }}
            />
        </Form>
      </div> 
    </Card>
  );
};

export default ExchangeTable;
