import axios from "axios";
import config from "./config";
const BASE_URL = config.URL;
const transaction = {
  async transaction(idToken, accessToken, filter) {
    const resp = await axios.get(BASE_URL + config.transaction, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
      params: {
        id: filter?._id || null,
        transaction_hash: filter?.transaction_hash || null,
        transaction_id: filter?.transaction_id || null,
        entity: filter?.entity || null,
        platform: filter?.platform || null,
        currency_code: filter?.currency_code || null,
        status: filter?.status || null,
        min_date: filter?.min_date || null,
        max_date: filter?.max_date || null,
        timezone_offset: filter?.timezone_offset || null,
        user_email: filter?.user_email || null,
        type: filter?.type || null,
        last_id: filter?.last_id || null,
        user_id: filter?.user_id || null,
        aml_screened: filter?.aml_screened || null,
        aml_reviewer_id: filter?.aml_reviewer_id || null,
        aml_rule_id: filter?.aml_rule_id || null,
        customer_name: filter?.customer_name || null,
      },
    });
    return resp;
  },
  async getRulesFtm(idToken, accessToken) {
    let url = BASE_URL + config.rulesFtm;

    const resp = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
};
export default transaction;
