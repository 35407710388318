import React from "react";
import { Tabs, Typography } from "antd";
import "./exchange.less";
import ExchangeTable from "./exchangeTable";
const { Text } = Typography;
const Exchange = () => {
  const tabsItems = [
    {
      key: "1",
      label: (
        <Text
          style={{
            fontSize: 18,
            color: "#fff",
          }}
        >
          Damex
        </Text>
      ),
      children: <ExchangeTable source={"Damex"} />,
    },
    {
      key: "2",
      label: (
        <Text
          style={{
            fontSize: 18,
            color: "#fff",
          }}
        >
          Damex Direct
        </Text>
      ),
      children: <ExchangeTable source={"Damex Direct"} />,
    },
  ];
  return <Tabs defaultActiveKey="1" items={tabsItems}></Tabs>;
};
export default Exchange;
