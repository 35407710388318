import axios from "axios";
import config from "./config";
const BASE_URL = config.URL;
const commissions = {
  async getCommissions(idToken, accessToken, filter) {
    const resp = await axios.get(BASE_URL + config.commissions, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
      params: {
        min_date: filter?.min_date || null,
        max_date: filter?.max_date || null,
        timezone_offset: filter?.timezone_offset || null,
        commissioner_email: filter?.commissioner_email || null,
        commissioner_name: filter?.commissioner_name || null,
      },
    });
    return resp;
  },
};
export default commissions;
