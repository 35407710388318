import React from "react";
import { Tabs, Typography } from "antd";
import "./freezeWithdrawals.less";
import FreezeTable from "./freezeTable";
const { Text } = Typography;
const FreezeWithdrawals = () => {
  const tabItems = [
    {
      key: "1",
      label: (
        <Text
          style={{
            fontSize: 18,
            color: "#fff",
          }}
        >
          Damex
        </Text>
      ),
      children: <FreezeTable />,
    },
    {
      key: "2",
      label: (
        <Text
          style={{
            fontSize: 18,
            color: "#fff",
          }}
        >
          Damex Direct
        </Text>
      ),
      children: <FreezeTable />,
    },
  ];
  return <Tabs defaultActiveKey="1" items={tabItems}></Tabs>;
};
export default FreezeWithdrawals;
