import React from "react";
import {
  Row,
  Col,
  Space,
  Typography,
  Button,
  Modal,
  Input,
  Form
} from "antd";
import ShieldLockOutline from "@2fd/ant-design-icons/lib/ShieldLockOutline";
import "./signin.less";

const { Text, Title } = Typography;

const TwoFactorSignin = (props) => {

    const onInputChange = (value) => {

        props.verificationCodeReceived(value.code)       

    }

    return (
      <div>
        <Modal
          footer={null}
          className="container-twofactor"
          maskStyle={{ backgroundColor: "rgba(0,0, 0, 0.90)" }}
          open={props.modalActivate}
          onOk={() => props.openModal()}
          onCancel={() => props.closeModal()}
          centered={true}
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            <Row
              gutter={[8, { xs: 8, sm: 16, md: 24, lg: 32 }]}
              justify="center"
            >
                <Space direction="vertical" style={{}}>
                    
                    <ShieldLockOutline
                    style={{ color: "#53ff84", fontSize: 80,  marginLeft: "auto", marginRight: "auto"  }}
                    />
                    <Title level={4} style={{ color: "#00001E", textAlign: "center" }}>
                        Two-step verification
                    </Title>
                    <Text
                    style={{
                        color: "#00001E",
                        fontSize: 16,
                        padding: "0px 24px",
                    }}
                    >
                    Please enter your Google Authenticator code
                    </Text>
                </Space>
            </Row>
            <Row
              gutter={[8, { xs: 8, sm: 16, md: 24, lg: 32 }]}
              justify="center"
            >
              <Space direction="vertical">

                <Space direction="horizontal" size="small" style={{ width: "98%" }}>
                 <Form onFinish={ onInputChange } >
                    <Form.Item name="code" rules={[
                        ({getFieldValue}) =>({
                            validator(rule, value){
                                if(!value || getFieldValue("code").length === 6){
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error("Please complete the verification code"));
                            }  
                        })
                    ]}>
                        <Input
                            maxLength={ 6 }
                            size="large"
                            style={{ width: "50%",  color:"black" }}
                            placeholder="000000"
                        /> 
                    </Form.Item>
                    <Row gutter={[32, { xs: 8, sm: 16, md: 24, lg: 32 }]} justify="end">
                        <Col
                            xs={{ span: 24, offset: 0 }}
                            sm={{ span: 24, offset: 0 }}
                            md={{ span: 12, offset: 0 }}
                            lg={{ span: 12, offset: 0 }}
                            xl={{ span: 12, offset: 0 }}
                        >
                          <div id="btn-previous" className="btn-previous-security">
                            <Button
                              block
                              size="large"
                              className="btn-previous-security"
                              onClick={() => props.closeModal()}
                            >
                              Cancel
                            </Button>
                          </div>
                        </Col>
                        <Col
                            xs={{ span: 24, offset: 0 }}
                            sm={{ span: 24, offset: 0 }}
                            md={{ span: 12, offset: 0 }}
                            lg={{ span: 12, offset: 0 }}
                            xl={{ span: 12, offset: 0 }}
                        >
                            <Button
                              block
                              size="large"
                              type="primary"
                              loading={props.loading}
                              htmlType="submit"
                              style={{
                                  height: 60,
                                  fontSize: 18,
                                  fontWeight: 600,
                                  borderRadius: "5px",
                              }}
                            >
                              Confirm
                            </Button>
                        </Col>
                    </Row>
                 </Form>
                </Space>
              </Space>
            </Row>
          </Space>
        </Modal>
      </div>
    );
}

export default TwoFactorSignin;
