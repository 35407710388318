import React, { useEffect } from "react";
import { Table } from "antd";
import { Col, Card, Space, Typography, Image } from "antd";
import "./dashboard.less";
import NumberFormat from "react-number-format";
import { asset } from "../../common/assets";
import { useState } from "react";
import iconDefault from "../../images/logoDam.png";

const { Text, Title } = Typography;

const BalanceTable = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const [flag, setFlag] = useState(false);

  function getInfoCurrency(currency,type) {
    let data = asset.assets;
    let findCurrency = data.find((element) => element.value === currency);
    if (findCurrency === undefined) {
      let objCurrency = {
        color: "#50af95",
        icon: iconDefault,
        name: currency,
        symbol: currency,
        type: type,
        value: currency,
      };
      return objCurrency;
    } else {
      return findCurrency;
    }
  }

  const columns = [
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Currency
        </Text>
      ),
      dataIndex: "currency",
      key: "currency",
      render: (currency) => (
        <>
          <Space align="center">
            <Image
              style={{ alignItems: "center", display: "inline-flex" }}
              preview={false}
              width={25}
              src={currency && getInfoCurrency(currency)?.icon}
            />
            <Text
              style={{
                color: "#fff",
                fontSize: 16,
              }}
            >
              {currency && getInfoCurrency(currency)?.name}
            </Text>
          </Space>
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Balance
        </Text>
      ),
      dataIndex: "balance",
      key: "balance",
      render: (record, originNode) => (
        <>
          <Text
            style={{
              color: "#fff",
              fontSize: 18,
            }}
          >
            <NumberFormat
              thousandSeparator={true}
              prefix={
                originNode &&
                originNode?.currency &&
                originNode?.currency?.symbol &&
                getInfoCurrency(originNode?.currency, originNode?.currency_type)
                  ?.symbol + " "
              }
              displayType="text"
              decimalScale={8}
              value={originNode.client_balance}
            />
          </Text>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (!flag) {
      let newArray = [];
      for (let i = 0; i < props.data.balances.length; i++) {
        if (
          props.data !== undefined 
          // props.data.balances[i].currency_type === "CRYPTO" &&
          // props.data.balances[i].currency !== "FAU" 
        ) {
          newArray.push({
            key: props.data.balances[i].currency,
            ...props.data.balances[i],
          });
        }
      }
      setDataSource(newArray);
      setFlag(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Col span="12">
      <Card className="graph-card">
        <Title
          level={4}
          style={{
            color: "#fff",
            textAlign: "left",
          }}
        >
          User balances by Asset
        </Title>
        <div
          id="table-balances"
          className="ant-table ant-table-tbody ant-table-thead text-table-cell"
        >
          {dataSource.length > 5 ? (
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={{ pageSize: 10 }}
            />
          ) : (
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
          )}
        </div>
      </Card>
    </Col>
  );
};

export default BalanceTable;
