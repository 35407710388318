import React, { useState } from "react";
import {
  Card,
  Row,
  Col,
  Input,
  Typography,
  Switch,
  Button,
  Divider,
  AutoComplete,
  Checkbox,
  Space,
  Select,
  Radio,
} from "antd";
import {
  FolderOutlined,
  PauseCircleOutlined,
  FormOutlined,
} from "@ant-design/icons";
import "./trade.less";
import  TradeTable  from './TradeTable';

const { Text } = Typography;

const Trade = () => {
  const [selectedPair, setSelectedPair] = useState("USDT/EUR");
  const [isBuying, setIsBuying] = useState(true);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientQuantity, setClientQuantity] = useState("150,000");
  const [clientFee, setClientFee] = useState("0.35");
  const [revenueCcy, setRevenueCcy] = useState("EUR");
  const [isIndicative, setIsIndicative] = useState(false);
  const [showExecution, setShowExecution] = useState(false);
  const [selectedLPs, setSelectedLPs] = useState({
    B2C2: true,
    Scrypt: true,
    Finery: false,
    Wincent: true,
  });
  const [diffFrom, setDiffFrom] = useState("Best LP");

  // Mock data
  const mockLPs = [
    { name: "B2C2", price: "0.93304", change: "-0.03%" },
    { name: "Scrypt", price: "0.93259", change: "-0.08%" },
    { name: "Finery", price: "0.93350", change: "0.00%" },
    { name: "Wincent", price: "0.933598", change: "Best" },
  ];

  const mockClients = [
    {
      value: "1",
      label: "Mayfly Entertainment Limited",
      data: {
        accountStatus: "Active",
        accountType: "Corporate",
        country: "United Kingdom",
        dateOnboarded: "2023-01-15",
        products: "OTC Trading",
        clientFee: "0.35%",
        crm: "John Doe",
        introducer: "Direct",
        introducerFee: "0.00%",
        bankUsed: "BCB",
        rolling30DayVol: "£1,234,567",
      },
    },
  ];

  const handleClientSelect = (value) => {
    const client = mockClients.find((c) => c.value === value);
    setSelectedClient(client);
  };

  const tradingPairs = [
    { value: "USDT/EUR", label: "USDT/EUR" },
    { value: "USDT/GBP", label: "USDT/GBP" },
  ];

  const handlePairChange = (value) => {
    setSelectedPair(value);
  };

  return (
    <div className="trade-container">
      <Row gutter={[16, 16]}>
        {/* Client Information */}
        <Col span={8}>
          <Card title="Client Information" className="client-info-card">
            <AutoComplete
              options={mockClients}
              style={{ width: "100%", marginBottom: 16 }}
              placeholder="Select Client..."
              filterOption={(inputValue, option) =>
                option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
              onChange={handleClientSelect}
            />

            <div className="client-details">
              <div className="detail-item">
                <Text className="detail-label">Account status</Text>
                <Text className="detail-value">
                  {selectedClient?.data.accountStatus || "-"}
                </Text>
              </div>
              <div className="detail-item">
                <Text className="detail-label">Account type</Text>
                <Text className="detail-value">
                  {selectedClient?.data.accountType || "-"}
                </Text>
              </div>
              <div className="detail-item">
                <Text className="detail-label">Country</Text>
                <Text className="detail-value">
                  {selectedClient?.data.country || "-"}
                </Text>
              </div>
              <div className="detail-item">
                <Text className="detail-label">Date onboarded</Text>
                <Text className="detail-value">
                  {selectedClient?.data.dateOnboarded || "-"}
                </Text>
              </div>
              <div className="detail-item">
                <Text className="detail-label">Product(s)</Text>
                <Text className="detail-value">
                  {selectedClient?.data.products || "-"}
                </Text>
              </div>
              <Divider />
              <div className="detail-item">
                <Text className="detail-label">Client fee</Text>
                <Text className="detail-value">
                  {selectedClient?.data.clientFee || "-"}
                </Text>
              </div>
              <div className="detail-item">
                <Text className="detail-label">CRM</Text>
                <Text className="detail-value">
                  {selectedClient?.data.crm || "-"}
                </Text>
              </div>
              <div className="detail-item">
                <Text className="detail-label">Introducer</Text>
                <Text className="detail-value">
                  {selectedClient?.data.introducer || "-"}
                </Text>
              </div>
              <div className="detail-item">
                <Text className="detail-label">Introducer fee / basis</Text>
                <Text className="detail-value">
                  {selectedClient?.data.introducerFee || "-"}
                </Text>
              </div>
              <div className="detail-item">
                <Text className="detail-label">Bank used</Text>
                <Text className="detail-value">
                  {selectedClient?.data.bankUsed || "-"}
                </Text>
              </div>
              <div className="detail-item">
                <Text className="detail-label">Rolling 30-day vol</Text>
                <Text className="detail-value">
                  {selectedClient?.data.rolling30DayVol || "-"}
                </Text>
              </div>
            </div>

            <Button
              icon={<FolderOutlined />}
              type="link"
              className="drive-folder-btn"
            >
              Drive Folder
            </Button>
          </Card>
        </Col>

        {/* OTC Trade Details */}
        <Col span={8}>
          <Card title="OTC Trade Details" className="trade-details-card">
            <div className="pair-header">
              <AutoComplete
                value={selectedPair}
                options={tradingPairs}
                className="pair-select"
                onChange={handlePairChange}
                filterOption={(inputValue, option) =>
                  option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
              />
              <div className="price-info">
                <div className="price-item">
                  <Text type="secondary">LAST PRICE</Text>
                  <Text>0.9335</Text>
                </div>
                <div className="price-item">
                  <Text type="secondary">24 HR CHANGE</Text>
                  <Text className="positive-change">+0.73%</Text>
                </div>
              </div>
            </div>

            <div className="trade-direction">
              <div 
                className={`direction-option ${isBuying ? 'buying active' : ''}`}
                onClick={() => setIsBuying(true)}
              >
                Buying
              </div>
              <div 
                className={`direction-option ${!isBuying ? 'selling active' : ''}`}
                onClick={() => setIsBuying(false)}
              >
                Selling
              </div>
            </div>

            <div className="trade-inputs-grid">
              <div className="input-group">
                <Text>Client Quantity</Text>
                <Input
                  value={clientQuantity}
                  onChange={(e) => setClientQuantity(e.target.value)}
                  suffix="USDT"
                />
              </div>

              <div className="input-group">
                <Text>Client Volume</Text>
                <Input value="-" disabled suffix="EUR" />
              </div>

              <div className="input-group">
                <Text>Client fee</Text>
                <Input
                  value={clientFee}
                  onChange={(e) => setClientFee(e.target.value)}
                  suffix="%"
                />
              </div>

              <div className="input-group">
                <Text>LP price</Text>
                <Input
                  value="0.9333598"
                  disabled
                  suffix={<Text type="success">+0.00%</Text>}
                />
              </div>
            </div>

            <div className="trade-details">
              <div className="detail-row">
                <Text type="secondary">Client price</Text>
                <Text>0.930093</Text>
              </div>
              <div className="detail-row">
                <Text type="secondary">Quantity</Text>
                <Text>150000 USDT</Text>
              </div>
              <div className="detail-row">
                <Text type="secondary">Client volume</Text>
                <Text>139,513.95 EUR</Text>
              </div>
              <div className="detail-row">
                <Text type="secondary">LP Volume</Text>
                <Text>140,003.97 EUR</Text>
              </div>
              <div className="detail-row">
                <Text type="secondary">Revenue</Text>
                <Text>490.02 EUR</Text>
              </div>
              <div className="detail-row">
                <Text type="secondary">Revenue CCY</Text>
                <div className="currency-toggle">
                  <Radio.Group 
                    value={revenueCcy} 
                    onChange={(e) => setRevenueCcy(e.target.value)}
                    className="revenue-currency-toggle"
                  >
                    <Radio.Button value="USDT">USDT</Radio.Button>
                    <Radio.Button value="EUR">EUR</Radio.Button>
                  </Radio.Group>
                </div>
              </div>
            </div>

            <div className="bottom-controls">
              <div className="indicative-check">
                <Checkbox
                  checked={isIndicative}
                  onChange={(e) => setIsIndicative(e.target.checked)}
                >
                  Indicative
                </Checkbox>
                <div className="quote-section">
                  <Text type="secondary" className="quote-label">Quote message</Text>
                  <Text className="quote-message">USDT/EUR Bid 0.930093</Text>
                </div>
              </div>
            </div>
          </Card>
        </Col>

        {/* Pricing & Execution */}
        <Col span={8}>
          <Card title="Pricing & Execution" className="pricing-execution-card">
            <div className="header-switches">
              <Button
                type={!showExecution ? "primary" : "default"}
                onClick={() => setShowExecution(false)}
                className={!showExecution ? "active" : ""}
              >
                Compare LPs
              </Button>
              <Button
                type={showExecution ? "primary" : "default"}
                onClick={() => setShowExecution(true)}
                className={showExecution ? "active" : ""}
              >
                Execution
              </Button>
            </div>

            <div className="section-title">
              <Text>Bids (Selling)</Text>
            </div>

            <div className="lp-prices">
              {mockLPs.map((lp, index) => (
                <div key={index} className="lp-price-row">
                  <Space>
                    <Switch
                      size="small"
                      checked={selectedLPs[lp.name]}
                      onChange={(checked) =>
                        setSelectedLPs((prev) => ({
                          ...prev,
                          [lp.name]: checked,
                        }))
                      }
                    />
                    <Text>{lp.name}</Text>
                  </Space>
                  <Space>
                    <Text>{lp.price}</Text>
                    <Text
                      className={
                        lp.change === "Best"
                          ? "best-price"
                          : lp.change.startsWith("-")
                          ? "negative-change"
                          : "positive-change"
                      }
                    >
                      {lp.change}
                    </Text>
                  </Space>
                </div>
              ))}
            </div>

            <div className="summary-section">
              <div className="summary-row">
                <Text type="secondary">Avg. of LPs</Text>
                <Space>
                  <Text>0.9329966</Text>
                  <Text className="negative-change">-0.04%</Text>
                </Space>
              </div>
              <div className="summary-row">
                <Text type="secondary">Kraken ex.</Text>
                <Space>
                  <Text>0.9335</Text>
                  <Text className="positive-change">0.02%</Text>
                </Space>
              </div>
            </div>

            <div className="diff-from-section">
              <Text type="secondary">% Diff from</Text>
              <Select
                value={diffFrom}
                onChange={setDiffFrom}
                className="diff-select"
                options={[
                  { value: "Best LP", label: "Best LP" },
                  { value: "Avg LP", label: "Avg LP" },
                  { value: "Kraken", label: "Kraken" },
                ]}
              />
            </div>

            <Text className="trading-info">Selling 150000 USDT to EUR</Text>

            <Button
              type="primary"
              danger
              icon={<PauseCircleOutlined />}
              block
              className="stop-streaming-btn"
            >
              Stop Streaming
            </Button>

            <Button
              type="default"
              icon={<FormOutlined />}
              block
              className="log-trade-btn"
            >
              Log New Trade
            </Button>
          </Card>
        </Col>
      </Row>

      {/* Recent OTC Trades */}
      <TradeTable />
    </div>
  );
};

export default Trade;
