import React, { useState, useEffect } from "react";
import {
  Tabs,
  Typography,
  DatePicker,
  Card,
  Col,
  Row,
  Select,
  Button,
  message,
  Divider,
  Space,
  Skeleton,
  Table,
} from "antd";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import moment from "moment";
import "./commissions.less";
import { connect } from "react-redux";
import { currentToken } from "../../redux/actions/tokenActions";
import { getCommissionersIntroducers } from "../../redux/actions/userActions";
import NumberFormat from "react-number-format";
import { getCommissions } from "../../redux/actions/commissionsActions";
import servicesUsers from "../../services/user";
import servicesCommissions from "../../services/commissions";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";

dayjs.extend(weekday);
dayjs.extend(localeData);
const { Text } = Typography;
const { Option } = Select;
const Commissions = (props) => {
  const [filter, setFilter] = useState({});
  const [commissionersArray, setCommissionersArray] = useState([]);
  const [currentCommisionerName, setCurrentCommissionerName] = useState(null);
  const [currentCommisionerEmail, setCurrentCommissionerEmail] = useState(null);
  const [loadingCom, setLoadingCom] = useState(false);
  const [firstLevelCommissions, setFirstLevelCommissions] = useState({});
  const [commissionersList, setCommissionersList] = useState([]);
  const [rowIdTx, setRowIdTx] = useState("");
  const [rowId, setRowId] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const types = [
    { key: "FIAT_ONLY", value: "Fiat only" },
    { key: "LITE", value: "Lite" },
    { key: "DIRECT", value: "Direct" },
    { key: "DEPOSIT", value: "Deposit" },
    { key: "TRANSFER_IN", value: "Transfer in" },
    { key: "EXCHANGE_IN", value: "Exchange in" },
    { key: "WITHDRAWAL", value: "Withdrawal" },
    { key: "TRANSFER_OUT", value: "Transfer out" },
    { key: "EXCHANGE_OUT", value: "Exchange out" },
    { key: "REBALANCE_IN", value: "Rebalance in" },
    { key: "REBALANCE_OUT", value: "Rebalance out" },
  ];
  useEffect(() => {
    setRowId("");
    setRowIdTx("");
    getCommissionersInfo();
    getCommissionsList(false, true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function getMonth() {
    const startDate = new Date();
    return new Date(startDate.getFullYear(), startDate.getMonth(), 1);
  }
  async function getCommissionsList(filtering, start) {
    try {
      let initialFilter = null;
      if (start) {
        let startDate = getMonth();
        let now = new Date();
        const initialDay = moment(startDate).format("YYYY-MM-DD");
        const actualDay = moment(now).format("YYYY-MM-DD");
        const time = new Date().getTimezoneOffset();
        setMinDate(dayjs(initialDay, "YYYY-MM-DD"));
        setMaxDate(dayjs(actualDay, "YYYY-MM-DD"));
        initialFilter = {
          min_date: initialDay,
          max_date: actualDay,
          timezone_offset: time,
        };
        setFilter({
          min_date: initialDay,
          max_date: actualDay,
          timezone_offset: time,
        });
      }
      if (filtering) {
        setIsFiltering(true);
      }
      setLoading(true);
      const response = await servicesCommissions.getCommissions(
        props.clientId,
        props.token,
        initialFilter !== null ? initialFilter : filter
      );
      if (response.headers["x-new-access-token"] !== undefined) {
        props.currentToken(response.headers["x-new-access-token"]);
      }

      if (response.status === 200) {
        let commissionData = response.data.data;
        await props.getCommissions(commissionData);
        let objFirstLevel = {};
        Object.entries(commissionData).forEach(([key, value], i) => {
          if (key !== "commissioners") {
            objFirstLevel[key] = value;
          }
        });

        setFirstLevelCommissions(objFirstLevel);
        setCommissionersList(commissionData.commissioners);
        setLoading(false);
        setIsFiltering(false);
      } else if (response.status === 400) {
        setLoading(false);
        setIsFiltering(false);
        if (response.data.message !== undefined) {
          message.error(response.data.message);
        }
      } else if (response.status === 422) {
        message.error("Invalid token");
        setLoading(false);
        setIsFiltering(false);
      } else if (response.status === 401) {
        message.error("Expired token");
        setLoading(false);
        setIsFiltering(false);
      } else if (response.status === 403 && response.status === 500)
        message.error(response.data.message);
      setLoading(false);
      setIsFiltering(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setIsFiltering(false);
    }
  }
  async function getCommissionersInfo() {
    try {
      setLoadingCom(true);
      const response = await servicesUsers.commissionersOrIntroducersList(
        props.clientId,
        props.token
      );
      if (response.headers["x-new-access-token"] !== undefined) {
        props.currentToken(response.headers["x-new-access-token"]);
      }
      await props.getCommissionersIntroducers(response);
      let data = response.data.data.commissioners_or_introducers;
      if (data.length > 0) {
        if (response.status === 200) {
          let commissioners = data.filter(
            (element) => element.type === "COMMISSIONER"
          );

          let orderedCommissioners = commissioners.sort(function (a, b) {
            let fullNameA = "";
            let fullNameB = "";

            fullNameA = a.name;

            fullNameB = b.name;

            return fullNameA.localeCompare(fullNameB);
          });

          setCommissionersArray(orderedCommissioners);
          setLoadingCom(false);
        }
      }
    } catch (error) {
      setLoadingCom(false);
      if (error.response.status === 400) {
        if (error.response.data.error_details.payload_error !== undefined) {
          let messageError = error.response.data.error_details.payload_error;
          validationError(messageError, error.response.data.message);
        } else {
          message.error(error.response.data.message);
        }
        message.error("Invalid token");
      } else if (error.response.status === 422) {
        message.error("Invalid token");
      } else if (error.response.status === 401) {
        message.error("Expired token");
      } else if (error.response.status === 403 && error.response.status === 500)
        message.error(error.response.data.message);
    }
  }
  function validationError(messageError, otherMessage) {
    if (messageError === "INVALID_VALUE_TYPE") {
      message.error("'Type' is invalid", 10);
    } else if (messageError === "INVALID_VALUE_STATUS") {
      message.error("'Status' is invalid", 10);
    } else {
      message.error(otherMessage, 10);
    }
  }
  function handleCommissioner(value, type) {
    if (value) {
      type === "NAME"
        ? setCurrentCommissionerName(value.split("_")[1])
        : setCurrentCommissionerEmail(value.split("_")[1]);
      let val = value.split("_")[1];
      let name;
      val !== "" ? (name = val) : (name = "");
      if (type === "NAME") {
        setFilter({
          ...filter,
          commissioner_name: name,
        });
      } else {
        setFilter({
          ...filter,
          commissioner_email: name,
        });
      }
    } else {
      if (type === "NAME") {
        setCurrentCommissionerName(null);
        setFilter({
          ...filter,
          commissioner_name: "",
        });
      } else {
        setCurrentCommissionerEmail(null);
        setFilter({
          ...filter,
          commissioner_email: "",
        });
      }
    }
  }
  function labelCards(name) {
    let label = "";
    if (name === "total_commissions_eur") {
      label = "Total  Commissions (EUR)";
    } else if (name === "total_commissions_gbp") {
      label = "Total  Commissions (GBP)";
    } else if (name === "total_fees_eur") {
      label = "Total Fees (EUR)";
    } else if (name === "total_fees_gbp") {
      label = "Total Fees (GBP)";
    } else if (name === "volume_deposited_eur") {
      label = "Total deposited (EUR)";
    } else if (name === "volume_deposited_gbp") {
      label = "Total deposited (GBP)";
    } else if (name === "volume_traded_eur") {
      label = "Total traded (EUR)";
    } else if (name === "volume_traded_gbp") {
      label = "Total traded (GBP)";
    } else if (name === "volume_withdrawn_eur") {
      label = "Total withdrew (EUR)";
    } else if (name === "volume_withdrawn_gbp") {
      label = "Total withdrew (GBP)";
    }
    return label;
  }
  function findTypes(type) {
    let data = types.find((element) => element.key === type);
    return data.value;
  }
  const columns = [
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Email
        </Text>
      ),
      dataIndex: "email",
      key: "email",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Name
        </Text>
      ),
      dataIndex: "name",
      key: "name",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {"Volume traded (GBP)"}
        </Text>
      ),
      width: 150,
      dataIndex: "volume_traded_gbp",
      key: "volume_traded_gbp",
      render: (volume_traded_gbp) => (
        <>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            prefix="£ "
            decimalScale={2}
            value={volume_traded_gbp}
          />
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {"Volume traded (EUR)"}
        </Text>
      ),
      width: 150,
      dataIndex: "volume_traded_eur",
      key: "volume_traded_eur",
      render: (volume_traded_eur) => (
        <>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            prefix="€ "
            decimalScale={2}
            value={volume_traded_eur}
          />
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {"Volume deposited (GBP)"}
        </Text>
      ),
      width: 150,
      dataIndex: "volume_deposited_gbp",
      key: "volume_deposited_gbp",
      render: (volume_deposited_gbp) => (
        <>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            prefix="£ "
            decimalScale={2}
            value={volume_deposited_gbp}
          />
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {"Volume deposited (EUR)"}
        </Text>
      ),
      width: 160,
      dataIndex: "volume_deposited_eur",
      key: "volume_deposited_eur",
      render: (volume_deposited_eur) => (
        <>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            prefix="€ "
            decimalScale={2}
            value={volume_deposited_eur}
          />
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {"Volume withdrew (GBP)"}
        </Text>
      ),
      width: 175,
      dataIndex: "volume_withdrawn_gbp",
      key: "volume_withdrawn_gbp",
      render: (volume_withdrawn_gbp) => (
        <>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            prefix="£ "
            decimalScale={2}
            value={volume_withdrawn_gbp}
          />
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {"Volume withdrew (EUR)"}
        </Text>
      ),
      width: 150,
      dataIndex: "volume_withdrawn_eur",
      key: "volume_withdrawn_eur",
      render: (volume_withdrawn_eur) => (
        <>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            prefix="€ "
            decimalScale={2}
            value={volume_withdrawn_eur}
          />
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {"Fees (GBP)"}
        </Text>
      ),
      dataIndex: "total_fees_gbp",
      key: "total_fees_gbp",
      width: 150,
      render: (total_fees_gbp) => (
        <>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            prefix="£ "
            decimalScale={2}
            value={total_fees_gbp}
          />
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {"Fees (EUR)"}
        </Text>
      ),
      width: 150,
      dataIndex: "total_fees_eur",
      key: "total_fees_eur",
      render: (total_fees_eur) => (
        <>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            prefix="€ "
            decimalScale={2}
            value={total_fees_eur}
          />
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {" Commissions (GBP)"}
        </Text>
      ),
      width: 150,
      dataIndex: "total_commissions_gbp",
      key: "total_commissions_gbp",
      render: (total_commissions_gbp) => (
        <>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            prefix="£ "
            decimalScale={2}
            value={total_commissions_gbp}
          />
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {" Commissions (EUR)"}
        </Text>
      ),
      width: 150,
      dataIndex: "total_commissions_eur",
      key: "total_commissions_eur",
      render: (total_commissions_eur) => (
        <>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            prefix="€ "
            decimalScale={2}
            value={total_commissions_eur}
          />
        </>
      ),
    },
  ];
  const columnsClients = [
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Client Name
        </Text>
      ),
      dataIndex: "name",
      key: "name",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Email
        </Text>
      ),
      width: 170,
      dataIndex: "email",
      key: "email",
      className: "id-column",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {"Entity"}
        </Text>
      ),
      width: 150,
      dataIndex: "entity",
      key: "entity",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          User type
        </Text>
      ),
      dataIndex: "user_type",
      key: "user_type",
      width: 120,
      render: (user_type) => (
        <>
          {findTypes(user_type) !== undefined
            ? findTypes(user_type)
            : user_type}
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {"Volume traded (GBP)"}
        </Text>
      ),
      width: 160,
      dataIndex: "volume_traded_gbp",
      key: "volume_traded_gbp",
      render: (volume_traded_gbp) => (
        <>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            prefix="£ "
            decimalScale={2}
            value={volume_traded_gbp}
          />
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {"Volume traded (EUR)"}
        </Text>
      ),
      width: 160,
      dataIndex: "volume_traded_eur",
      key: "volume_traded_eur",
      render: (volume_traded_eur) => (
        <>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            prefix="€ "
            decimalScale={2}
            value={volume_traded_eur}
          />
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {"Volume deposited (GBP)"}
        </Text>
      ),
      width: 160,
      dataIndex: "volume_deposited_gbp",
      key: "volume_deposited_gbp",
      render: (volume_deposited_gbp) => (
        <>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            prefix="£ "
            decimalScale={2}
            value={volume_deposited_gbp}
          />
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {"Volume deposited (EUR)"}
        </Text>
      ),
      width: 160,
      dataIndex: "volume_deposited_eur",
      key: "volume_deposited_eur",
      render: (volume_deposited_eur) => (
        <>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            prefix="€ "
            decimalScale={2}
            value={volume_deposited_eur}
          />
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {"Volume withdrew (GBP)"}
        </Text>
      ),
      width: 170,
      dataIndex: "volume_withdrawn_gbp",
      key: "volume_withdrawn_gbp",
      render: (volume_withdrawn_gbp) => (
        <>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            prefix="£ "
            decimalScale={2}
            value={volume_withdrawn_gbp}
          />
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {"Volume withdrew (EUR)"}
        </Text>
      ),
      width: 170,
      dataIndex: "volume_withdrawn_eur",
      key: "volume_withdrawn_eur",
      render: (volume_withdrawn_eur) => (
        <>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            prefix="€ "
            decimalScale={2}
            value={volume_withdrawn_eur}
          />
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {"Fees (GBP)"}
        </Text>
      ),
      dataIndex: "total_fees_gbp",
      key: "total_fees_gbp",
      width: 150,
      render: (total_fees_gbp) => (
        <>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            prefix="£ "
            decimalScale={2}
            value={total_fees_gbp}
          />
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {"Fees (EUR)"}
        </Text>
      ),
      width: 150,
      dataIndex: "total_fees_eur",
      key: "total_fees_eur",
      render: (total_fees_eur) => (
        <>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            prefix="€ "
            decimalScale={2}
            value={total_fees_eur}
          />
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {" Commissions (GBP)"}
        </Text>
      ),
      width: 160,
      dataIndex: "total_commissions_gbp",
      key: "total_commissions_gbp",
      render: (total_commissions_gbp) => (
        <>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            prefix="£ "
            decimalScale={2}
            value={total_commissions_gbp}
          />
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {" Commissions (EUR)"}
        </Text>
      ),
      width: 160,
      dataIndex: "total_commissions_eur",
      key: "total_commissions_eur",
      render: (total_commissions_eur) => (
        <>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            prefix="€ "
            decimalScale={2}
            value={total_commissions_eur}
          />
        </>
      ),
    },
  ];
  const columnsTransactions = [
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Transactions ID
        </Text>
      ),
      dataIndex: "id",
      key: "id",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Date
        </Text>
      ),
      width: 150,
      dataIndex: "created_date",
      key: "created_date",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Type
        </Text>
      ),
      width: 150,
      dataIndex: "type",
      key: "type",
      render: (type) => (
        <>{findTypes(type) !== undefined ? findTypes(type) : type}</>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Currency
        </Text>
      ),
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          Amount
        </Text>
      ),
      width: 150,
      dataIndex: "amount",
      key: "amount",
      render: (record, originNode) => (
        <>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            decimalScale={2}
            value={originNode.amount}
          />
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {"Fees (GBP)"}
        </Text>
      ),
      dataIndex: "total_fees_gbp",
      key: "total_fees_gbp",
      width: 150,
      render: (total_fees_gbp) => (
        <>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            prefix="£ "
            decimalScale={2}
            value={total_fees_gbp}
          />
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {"Fees (EUR)"}
        </Text>
      ),
      width: 150,
      dataIndex: "total_fees_eur",
      key: "total_fees_eur",
      render: (total_fees_eur) => (
        <>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            prefix="€ "
            decimalScale={2}
            value={total_fees_eur}
          />
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {" Commissions (GBP)"}
        </Text>
      ),
      width: 170,
      dataIndex: "total_commissions_gbp",
      key: "total_commissions_gbp",
      render: (total_commissions_gbp) => (
        <>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            prefix="£ "
            decimalScale={2}
            value={total_commissions_gbp}
          />
        </>
      ),
    },
    {
      title: (
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
          }}
        >
          {" Commissions (EUR)"}
        </Text>
      ),
      width: 170,
      dataIndex: "total_commissions_eur",
      key: "total_commissions_eur",
      render: (total_commissions_eur) => (
        <>
          <NumberFormat
            thousandSeparator={true}
            displayType="text"
            style={{
              fontSize: 16,
              fontWeight: 400,
            }}
            prefix="€ "
            decimalScale={2}
            value={total_commissions_eur}
          />
        </>
      ),
    },
  ];
  const commissionsLevels = (
    <Card
      className="card-commissions"
      bodyStyle={{ padding: "10px 0px 10px 0px" }}
    >
      <Row
        gutter={[8, { xs: 8, sm: 16, md: 16, lg: 16, xl: 16 }]}
        style={{ padding: "5px 10px" }}
      >
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 5, offset: 0 }}
          xl={{ span: 5, offset: 0 }}
          xxl={{ span: 5, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Select
            allowClear
            showSearch
            defaultValue={currentCommisionerName}
            filterOption={(input, option) => {
              setCurrentCommissionerName(input);
              if (option.value) {
                let value = option.value.split("_")[1];
                return value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }
            }}
            style={{ width: "100%" }}
            size="large"
            placeholder="Commissioner name"
            loading={loadingCom}
            disabled={loadingCom}
            name="commissioner_name"
            onChange={(value) => handleCommissioner(value, "NAME")}
            value={currentCommisionerName}
          >
            {commissionersArray.map((item, i) => {
              return (
                <Option value={item._id + "_" + item.name} key={item._id}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 5, offset: 0 }}
          xl={{ span: 5, offset: 0 }}
          xxl={{ span: 5, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Select
            allowClear
            showSearch
            defaultValue={currentCommisionerEmail}
            filterOption={(input, option) => {
              setCurrentCommissionerEmail(input);
              if (option.value) {
                let value = option.value.split("_")[1];
                return value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }
            }}
            style={{ width: "100%" }}
            size="large"
            placeholder="Commissioner email"
            loading={loadingCom}
            disabled={loadingCom}
            name="commissioner_email"
            onChange={(value) => handleCommissioner(value, "EMAIL")}
            value={currentCommisionerEmail}
          >
            {commissionersArray.map((item, i) => {
              return (
                <Option value={item._id + "_" + item.email} key={item._id}>
                  {item.email}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 5, offset: 0 }}
          xl={{ span: 5, offset: 0 }}
          xxl={{ span: 5, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <DatePicker
            placeholder="Start date"
            size="large"
            format="YYYY-MM-DD"
            style={{ width: "100%" }}
            showTime={false}
            showToday={false}
            name="min_date"
            value={minDate}
            onChange={(value) => {
              setMinDate(value ? dayjs(value, "YYYY-MM-DD") : "");
              const date = value
                ? dayjs(value, "YYYY-MM-DD").format("YYYY-MM-DD")
                : "";
              const time = value ? new Date().getTimezoneOffset() : "";
              setFilter({
                ...filter,
                min_date: date,
                timezone_offset: time,
              });
            }}
          />
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 5, offset: 0 }}
          xl={{ span: 5, offset: 0 }}
          xxl={{ span: 5, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <DatePicker
            placeholder="End date"
            size="large"
            format="YYYY-MM-DD"
            style={{ width: "100%" }}
            name="max_date"
            value={maxDate}
            onChange={(value) => {
              const date = value
                ? dayjs(value, "YYYY-MM-DD").format("YYYY-MM-DD")
                : "";
              const time = value ? new Date().getTimezoneOffset() : "";
              setMaxDate(value ? dayjs(value, "YYYY-MM-DD") : "");
              setFilter({
                ...filter,
                max_date: date,
                timezone_offset: time,
              });
            }}
            showTime={false}
          />
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 4, offset: 0 }}
          xl={{ span: 4, offset: 0 }}
          xxl={{ span: 4, offset: 0 }}
          style={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Button
            type="primary"
            size="large"
            style={{ width: "100%" }}
            loading={isFiltering}
            onClick={() => getCommissionsList(true)}
          >
            Filter
          </Button>
        </Col>
      </Row>
      <Divider
        style={{
          background: "#fff",
        }}
      ></Divider>
      <Row
        gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}
        style={{ padding: "5px 10px" }}
      >
        {loading && <Skeleton active />}
        {!loading && (
          <>
            <Col
              xs={{ span: 10, offset: 0 }}
              sm={{ span: 10, offset: 0 }}
              md={{ span: 10, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
              xl={{ span: 4, offset: 0 }}
              xxl={{ span: 3, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
              }}
            >
              <Card
                className="card-totals-commissions"
                bodyStyle={{ padding: "6px 10px 6px 10px" }}
              >
                <Space size={[2, 4]} direction="vertical">
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#fff",
                    }}
                  >
                    {labelCards("volume_traded_gbp")}
                  </Text>
                  <NumberFormat
                    thousandSeparator={true}
                    displayType="text"
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#ccc",
                    }}
                    prefix={"£ "}
                    decimalScale={2}
                    value={firstLevelCommissions.volume_traded_gbp}
                  />
                </Space>
              </Card>
            </Col>
            <Col
              xs={{ span: 10, offset: 0 }}
              sm={{ span: 10, offset: 0 }}
              md={{ span: 10, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
              xl={{ span: 4, offset: 0 }}
              xxl={{ span: 3, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
              }}
            >
              <Card
                className="card-totals-commissions"
                bodyStyle={{ padding: "6px 10px 6px 10px" }}
              >
                <Space size={[2, 4]} direction="vertical">
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#fff",
                    }}
                  >
                    {labelCards("volume_traded_eur")}
                  </Text>
                  <NumberFormat
                    thousandSeparator={true}
                    displayType="text"
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#ccc",
                    }}
                    prefix={"€ "}
                    decimalScale={2}
                    value={firstLevelCommissions.volume_traded_eur}
                  />
                </Space>
              </Card>
            </Col>
            <Col
              xs={{ span: 10, offset: 0 }}
              sm={{ span: 10, offset: 0 }}
              md={{ span: 10, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
              xl={{ span: 4, offset: 0 }}
              xxl={{ span: 3, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
              }}
            >
              <Card
                className="card-totals-commissions"
                bodyStyle={{ padding: "6px 10px 6px 10px" }}
              >
                <Space size={[2, 4]} direction="vertical">
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#fff",
                    }}
                  >
                    {labelCards("volume_deposited_gbp")}
                  </Text>
                  <NumberFormat
                    thousandSeparator={true}
                    displayType="text"
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#ccc",
                    }}
                    prefix={"£ "}
                    decimalScale={2}
                    value={firstLevelCommissions.volume_deposited_gbp}
                  />
                </Space>
              </Card>
            </Col>
            <Col
              xs={{ span: 10, offset: 0 }}
              sm={{ span: 10, offset: 0 }}
              md={{ span: 10, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
              xl={{ span: 4, offset: 0 }}
              xxl={{ span: 3, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
              }}
            >
              <Card
                className="card-totals-commissions"
                bodyStyle={{ padding: "6px 10px 6px 10px" }}
              >
                <Space size={[2, 4]} direction="vertical">
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#fff",
                    }}
                  >
                    {labelCards("volume_deposited_eur")}
                  </Text>
                  <NumberFormat
                    thousandSeparator={true}
                    displayType="text"
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#ccc",
                    }}
                    prefix={"€ "}
                    decimalScale={2}
                    value={firstLevelCommissions.volume_deposited_eur}
                  />
                </Space>
              </Card>
            </Col>
            <Col
              xs={{ span: 10, offset: 0 }}
              sm={{ span: 10, offset: 0 }}
              md={{ span: 10, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
              xl={{ span: 4, offset: 0 }}
              xxl={{ span: 3, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
              }}
            >
              <Card
                className="card-totals-commissions"
                bodyStyle={{ padding: "6px 10px 6px 10px" }}
              >
                <Space size={[2, 4]} direction="vertical">
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#fff",
                    }}
                  >
                    {labelCards("volume_withdrawn_gbp")}
                  </Text>
                  <NumberFormat
                    thousandSeparator={true}
                    displayType="text"
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#ccc",
                    }}
                    prefix={"£ "}
                    decimalScale={2}
                    value={firstLevelCommissions.volume_withdrawn_gbp}
                  />
                </Space>
              </Card>
            </Col>
            <Col
              xs={{ span: 10, offset: 0 }}
              sm={{ span: 10, offset: 0 }}
              md={{ span: 10, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
              xl={{ span: 4, offset: 0 }}
              xxl={{ span: 3, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
              }}
            >
              <Card
                className="card-totals-commissions"
                bodyStyle={{ padding: "6px 10px 6px 10px" }}
              >
                <Space size={[2, 4]} direction="vertical">
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#fff",
                    }}
                  >
                    {labelCards("volume_withdrawn_eur")}
                  </Text>
                  <NumberFormat
                    thousandSeparator={true}
                    displayType="text"
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#ccc",
                    }}
                    prefix={"€ "}
                    decimalScale={2}
                    value={firstLevelCommissions.volume_withdrawn_eur}
                  />
                </Space>
              </Card>
            </Col>
            <Col
              xs={{ span: 10, offset: 0 }}
              sm={{ span: 10, offset: 0 }}
              md={{ span: 10, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
              xl={{ span: 4, offset: 0 }}
              xxl={{ span: 3, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
              }}
            >
              <Card
                className="card-totals-commissions"
                bodyStyle={{ padding: "6px 10px 6px 10px" }}
              >
                <Space size={[2, 4]} direction="vertical">
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#fff",
                    }}
                  >
                    {labelCards("total_fees_gbp")}
                  </Text>
                  <NumberFormat
                    thousandSeparator={true}
                    displayType="text"
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#ccc",
                    }}
                    prefix={"£ "}
                    decimalScale={2}
                    value={firstLevelCommissions.total_fees_gbp}
                  />
                </Space>
              </Card>
            </Col>
            <Col
              xs={{ span: 10, offset: 0 }}
              sm={{ span: 10, offset: 0 }}
              md={{ span: 10, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
              xl={{ span: 4, offset: 0 }}
              xxl={{ span: 3, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
              }}
            >
              <Card
                className="card-totals-commissions"
                bodyStyle={{ padding: "6px 10px 6px 10px" }}
              >
                <Space size={[2, 4]} direction="vertical">
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#fff",
                    }}
                  >
                    {labelCards("total_fees_eur")}
                  </Text>
                  <NumberFormat
                    thousandSeparator={true}
                    displayType="text"
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#ccc",
                    }}
                    prefix={"€ "}
                    decimalScale={2}
                    value={firstLevelCommissions.total_fees_eur}
                  />
                </Space>
              </Card>
            </Col>
            <Col
              xs={{ span: 10, offset: 0 }}
              sm={{ span: 10, offset: 0 }}
              md={{ span: 10, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
              xl={{ span: 4, offset: 0 }}
              xxl={{ span: 3, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
              }}
            >
              <Card
                className="card-totals-commissions"
                bodyStyle={{ padding: "6px 10px 6px 10px" }}
              >
                <Space size={[2, 4]} direction="vertical">
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#fff",
                    }}
                  >
                    {labelCards("total_commissions_gbp")}
                  </Text>
                  <NumberFormat
                    thousandSeparator={true}
                    displayType="text"
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#ccc",
                    }}
                    prefix={"£ "}
                    decimalScale={2}
                    value={firstLevelCommissions.total_commissions_gbp}
                  />
                </Space>
              </Card>
            </Col>
            <Col
              xs={{ span: 10, offset: 0 }}
              sm={{ span: 10, offset: 0 }}
              md={{ span: 10, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
              xl={{ span: 4, offset: 0 }}
              xxl={{ span: 3, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
              }}
            >
              <Card
                className="card-totals-commissions"
                bodyStyle={{ padding: "6px 10px 6px 10px" }}
              >
                <Space size={[2, 4]} direction="vertical">
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#fff",
                    }}
                  >
                    {labelCards("total_commissions_eur")}
                  </Text>
                  <NumberFormat
                    thousandSeparator={true}
                    displayType="text"
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#ccc",
                    }}
                    prefix={"€ "}
                    decimalScale={2}
                    value={firstLevelCommissions.total_commissions_eur}
                  />
                </Space>
              </Card>
            </Col>
          </>
        )}
      </Row>
      {loading && <Skeleton active />}
      {!loading && (
        <div
          id="table-commissions"
          className="ant-table ant-table-tbody ant-table-thead text-table-cell ant-table-cell"
          style={{ marginTop: 10 }}
        >
          <Table
            rowKey={"email"}
            rowClassName="row-table"
            dataSource={commissionersList}
            scroll={{ x: 1600 }}
            columns={columns}
            pagination={{ pageSize: 20 }}
            expandable={{
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <DownOutlined onClick={(e) => onExpand(record, e)} />
                ) : (
                  <RightOutlined onClick={(e) => onExpand(record, e)} />
                ),
              expandedRowRender: (record) => (
                <Table
                  rowKey={"email"}
                  rowClassName="row-table"
                  dataSource={record.clients}
                  scroll={{ x: 1600 }}
                  columns={columnsClients}
                  pagination={false}
                  expandable={{
                    expandIcon: ({ expanded, onExpand, record }) =>
                      expanded ? (
                        <DownOutlined onClick={(e) => onExpand(record, e)} />
                      ) : (
                        <RightOutlined onClick={(e) => onExpand(record, e)} />
                      ),
                    expandedRowRender: (record) => (
                      <Table
                        rowKey={"id"}
                        rowClassName="row-table"
                        dataSource={record.transactions}
                        scroll={{ x: 1400 }}
                        columns={columnsTransactions}
                        pagination={false}
                      />
                    ),
                    expandedRowKeys: [rowIdTx],
                    onExpand: (expanded, record) => {
                      if (expanded) {
                        setRowIdTx(record.email);
                      } else {
                        setRowIdTx("");
                      }
                    },
                  }}
                />
              ),
              expandedRowKeys: [rowId],
              onExpand: (expanded, record) => {
                if (expanded) {
                  setRowId(record.email);
                  setRowIdTx("");
                } else {
                  setRowId("");
                  setRowIdTx("");
                }
              },
            }}
          />
        </div>
      )}
    </Card>
  );
  const itemsTab = [
    {
      key: "1",
      label: (
        <Text
          style={{
            fontSize: 18,
            color: "#fff",
          }}
        >
          Damex Direct
        </Text>
      ),
      children: commissionsLevels,
    },
  ];
  return (
    <Tabs
      defaultActiveKey="1"
      items={itemsTab}
      destroyInactiveTabPane={true}
    ></Tabs>
  );
};
const mapStateToProps = (state) => {
  return {
    token: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
    commissionsResult: state.commissions.commissionsList,
  };
};

const mapDispatchToProps = {
  currentToken,
  getCommissionersIntroducers,
  getCommissions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Commissions);
