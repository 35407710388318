import {
    BALANCE
  } from "../actions/balanceActions";
  const initialState = {
    balanceResult: {},
  };
  export function balance(state = initialState, action) {    
    switch (action.type) {
      case BALANCE:
        return {
          ...state, 
          balanceResult: action.payload
         };
      default:
        return state;
    }
  };
  
  export default balance;
  