// Dependencies
import React from "react";
import NumberFormat from "react-number-format";

// Ant Design Components
import { Card, Row, Col, Typography, Skeleton } from "antd";

// Constants
import { asset } from "../../../common/assets";

// Styles
import "../pendingSettlements.less";

const { Text } = Typography;

const BalanceCard = ({ title, balances, loading }) => {
  // Helper function to get asset icon from currency code
  const getAssetIcon = (currency) => {
    const assetInfo = asset.assets.find((a) => a.value === currency);
    return assetInfo?.icon || null;
  };

  // Helper function to render individual balance item
  const renderBalance = (item) => (
    <Col xs={24} sm={24} key={`${item.currency}${item.network || ""}`}>
      <div className="balance-item">
        {/* Currency Info Section */}
        <div className="currency-info">
          <img
            src={getAssetIcon(item.currency)}
            alt={item.currency}
            className="currency-icon"
          />
          <Text className="currency-text">
            {item.currency}
            {item.network && item.network !== "BASE_ASSET" && (
              <span className="network-text">({item.network})</span>
            )}
          </Text>
        </div>

        {/* Balance Amount Section */}
        <NumberFormat
          value={item.balance}
          displayType="text"
          thousandSeparator={true}
          decimalScale={
            item.currency === "BTC" || item.currency === "ETH" ? 8 : 2
          }
          className="balance-amount"
          renderText={(value) => (
            <Text className="balance-amount">{value}</Text>
          )}
        />
      </div>
    </Col>
  );

  return (
    <Card
      title={<Text className="card-title">{title}</Text>}
      className="balance-card"
      bordered={false}
    >
      {loading ? (
        <Skeleton active />
      ) : (
        <Row gutter={[16, 8]}>{balances.map(renderBalance)}</Row>
      )}
    </Card>
  );
};

const BalanceCards = ({ loading, tradingAccounts, withdrawalVault }) => {
  return (
    <Row gutter={[16, 16]} className="balance-cards-container">
      {/* Withdrawal Vault Balance Card */}
      <Col xs={24} md={12}>
        <BalanceCard
          title="High Value Crypto Withdrawal Vault"
          balances={withdrawalVault}
          loading={loading}
        />
      </Col>

      {/* Trading Accounts Balance Card */}
      <Col xs={24} md={12}>
        <BalanceCard
          title="High Value FIAT Trading Accounts"
          balances={tradingAccounts}
          loading={loading}
        />
      </Col>
    </Row>
  );
};

export default BalanceCards;
