import servicesAml from "../../services/aml";
import { currentToken } from "./tokenActions";
export const GET_AML_TRANSACTION = "GET_AML_TRANSACTION";
export const SET_LOAD_AML = "SET_LOAD_AML";
export const SET_CURRENT_LAST_ID = "SET_CURRENT_LAST_ID";
export function getAmlTransaction(
  id,
  accessToken,
  entity,
  currency,
  status,
  min_date,
  max_date,
  user_email,
  user_id,
  type,
  user_fullname,
  transaction_id,
  transaction_hash,
  lastId
) {
  return async (dispatch, getState) => {
    dispatch(setLoadAml(true));
    const response = await servicesAml.getTransactionAml(
      id,
      accessToken,
      entity,
      currency,
      status,
      min_date,
      max_date,
      user_email,
      user_id,
      type,
      user_fullname,
      transaction_id,
      transaction_hash,
      lastId
    );
    if (response.headers["x-new-access-token"] !== undefined) {
      dispatch(currentToken(response.headers["x-new-access-token"]));
    }

    dispatch({
      type: GET_AML_TRANSACTION,
      payload: response,
    });
    //dispatch(setCurrentLastId(current_last_id));
    dispatch(setLoadAml(false));
  };
}
export function setLoadAml(value) {
  return (dispatch) => {
    dispatch({
      type: SET_LOAD_AML,
      payload: value,
    });
  };
}
export function setCurrentLastId(value) {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_LAST_ID,
      payload: value,
    });
  };
}
